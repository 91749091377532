import React from 'react';

import { useGoogleLogin } from '@react-oauth/google';
import googleIcon from '../../resources/images/icon/google.svg'
import axios from 'axios';

function GoogleSocialLogin() {


  const { signIn } = useGoogleLogin({
    onSuccess: tokenResponse => console.log(tokenResponse),
    
  });

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
      );

      localStorage.setItem("USER_INFO", JSON.stringify(userInfo))

      window.location.replace( 'tokenauthentication');

      console.log( userInfo.data.email);
    },
    onError: errorResponse => console.log(errorResponse),
  });

  return (
    <>
      <button className="googleButton" onClick={() => login()}>
        <img src={googleIcon} alt="google login" className="googleIcon"></img>
          <span className="googleButtonText">Sign in with Google</span>
      </button>
    </>
  );
}

export default GoogleSocialLogin;