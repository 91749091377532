// Helper functions for filtering
export const defaultMatcherTodos = (filterText, node) => node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;

export const semCategoriaMatcher = (filterText, node) => node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 && (node.miraklCategory == "" || node.miraklCategory == undefined) && node.hasChildren == false;

export const sincronizadosMatcher = (filterText, node) => (node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 && node.syncMirakl == true) || node.hasChildren == true;

export const naoSincronizadosMatcher = (filterText, node) => node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 && node.miraklCategory != "" && node.miraklCategory != undefined && node.syncMirakl == false && node.hasChildren == false;

export const findNode = (node, filter, matcher) => matcher(filter, node)
    || (node.children && node.children.length && !!node.children.find(child => findNode(child, filter, matcher)))


export const filterTree = (node, filter, tipo) => {
  let matcher = defaultMatcherTodos
  if(tipo == "Sync"){
    matcher = sincronizadosMatcher
  } else if(tipo == "No_sync"){
    matcher = naoSincronizadosMatcher
  } else if(tipo == "Sem_cat"){
    matcher = semCategoriaMatcher
  }
  
  // If im an exact match then all my children get to stay
  if (matcher(filter, node) || !node.children) { return node }
  // If not then only keep the ones that match or have matching descendants
  const filtered = node.children
    .filter(child => findNode(child, filter, matcher))
    .map(child => filterTree(child, filter, tipo))
  return Object.assign({}, node, { children: filtered })
}


export const expandFilteredNodes = (node, filter, tipo) => {
  let matcher = defaultMatcherTodos
  if(tipo == "Sync"){
    matcher = sincronizadosMatcher
  } else if(tipo == "No_sync"){
    matcher = naoSincronizadosMatcher
  } else if(tipo == "Sem_cat"){
    matcher = semCategoriaMatcher
  }
  node.toggled = matcher(filter, node)

  if (node.children) {
    node.children.forEach((child) => {
      const expandedChild = expandFilteredNodes(child, filter, tipo)
      if (expandedChild.toggled) {
        node.toggled = true
      }
    })
  }
  return node
}

/*eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["node"] }]*/
export const expandSelectedNodes = (node) => {
  if (node.planCustom || node.partnerCustom) {
    return true
  }

  if (node.children) {
    node.children.forEach((child) => {
      const toggled = expandSelectedNodes(child)
      if (toggled) {
        node.toggled = toggled
      }
    })
  }

  return false
}
