import React, { Component, Fragment } from 'react'

import { Redirect, Link } from 'react-router-dom'

class Dashboard extends Component {
    constructor(props) {
      super(props)

      this.state = { redirect: null };

      this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        this.setState({ redirect: true })
    } 

    render() {
      if (this.state.redirect) {
        return <Redirect to="/dashboard" />
      }

      return (
        <Fragment>
            <h1>Erro ao tentar acessa a página, click <Link to='#' onClick={this.handleClick()}>aqui</Link> para voltar à pagina principal.</h1>
        </Fragment>
      )
    }
}

export default Dashboard