/* eslint-disable max-len */
export default {
  translations: {
    // Home
    'home': 'Home',

    // Input Text area
    'characters left': 'Restam {{count}} caracteres',

    // Menu
    'Payment': 'Repasse',

    // Authentication
    'Password': 'Senha',
    'User': 'Usuário',
    'youremail@domain.com.br': 'seuemail@domínio.com.br',
    'Login': 'Entrar',
    'User or password is incorrect': 'Usuário ou senha incorretos',
    'Forgot password': 'Esqueci a senha',


    // Reports
    'payment-management-reports': 'Gestão de Repasse',
    'commission-management-reports': 'Gestão de Comissão',
    'billing-adjustments': 'Ajuste Manual',
    'Loading data...': 'AGUARDE, CARREGANDO…',
    'Cycle': 'Ciclo',
    'Consolidated cycle in the period': 'Consolidado do ciclo no período',
    'Average Commission': 'Comissão Média',
    'Revenue': 'Faturamento',
    'REVENUE': 'FATURAMENTO',
    'SALE - RETURN': 'VENDA - ESTORNO',
    'Marketplace Commission': 'Comissão Marketplace',
    'MARKETPLACE COMMISSION': 'COMISSÃO MARKETPLACE',
    'COMMISSION - RETURNED COMISSION': 'COMISSÃO - ESTORNO COMISSÃO',
    'Payment Value': 'Repasse',
    'PAYMENT VALUE': 'REPASSE',
    'REVENUE - COMMISSION': 'FATURAMENTO - COMISSÃO',
    'Saphira Report': 'Para Saphira',
    'Detailed Report': 'Detalhado',
    'Error occurred!': 'Ocorreu um erro!',
    'Download File': 'Baixar Arquivo',
    'Period': 'Período',
    'from': 'de',
    'to': 'até',
    'This file is formatted according to SAPHIRA system file upload.': 'Esse arquivo está formatado em acordo com o exigido pelo sistema SAPHIRA para upload.',
    'Only closed cycles can be sent to Saphira.': 'Apenas ciclos encerrados podem ser enviados ao SAPHIRA.',
    'No display cycle': 'Não há ciclo para exibição',
    'No previous cycles for display': 'Sem ciclos anteriores para exibição',
    'Empty date': '00/00/0000',
    'Closed Cycles': 'Ciclos Fechados',
    'Open Cycles': 'Ciclos Abertos',
    'Sales': 'Vendas',
    'SALES': 'VENDAS',
    'Sales Commission': 'Comissão de Vendas',
    'Return Commission': 'Estorno de Comissão',
    'Commission Balance': 'Saldo Comissão',
    'COMMISSION BALANCE': 'SALDO COMISSÂO',
    'COMMISSION - RETURN': 'COMISSÂO - ESTORNO',
    'Consolidated Commission': 'Comissão Consolidada',
    'ITEM + FREIGHT': 'ITEM + FRETE',
    'Error on do download': 'Falhar ao realizar o download',

    // Tabela de Gestão de Repasse
    'Billing': 'Faturamento',
    'Commission': 'Comissão',
    'Cycles': 'Ciclos',
    'financial': 'Financeiro',
    'Item + Freight': 'Item + Frete',
    'Orders': 'Pedidos',
    'Out of': 'de ',
    'Previous Cycles': 'Ciclos Anteriores',
    'Previous Reports': 'Relatórios anteriores',
    'Repayment': 'Repasse',
    'Reports generated from past cycles': 'Relatórios gerados de ciclos passados',
    'Results': 'Resultados',

    //BillingAdjustment
    'Open': 'Aberto',
    'Manual Adjustment': 'Ajuste Manual',
    'Marketplace ajustments list': 'Lista de ajustes do Marketplace',
    'Adjustment': 'Ajuste',
    'Adjustment Id': 'ID',
    'Created On': 'Criado Em',
    'Seller': 'Loja',
    'Reason': 'Finalidade',
    'CREDIT': 'Crédito',
    'DEBIT': 'Débito',
    'Add Adjustment': 'Adicionar Ajuste',
    'Download Spreadsheet': 'Baixar Planilha',
    'Select adjustment reason': 'Selecione a finalidade do ajuste',
    'There are no adjustments to be shown.': 'Não há ajustes a serem exibidos',
    'Billing Adjustment Id': 'Id Ajuste',
    'Search by trading name or ecommerce id': 'Pesquise pelo nome da loja ou pelo id seller',
    'All fields are required': 'Todos os campos são obrigatórios',
    'Only ADJUSTMENTS in open cycles are allowed': 'Só são permitidos AJUSTES em ciclos abertos.',
    'Adjustment Cycle': 'Ciclo de Ajuste',
    'Adjustment Type': 'Tipo de Ajuste',
    'Select the Type': 'Selecione o Tipo',
    'error.message.closed.cycle.instance': 'O Ciclo ao qual você está tentando inserir o ajuste encontra-se fechado. Apenas Ciclos abertos podem receber ajustes.',
    'error.message.no.cycle.instance': 'Não foi encontrado nenhum ciclo para esse seller. Por favor, confira o seller informado.',
    'Billing adjustment inserted successfully.': 'Ajuste inserido com sucesso.',
    'Describe the reason of adjustment for this seller.': 'Descreva o motivo do ajuste para esse lojista.',
    'error.default.warning.message': 'Ocorreu um erro inesperado, favor contactar o Administrador',

    // Errors
    '400 Error': 'Erro 400',
    '404 Error': 'Erro 404',
    '403 Error': 'Erro 403',
    '503 Error': 'Erro 503',
    '500 Error': 'Erro 500',
    'Sorry, invalid parameters.': 'Desculpe, parâmetros inválidos.',
    'Sorry, page not found.': 'Desculpe, página não encontrada.',
    'A page you are looking for does not exist on our servers.': 'A página que você procura não existe em nossos servidores.',
    'Forbidden': 'Acesso não permitido.',
    'You do not have permission to access this page': 'Você não tem permissão para acessar essa página.',
    'Sorry, the server is unavailable.': 'Desculpe, servidor indisponível.',
    'Sorry, internal server error.': 'Desculpe, erro interno no servidor.',
    'The request could not be understood by the server due to invalid parameters. Fix it and try again.': 'A requisição não pode ser compreendida pelo servidor devido a parâmetros inválidos. Corrija-a e tente novamente.',
    'Please, try again later.': 'Por favor, tente novamente mais tarde.',
    'Back to home page': 'Voltar para a página inicial...',
    'Server Bad Request.': 'Resposta inesperada do servidor.',
    'Error getting cycle instances list': 'Falha ao buscar lista de ciclos',
    'Error on getting cycle instances': 'Falha ao buscar ciclos',

    //Generics
    'Close': 'Fechar',
    'Back': 'Voltar',
    'Cancel': 'Cancelar',
    'Caution': 'Atenção',
    'Continue': 'Continuar',
    'Required': 'Preenchimento obrigatório',
    'Description': 'Descrição',
    'Value': 'Valor',
    'Details': 'Detalhes',
    'Save': 'Salvar',
    'Time': 'Hora',
    'Lines': 'Linhas',
    'Line': 'Linha',

    //Plans
    'plans': 'Grade de Comissão',
    'Grid Plan': 'Grade',
    'Grid Seller': 'Lojista',
    'Create Plan': 'Criar Grade',
    'Standard': 'Padrão',
    'Name': 'Nome',
    'Categories': 'Categorias',
    'Sellers': 'Lojas',
    'Area': 'Área',
    'There are no plans to be shown.': 'Sem grade cadastrada para exibição',
    'Plans Grid': 'Grade de Comissão',
    'Plans management for Marketplace\'s sellers': 'Gestão das comissões aplicadas aos lojistas no Marketplace',
    'Marketplace Standard Commission': 'Comissão Padrão Marketplace',
    'Show all': 'Ver todas',
    'Plan categories': 'Categorias da grade',

    //Plan Insert
    'Create Commission Grid': 'Criar Grade de Comissão',
    'Edit Commission Grid': 'Editar Grade de Comissão',
    'Basic Information': '1. Informações Básicas',
    'Include Categories': '2. Incluir Categorias',
    'Add Partners': '3. Adicionar Lojistas',
    'Save Plan': 'Salvar Grade',
    'Grid Name': 'Nome da Grade',
    'Requesting Area': 'Área Solicitante',
    'Standard Plan Commission': 'Comissão Padrão do Plano',
    'Enter the name of the new grid': 'Insira o nome da nova grade',
    'Select the area': 'Selecione a área',
    'Enter details here to help others understand this grid.': 'Insira aqui detalhes que possam ajudar outras pessoas a entenderem esta grade.',
    'Grids with the same name are not allowed': 'Não são permitidas grades com o mesmo nome',
    'Select the Categories': 'Selecione as Categorias',
    'Selected Category': 'Categoria Selecionada',
    'Search category': 'Buscar categoria',
    'Apply the commission for each selected category': 'Aplique a commissão para cada categoria selecionada',
    'Add': 'Adicionar',
    'Search seller': 'Buscar Loja',
    'Added Sellers': 'Lojas Adicionadas',
    'A store can not enabled on more than on plan': 'Uma Loja não pode estar habilitada em mais de um plano',
    'Without add store': 'Sem loja adicionada.',
    'Commission Grid successfully created': 'Grade de Comissão criada com sucesso.',
    'Commission Grid successfully edited': 'Grade de Comissão editada com sucesso.',
    'Partner Commission Grid successfully edited': 'Comissão da loja customizada com sucesso.',
    'Categoy Navigation': 'Categoria de Navegação',
    'Category':'Categoria',
    //Plan Insert Error
    'Problems on Create Grid': 'Problemas na Criação da Grade',
    'Problems on Edit Grid': 'Problemas na Edição da Grade',
    'The following problems must be fixed before that create grid': 'Os problemas a seguir devem ser resolvidos antes que a nova grade seja criada.',
    'The following problems must be fixed before that edit grid': 'Os problemas a seguir devem ser resolvidos antes que a grade seja editada.',
    'The grid already exists': 'A grade {{plan}} já existe',
    'Every created grid must have a unique name that helps to define yours creations purpose': 'Cada grade criada deve ter um nome único e que ajude a definir seu propósito de criação.',
    'Please, choose a name that is not in use.': 'Por favor, escolha um nome que não esteja em uso.',
    'The following partners already in use by another plan': 'As lojas listadas abaixo estão habilitadas em outros planos.',
    'Every partner must be in once Plan. You should set that plan on partner profile in Partner Tab': 'Cada loja deve estar habilitada em apenas um plano de comissão. Você pode habilitar esse plano diretamente entrando no perfil da loja na aba Lojistas.',
    'Please, remove the following partners on Add Partners Tab': 'Por favor, remova as lojas listadas abaixo da lista da aba ADICIONAR LOJAS.',

    // Sellers
    'There are no sellers to be shown': 'Sem sellers cadastrados para exibição',
    'Contractual': 'Contratual',
    'Store': 'Loja',
    'Plan': 'Plano',

    //Partner Insert
    'Customize Commission Grid': 'Customizar Grade de Comissão',
    'Commissions with custom values going to have priority over plan values on plan assigned that partner': 'Comissões com valores customizados aqui, terão precedência sobre os valores cadastrados no plano aplicado ao lojista.',
    'Contractual Commission': 'Comissão Contratual',
    'Seller Commission Grid': 'Grade de Comissão do Lojista',
    'Select the grid': 'Selecione a grade',
    'Without grid applied': 'Sem grade aplicada',

    // Audit
    'Audit': 'Auditoria da Aplicação',
    'Audit Description': 'Lista de ações executadas no Marketplace',
    'Audit Type': 'Tipo',
    'Select audit type': 'Selecione o tipo da auditoria',
    'Audit User': 'Usuário',
    'Audit Date': 'Data',
    'Audit Action': 'Ação',
    'Audit Application': 'Fonte',
    'Audit Details': 'Detalhes',
    'No audits for display': 'Sem logs para exibição',

 



  }
}
