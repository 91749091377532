import React from 'react'
import PropTypes from 'prop-types'
import { CredentialManager } from '../../commons/CredentialManager'
import { AuthenticatedSessionControl, LogoutTypes } from  'react-session-control';
import { Dropdown, MenuItem, MenuItemClick } from '../menu/dropdown/Dropdown'

/** css */
import './User.css'

const getClassName = (defaultClass, additionalClass) => (additionalClass ? `${defaultClass} ${additionalClass}` : defaultClass)


const Admin = (
  <div className="user-admin">ADMIN . </div>
);


function logout(){
  CredentialManager.logout();
  window.location.href = "/";
}

const User = (props) => {
  const {
    className, userName, isAdmin, id
  } = props

	function handleSessionControlLogout(logoutType){
		logout();
	}

  return (
    <>
        <AuthenticatedSessionControl
          inactivityTimeout={1700}
          modalInactivityTimeout={10}
          storageTokenKey="TOKEN"
          onLogout={handleSessionControlLogout}
        />
      <div className={getClassName('user', className)} id={`user_${id}`}>

        <div className="user-container">
          <Dropdown className="avatar" id="nav" hamburger={false}>
            <MenuItem text="Alterar senha" route="/changepassword" key="Alterar senha" />
            <MenuItemClick text="Sair" key="Sair" onClick={logout} />
          </Dropdown>
          <div className="user-name">
            {userName}       
          </div>
        </div>
      </div>
    </>
  )
}
User.propTypes = {
  className: PropTypes.string,
  userName: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  id: PropTypes.string.isRequired
}

export default User