import React, { Component, Fragment } from 'react'

class Dashboard extends Component {
    constructor(props) {
      super(props)
    }

    render() {
      return (
        <Fragment>

        </Fragment>
      )
    }
}

export default Dashboard