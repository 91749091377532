import React from 'react'

/** components */
import Failure from './Failure'

export default function NotFound() {
  return (
    <Failure
      title="404 Error"
      resume="Sorry, page not found."
      message="A page you are looking for does not exist on our servers."
      to="/"
      actionText="Back to home page"
    />
  )
}
