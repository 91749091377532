import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/esm/locale';

import { MenuGrid } from '../user/menu-grid'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

class ManagerHolidayGrid extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        rowData: undefined,
        gridApi: null,
        gridColumnApi: null
      }
    }

    componentDidMount = () => {
        this.setState({
            rowData: this.props.allUsers
        })
    }

    formatDate = (data) => {
        let newDate = parseISO(data.value);
        let dateReturn = data.seconds;

        try {
            dateReturn = format(newDate, 'dd MMMM',{locale: ptBR});
        } catch (e) {
            console.log(`Erro ao converter a coluna CreateOn: ${e}`);
        }
        
        return dateReturn;
    }

    buttons = (params) => {
        let buttons = document.createElement('div')

        let edit = document.createElement('button')
        edit.className = 'btn-grid';
        edit.innerHTML = '<i class="icon-edit" />';
        edit.addEventListener('click', () => {
            this.props.handleClickButtonsGrid.editHoliday(params.data.holidayDate, params.data.description)
        });

        let del = document.createElement('button')
        del.className = 'btn-grid';
        del.innerHTML = '<i class="icon-cancel" />';
        del.addEventListener('click', () => {
            this.props.handleClickButtonsGrid.deleteHoliday(params.data.holidayDate)
        });

        buttons.appendChild(edit);
        buttons.appendChild(del);

        return buttons;
    }

    render() {
        return (
            <div className="ag-theme-material" style={{ height: this.props.height, width: this.props.width }}>
                <AgGridReact
                    onGridReady={this.props.onGridReady}
                    rowData={this.state.rowData}
                    pagination={true}
                    enableCellChangeFlash={true}
                    suppressExcelExport={true}      
                    suppressClickEdit={true}
                    suppressCellSelection={false}
                    enableCellTextSelection={true}
                    editable={true} 
                >   
                    <AgGridColumn lockPosition={true} headerName="Id"   field="seq"         width={80} resizable={true}/>
                    <AgGridColumn lockPosition={true} sortable={true} initialSort={'asc'} sortingOrder={['desc', 'asc']} headerName="Data" field="holidayDate" width={160} cellRenderer={this.formatDate} resizable={true}/>
                    <AgGridColumn lockPosition={true} headerName="Descrição" field="description" width={300} resizable={true}/>
                    <AgGridColumn lockPosition={true} headerName="Ações" width={220} cellRenderer={this.buttons}/>
                </AgGridReact>
            </div>
        )
    }
}

export default ManagerHolidayGrid;