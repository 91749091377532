/* eslint prefer-promise-reject-errors: "off" */
/**
 * See more: https://eslint.org/docs/rules/prefer-promise-reject-errors
 */

import axios from 'axios'
import emitter from './Emitter'
import i18n from '../utils/i18n/i18n'
/** components */
import { CredentialManager } from './CredentialManager'
import env from './Environment'


const RESPONSE_STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  FORBIDDEN: 401,
  UNAUTHORIZED: 403,
  NOT_FOUND: 404,
  BUSINESS_EXCEPTION: 422,
  INTERNAL_SERVER_ERROR: 500
}

const axiosAuth = axios.create({
  baseURL: env.getUrlEnv().REACT_APP_MKTP_AUTH_URL,
  headers: { 'Content-Type': 'application/json' },
  mode: 'cors'
})


const axiosBackend = axios.create({
  baseURL: env.getUrlEnv().REACT_APP_MKTP_FRONT_BACKEND_URL,
  headers: { 'Content-Type': 'application/json' },
  mode: 'cors'
})



export const verifyMessage = (data) => {
  if (data) {
    const messageError = data.meta.errors[0].message === i18n.t(data.meta.errors[0].message)
      ? 'Server Bad Request.' : data.meta.errors[0].message
    return messageError
  }
  return 'Server Bad Request.'
}

export const onServerError = (data) => {
  emitter.emit('error', verifyMessage(data))
}

const onAccessDenied = (data) => {
  emitter.emit('authenticate', data)
}

const handleResponse = (status, data) => (
  new Promise((resolve, reject) => {
    if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
      reject({ status, data })
      onServerError(data)
    } else if (status === RESPONSE_STATUS.FORBIDDEN) {
      onAccessDenied(data)
    } else if (status === RESPONSE_STATUS.UNAUTHORIZED) {
      onAccessDenied(data)
    } else if (status === RESPONSE_STATUS.BAD_REQUEST) {
      reject({ status, data })
    } else if (status === RESPONSE_STATUS.NOT_FOUND) {
      reject({ status, data })
      onServerError()
    } else if (status === RESPONSE_STATUS.BUSINESS_EXCEPTION) {
      reject({ status, data })
    } else {
      resolve(data)
    }
  })
)

export const handleError = (response, resolve, reject) => {
  if (response) {
    const { status, data } = response
    handleResponse(status, data)
      .then(resolve)
      .catch(reject)
  } else {
    onServerError()
  }
}

const handleAuthentication = (status, data) => (
  new Promise((resolve, reject) => {
    if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
      reject({ status, data })
      onServerError(data)
    } else if (status >= RESPONSE_STATUS.BAD_REQUEST) {
      reject({ status, data })
    } else {
      resolve(data)
    }
  })
)

const handleAuthenticationError = (error, reject) => {
  if (error) {
    const { status } = error.response ? error.response : { status: '' }
    if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
      reject(error)
      onServerError({ meta: { errors: [{ message: error.message }] } })
    } else {
      reject(error)
    }
  }
}

export const doAuth = url => (
  new Promise((resolve, reject) => {
    axiosAuth.get(url)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)
export const requestPassword = (url,username) => (
  new Promise((resolve, reject) => {
    axiosBackend.get(`${url}?email=${username}`)
            .then(({ data }) => {
              resolve(data)
            })
            .catch(err => {
                reject(err)
            });
  })
)

