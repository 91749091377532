import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const InputTimePicker = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker         
        selected={props.value != null ? props.value : startDate} 
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Hora"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        onChange={(date, e) => { props.handleChange(date, e); setStartDate(date)}}
    />
  );
};

export default InputTimePicker;