import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import Closable from '../../components/closable/Closable'
import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'
import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import ManagementController from '../../controllers/ManagementController'
import TransferListEditUser from '../../components/form-components/transfer-list/TransferListEditUser'
import InputText from '../../components/form-components/InputText'

import './EditUser.css'

class EditUser extends Component {
    constructor(props) {
      super(props)
      
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        loader: false,
        selectedRoles:  [] ,
        inputUsername: '',
        user: undefined,
        userList: [],
        leftList: [],
        rightList: []
      }
      
      bindAll(this, [
                     'closePartnerMessage',
                     'handleOnClickSave',
                     'handleRolesSelected',
                     'setValueUserSelected',
                     'searchUserByUsername'
                    ]);
                    
    }
    
    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    handleRolesSelected = (roles) => {
      this.setState({ selectedRoles: roles })
    }

    setValueUserSelected = (value) => {
      if(value != null){
          this.setState({
            user: value,
            inputUsername: value.username,
            rightList: value.roles,
            leftList: value.notRoles
          }, () => {
            
          });
      }
      
    }

    searchUserByUsername = () => {
      ManagementController.getUserByUsername(localStorage.getItem('USER_NAME_SELECTED'))
        .then((result) => {
          console.log(result)
          this.setState({
            user: result,
            inputUsername: result.username,
            rightList: result.roles,
            leftList: result.notRoles
          });
        })
        .catch((error) => {
          this.setState({
            showPartnerMessage: true, 
            message: `Erro ao buscar o User: ${error}`,
            colorMessage: 'header-message header-error-message'
          });
        });
    }

    handleOnClickSave = (event) => {
      this.setState({ loader: true });

      let inputUsername = localStorage.getItem('USER_NAME_SELECTED') ? localStorage.getItem('USER_NAME_SELECTED') : undefined;
      let listRoles = this.state.selectedRoles.length > 0 ? this.state.selectedRoles : undefined;
      
      if (this.formValidate()) {
        ManagementController.updateUser(inputUsername, listRoles)
          .then((result) => {
            if (result.status === 'Error') {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `${result.message}`,
                colorMessage: 'header-message header-error-message'
              });
            } else {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Usuário alterado com sucesso.`,
                colorMessage: 'header-message header-success-message'
              });
            }
          })
          .catch((error) => {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Erro eo tentar alterar o usuário.`,
                colorMessage: 'header-message header-error-message'
              });
          })
      } 
  }

  handleChangeUsername = (event) => {
    const { value } = event.target

    this.setState({
      inputUsername: value
    })
  }

  formValidate = () => { 
    if (localStorage.getItem('USER_NAME_SELECTED') === '' || localStorage.getItem('USER_NAME_SELECTED') === undefined ) { 
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Você deve preencher o campo Username.`,
        colorMessage: 'header-message header-error-message'
      });
      return false; 
    }

    if (this.state.selectedRoles.length <= 0) { 
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Você deve adicionar ao menos 1 permissão para o usuário.`,
        colorMessage: 'header-message header-error-message'
      });

      return false; 
    }
    return true;
  }

  handleCancelClick = () => {
    window.location.replace('manageruser')
  }

  componentDidMount = () => {
    console.log(localStorage.getItem('USER_NAME_SELECTED'));
    this.searchUserByUsername();
  }
    
    render() {

      const hasRoleSync = CredentialManager.userHasRole('ROLE_ADMIN')

      if (!hasRoleSync) {
        return <Redirect to="/error" />
      }

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '100%'}}> 
              <h3>Editar Usuário</h3>
            </div>
            
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Usuário:
            </div>
            <div className="column" style={{width: '50%'}}>
              <InputText
                    id="inputUsername"
                    value={localStorage.getItem('USER_NAME_SELECTED')}
                    placeholder={'Digite aqui o Username'}
                    maxLength={100}
                    readOnly={true}
                />
            </div>
            <div className="column" style={{width: '50%'}}>

            </div>
            <div className="column" style={{width: '100%'}}>
              Permissões:
            </div>
            <div className="column" style={{width: '100%'}}>
                
                <TransferListEditUser handleRolesSelected={this.handleRolesSelected} 
                              rightList={this.state.rightList}
                              leftList={this.state.leftList}
                              />

            </div>
            <div className="column" style={{width: '16%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickSave} 
                id="btnFiltrar" 
                text="Salvar Alterações"
                componentClass="btn btn-action" />
            </div>
            <div className="column" style={{ width: '84%', marginTop: '30px'}}>
              <ActionButton
                onClickAction={this.handleCancelClick}
                id="btnCancelar"
                text="Cancelar"
                componentClass="btn btn-default" />    
            </div>
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default EditUser