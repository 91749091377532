/* eslint prefer-promise-reject-errors: "off" */
/**
 * See more: https://eslint.org/docs/rules/prefer-promise-reject-errors
 */

 import axios from 'axios'
 import emitter from './Emitter'
 import i18n from '../utils/i18n/i18n'
 import qs from 'qs'
 /** components */
 import { CredentialManager } from './CredentialManager'
 import env from './Environment'
 import { saveAs } from 'file-saver';
 
 export const getAuthorizationHeaders = (config) => {
   let conf = {}
   conf = config
   conf.headers.Authorization = `${CredentialManager.getUserToken().token_type} ${CredentialManager.getUserToken().access_token}`
   return conf
 }
 
 const RESPONSE_STATUS = {
   SUCCESS: 200,
   BAD_REQUEST: 400,
   FORBIDDEN: 401,
   UNAUTHORIZED: 403,
   NOT_FOUND: 404,
   BUSINESS_EXCEPTION: 422,
   INTERNAL_SERVER_ERROR: 500
 }
 
 const axiosAuth = axios.create({
   baseURL: env.getUrlEnv().REACT_APP_MKTP_AUTH_URL,
   headers: { 'Content-Type': 'application/json' },
   mode: 'cors'
 })
 
 axiosAuth.interceptors.request.use(
   getAuthorizationHeaders,
   error => Promise.reject(error)
 )
 
 const axiosInstanceAuthentication = axios.create({
   //baseURL: "http://localhost:8085",
   baseURL: env.getUrlEnv().REACT_APP_MKTP_AUTH_URL,
   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
   mode: 'cors'
 })
 
 const axiosBackend = axios.create({
   baseURL: env.getUrlEnv().REACT_APP_MKTP_FRONT_BACKEND_URL,
   headers: { 'Content-Type': 'application/json' },
   mode: 'cors'
 })
 
 const axiosSeller = axios.create({
   baseURL: env.getUrlEnv().REACT_APP_MKTP_SELLER,
   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
   mode: 'cors'
 })
 
 const axiosSellerDiagnosis = axios.create({
   baseURL: env.getUrlEnv().REACT_APP_MKTP_SELLER_DIAGNOSIS,
   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
   mode: 'cors'
 })
 
 const axiosCatalogingDashboard = axios.create({
   baseURL: env.getUrlEnv().REACT_APP_CATALOGING_DASHBOARD,
   mode: 'cors'
 })
 
 const axiosCommissioning = axios.create({
   baseURL: env.getUrlEnv().REACT_APP_C4_COMMISSIONING,
   mode: 'cors'
 })
 
 const axiosDataHub = axios.create({
   baseURL: env.getUrlEnv().REACT_APP_MKTP_DATA_HUB_URL,
   headers: { 'Content-Type': 'application/json' },
   mode: 'cors'
 })

 axiosBackend.interceptors.request.use(
   getAuthorizationHeaders,
   error => Promise.reject(error)
 )
 
 axiosSeller.interceptors.request.use(
   getAuthorizationHeaders,
   error => Promise.reject(error)
 )
 
 axiosCatalogingDashboard.interceptors.request.use(
   getAuthorizationHeaders,
   error => Promise.reject(error)
 )
 
 axiosCommissioning.interceptors.request.use(
   getAuthorizationHeaders,
   error => Promise.reject(error)
 )
 
 axiosDataHub.interceptors.request.use(
   getAuthorizationHeaders,
   error => Promise.reject(error)
 )
 
 export const verifyMessage = (data) => {
   if (data) {
     const messageError = data.meta.errors[0].message === i18n.t(data.meta.errors[0].message)
       ? 'Server Bad Request.' : data.meta.errors[0].message
     return messageError
   }
   return 'Server Bad Request.'
 }
 
 export const onServerError = (data) => {
   emitter.emit('error', verifyMessage(data))
 }
 
 const onAccessDenied = (data) => {
   emitter.emit('authenticate', data)
 }
 
 const handleResponse = (status, data) => (
   new Promise((resolve, reject) => {
     if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
       reject({ status, data })
       onServerError(data)
     } else if (status === RESPONSE_STATUS.FORBIDDEN) {
       onAccessDenied(data)
     } else if (status === RESPONSE_STATUS.UNAUTHORIZED) {
       onAccessDenied(data)
     } else if (status === RESPONSE_STATUS.BAD_REQUEST) {
       reject({ status, data })
     } else if (status === RESPONSE_STATUS.NOT_FOUND) {
       reject({ status, data })
       onServerError()
     } else if (status === RESPONSE_STATUS.BUSINESS_EXCEPTION) {
       reject({ status, data })
     } else {
       resolve(data)
     }
   })
 )
 
 export const handleError = (response, resolve, reject) => {
   if (response) {
     const { status, data } = response
     handleResponse(status, data)
       .then(resolve)
       .catch(reject)
   } else {
     onServerError()
   }
 }
 
 const handleAuthentication = (status, data) => (
   new Promise((resolve, reject) => {
     if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
       reject({ status, data })
       onServerError(data)
     } else if (status >= RESPONSE_STATUS.BAD_REQUEST) {
       reject({ status, data })
     } else {
       resolve(data)
     }
   })
 )
 
 const handleAuthenticationError = (error, reject) => {
   if (error) {
     const { status } = error.response ? error.response : { status: '' }
     if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
       reject(error)
       onServerError({ meta: { errors: [{ message: error.message }] } })
     } else {
       reject(error)
     }
   }
 }
 
 export const doAuthenticate = (url, auth) => (
   new Promise((resolve, reject) => {
     axiosInstanceAuthentication.post(url, auth)
       .then(({ status, data }) => {
         handleAuthentication(status, data)
           .then(resolve)
           .catch(reject)
       })
       .catch(error => handleAuthenticationError(error, reject))
   })
 )
 
 export const doAuth = url => (
   new Promise((resolve, reject) => {
     axiosAuth.get(url)
       .then(({ status, data }) => {
         handleResponse(status, data)
           .then(resolve)
           .catch(reject)
       })
       .catch(({ response }) => {
         handleError(response, resolve, reject)
       })
   })
 )
 
 
 
 
 const axiosVtex = axios.create({
     //baseURL: "https://carrefourbr.vtexcommercestable.com.br",
    // baseURL: "http://localhost:8010/proxy",
   baseURL: env.getUrlEnv().REACT_APP_MKTP_FRONT_BACKEND_URL,
   //baseURL: "http://localhost:8087",
   headers: {'Content-Type': 'application/json'},
   mode: 'cors'
 })
 
 axiosVtex.interceptors.request.use(
   getAuthorizationHeaders,
   error => Promise.reject(error)
 )
 
 export const getCategories = url => (new Promise((resolve, reject) => {
     axiosVtex.get(url)
         .then(({ data }) => {
             resolve(data)
         })
         .catch(err => {
             reject(err)
         });
 }));
 
 export const getCategoriesFirestore = url => (
 
   new Promise((resolve, reject) => {
     axiosVtex.get(url)
         .then(({ data }) => {
             resolve(data)
         })
         .catch(err => {
             reject(err)
         });
 }));
 
 export const synchronizeCategories = url => (new Promise((resolve, reject) => {
     axiosVtex.get(url)
     .then(() => {
             resolve()
         })
         .catch(err => {
             reject(err)
         });
 }));
 
 export const updateCategory = (url, category) => (new Promise((resolve, reject) => {
     axiosVtex.post(url, category)
     .then((data) => {
             resolve(data)
         })
         .catch(err => {
             reject(err)
         });
 }));
 
 export const createCategory = (url, category) => (new Promise((resolve, reject) => {
     return axiosVtex.post(url, category)
       .then((data) => {
               resolve(data)
           })
           .catch(err => {
               reject(err)
           });
 }));
 
 export const getReport = (url, from, to, groupBy, sellerId) => (
   new Promise((resolve, reject) => {
     axiosBackend.get(url, { 
               params: { from: from, to: to, groupBy: groupBy, sellerId: sellerId },
               paramsSerializer: (params) => {
                 return qs.stringify(params, { arrayFormat: 'repeat' })
               }
             })
             .then(({ data }) => {
               resolve(data)
             })
             .catch(err => {
                 reject(err)
             });
   })
 )
 
 export const getReportBySeller = (url, sellerId) => (
   new Promise((resolve, reject) => {
     axiosBackend.get(url, { 
               params: { sellerId: sellerId },
               paramsSerializer: (params) => {
                 return qs.stringify(params, { arrayFormat: 'repeat' })
               }
             })
             .then(({ data }) => {
               resolve(data)
             })
             .catch(err => {
                 reject(err)
             });
   })
 )
 
 export const getLogPriceStock = (url, rms) => (
   new Promise((resolve, reject) => {
     axiosBackend.get(`${url}?rms=${rms}`) 
       .then(({ data }) => {
         resolve(data)
       })
       .catch(err => {
           reject(err)
       });
   })
 )
 
 export const getLockedPriceParams = (url) => (
   new Promise((resolve, reject) => {
     axiosBackend.get(`${url}`) 
       .then(({ data }) => {
         resolve(data)
       })
       .catch(err => {
           reject(err)
       });
   })
 )
 
 export const setLockedPriceParams = (url, params) => (
   new Promise((resolve, reject) => {
     axiosBackend.post(`${url}`, params) 
       .then(({ data }) => {
         resolve(data)
       })
       .catch(err => {
           reject(err)
       });
   })
 )
 
 export const executeReprocessOffers = (url, data) => (
   new Promise((resolve, reject) => {
     axiosBackend.post(url, data)
     .then(({ data }) => {
       resolve(data)
     })
     .catch(err => {
         reject(err)
     });
   })
 )
 
 export const processUnlockOffers = (url, params, user) => (
   new Promise((resolve, reject) => {
     axiosBackend.post(`${url}?createdBy=${user}`, params) 
       .then(({ data }) => {
         resolve(data)
       })
       .catch(err => {
           reject(err)
       });
   })
 )
 
 export const getSellerDiagnosis = (url, tradingName) => (
   new Promise((resolve, reject) => {
     console.log(process.env);    
     axiosSellerDiagnosis.get(`${url}/${tradingName}`)
       .then(({ data }) => {
         resolve(data)
       })
       .catch(err => {
           reject(err)
       });      
   })
 )
 
 export const getSellerList = (url, tradingName) => (
   new Promise((resolve, reject) => {
     axiosSeller.get(`${url}?tradingName=${tradingName}`)
             .then(({ data }) => {
               resolve(data)
             })
             .catch(err => {
                 reject(err)
             });
   })
 )
 
 export const getTopSellersSheet = (url, groupBy, init, end, total, cluster, sellerId) => (
   new Promise((resolve, reject) => {
 
     var endpoint_url = `${url}/${groupBy}?init=${init}&end=${end}&total=${total}`
     if (cluster != '')
       endpoint_url += `&cluster=${cluster}`
     if (sellerId != '')
       endpoint_url += `&sellerId=${sellerId}`
     
     const fileName = 'Top_Sellers_' + init + '_' + end
 
     axiosCatalogingDashboard.get(endpoint_url, {responseType: 'blob'}).then((response) => {
       
       saveAs(response.data, fileName)
 
       }).catch((err) => {          
           reject(err)
       })
   })
 )
 
 export const getTopSellersList = (url, groupBy, init, end, total, cluster, sellerId) => (
   new Promise((resolve, reject) => {
     var endpoint_url = `${url}/${groupBy}?init=${init}&end=${end}&total=${total}`
     if (cluster != '')
       endpoint_url += `&cluster=${cluster}`
     if (sellerId != '')
       endpoint_url += `&sellerId=${sellerId}`
     axiosCatalogingDashboard.get(endpoint_url)
     .then(({ data }) => {
       console.log(data)      
       resolve(data)
     })
     .catch(err => {
         reject(err)
     });    
   })  
 )
 
 export const getItemsByPeriodSheet = (url, groupBy, init, end, sellerId) => (
  new Promise((resolve, reject) => {

    var endpoint_url = `${url}/${groupBy}?init=${init}&end=${end}&sellerId=${sellerId}`
       
    const fileName = 'Itens_por_periodo_' + init + '_' + end

    axiosCatalogingDashboard.get(endpoint_url, {responseType: 'blob'}).then((response) => {
      
      saveAs(response.data, fileName)

      }).catch((err) => {          
          reject(err)
      })
  })
)

export const getItemsByPeriod = (url, groupBy, init, end, sellerId) => (
  new Promise((resolve, reject) => {
    var endpoint_url = `${url}/${groupBy}?init=${init}&end=${end}&sellerId=${sellerId}`

    axiosCatalogingDashboard.get(endpoint_url)
    .then(({ data }) => {
      console.log(data)      
      resolve(data)
    })
    .catch(err => {
        reject(err)
    });    
  })  
)

export const uploadMassiveBillingAdjustment = (url, data) => (
  new Promise((resolve, reject) => {
    axiosCommissioning.post(url, data, {headers: { "Content-Type": "multipart/form-data" }} )
    .then(({ data }) => {
      resolve(data)
    })
    .catch(err => {
        reject(err)
    });
  })
)
 export const getLockedsAndUnlockedsOffers = (url, from, to, statusList, ecommerceId, rms) => (
   new Promise((resolve, reject) => {
     axiosBackend.get(url, { 
               params: { from: from, to: to, statusList: statusList, ecommerceId: ecommerceId, rms: rms },
               paramsSerializer: (params) => {
                 return qs.stringify(params, { arrayFormat: 'repeat' })
               }
             })
             .then(({ data }) => {
               resolve(data)
             })
             .catch(err => {
                 reject(err)
             });
   })
 )
 
 export const getSellerByEcommerceId = (url, ecommerceId) => (
   new Promise((resolve, reject) => {
     axiosSeller.get(`${url}/${ecommerceId}`)
             .then(({ data }) => {
               resolve(data)
             })
             .catch(err => {
                 reject(err)
             });
   })
 )
 
 export const getMktPortalRoles = (url) => (
   new Promise((resolve, reject) => {
     axiosBackend.get(`${url}`)
             .then(({ data }) => {
               resolve(data)
             })
             .catch(err => {
                 reject(err)
             });
   })
 )
 
 export const doGetDataHub = url => (
   new Promise((resolve, reject) => {
     axiosDataHub.get(url)
       .then(({ status, data }) => {
         handleResponse(status, data)
           .then(resolve)
           .catch(reject)
       })
       .catch(({ response }) => {
         handleError(response, resolve, reject)
       })
   })
 )

 export const getHolidayList = (url, year, active) => (
  new Promise((resolve, reject) => {
    console.log(`${url}?year=${year}&active=${active}`)
    axiosBackend.get(`${url}?year=${year}&active=${active}`) 
      .then(({ data }) => {
        resolve(data)
      })
      .catch(err => {
          reject(err)
      });
  })
)

export const postAddHoliday = (url, date, description, changeBy, active) => (
  
  new Promise((resolve, reject) => {
    console.log(`${url}?holidayDate=${date}&description=${description}&changeBy=${changeBy}&active=${active}`)

    axiosBackend.post(`${url}?holidayDate=${date}&description=${description}&changeBy=${changeBy}&active=${active}`) 
      .then(({ data }) => {
        resolve(data)
      })
      .catch(err => {
          reject(err)
      });
  })
)

