import React, { Component } from 'react'
import { bindAll } from 'lodash'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'

 
/** components */
import Logo from '../../components/header/title/logo/Logo'
import InputText from '../../components/form-components/InputText'
import ActionButton from '../../components/buttons/ActionButton'
import { CredentialManager } from '../../commons/CredentialManager'
import { ToggleManager } from '../../commons/ToggleManager'
import authenticate from '../../services/api/authentication/AuthenticationAction'
import getUserInfo from '../../services/api/user-info/UserInfo'
import i18n from '../../utils/i18n/i18n'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import { UnauthenticatedSessionControl } from 'react-session-control'
import ManagementController from '../../controllers/ManagementController'

/** css */
import './Authentication.css'


// Renders the message for incorrect user or password
const InvalidUserPassword = () => (
  <div className="invalid-user-password">
    <div className="message"> 
      <i className="icon-warning" />
      <span>Usuário ou senha incorretos</span>
    </div>
  </div>
)

export default class Authentication extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      input_text_user: JSON.parse(localStorage.getItem("USER_INFO")) !== null && JSON.parse(localStorage.getItem("USER_INFO")).data !== null &&  JSON.parse(localStorage.getItem("USER_INFO")).data.email  !== null ?  JSON.parse(localStorage.getItem("USER_INFO")).data.email : '',
      input_text_password: '',
      validUser: true,
      validPassword: true,
      validCredentials: true,
      loader: false,
      isNewUser: false
    }
    bindAll(this, ['validateUser', 'validatePassword', 'handleChange', 'click', 'verifyNewUser'])
  }

  validateUser() {
    // eslint-disable-next-line camelcase
    const { input_text_user } = this.state
    return input_text_user.length > 0
  }

  validatePassword() {
    // eslint-disable-next-line camelcase
    const { input_text_password } = this.state
    return input_text_password.length > 0
  }

  // Update the state with the current value of the inputs
  handleChange(event) {
    const value = (event.target.type =='text')? event.target.value.replace(/( )+/g, ''): event.target.value
    this.setState({
      [event.target.id]: value
    })
  }

  verifyNewUser(username) {
        return ManagementController.isNewUser(username)
  }

  click(event) {
    event.preventDefault()
    // eslint-disable-next-line camelcase
    const { input_text_user, input_text_password } = this.state
    const validUser = this.validateUser()
    const validPassword = this.validatePassword()
    this.setState({
      validUser,
      validPassword
    })
    // Checks if the user and password are filled in before calling the API for authentication
    if (validUser && validPassword) {
      this.setState({ loader: true })
      // Calls the API for authenticate the user                                    
      authenticate(input_text_user, input_text_password)
        .then((response) => {
          // Sets the token got from the authetication API
          new Promise(resolve => CredentialManager.setUserToken(response, resolve))
            .then(async () => {

              await new Promise(r => setTimeout(r, 1000));

              getUserInfo()
                .then(async (data) => {
                // Sets the user info
                CredentialManager.setAuthenticatedUser({ name: data.username, type: data.customData.userType })
                CredentialManager.setUserRoles(data.roles)

                //getToggles get all of existing toggles
                const activeToggles = []
                activeToggles.push('CREATE-STONE-SELLER-ACCOUNT')

                //SetToggle recording the active toggles
                ToggleManager.setToggle(activeToggles) 
                
                this.setState({ validCredentials: true, loader:false})
              })
              .catch((error) => {
                console.log(`Erro ao verificar credenciais do usuário Logado: ${error}`);
                this.setState({loader:false});
              })
            })
        }).catch((error) => {
          if (error) {
            const { status, data } = error.response ? error.response : { status: '', data: '' }
            console.log(`Status: ${status}, Error: ${error}, Data: ${data}`)

            // Checks if the status is 400 and error is invalid_grant (Unauthorized) in order to change the inputs' appeareance
            if (status === 400 && data.error === 'invalid_grant') {
              this.setState({
                validCredentials: false,
                loader: false
              })
            }
          }
          this.setState({loader: false})            
        })
    }
  }

  componentWillMount() {
    //CredentialManager.logout()
    //ToggleManager.logout()
  }

  render() {
    const { validUser, validPassword, validCredentials, loader, isNewUser } = this.state

    // Checks if the inputs are filled in, othwerwise, sets error classes
    const errorUser = validUser ? '' : 'error error-text'
    const errorPassword = validPassword ? '' : 'error error-text'
    let errorLogin = ''
    if (validUser && validPassword) {
      errorLogin = validCredentials ? '' : 'error '
    }

    if (CredentialManager.userIsAuthenticated()) {
      this.verifyNewUser(CredentialManager.getAuthenticatedUser().name)
      .then( (dataIsNewUser)=> {
          if(dataIsNewUser){
            window.location.replace( 'changepassword');
          }else{
            window.location.replace( 'dashboard');
          }
      })

      ManagementController.updateLastLogon(CredentialManager.getAuthenticatedUser().name)
      .then(() => {
        console.log('Atualizado com sucesso')
      })
      .catch((error) => {
        console.log(error)
      })
    }

    function handleSessionControlLogin(logoutType){
      console.log('Timeout here...')
    }

    return (
      <div className="login-background">
        <UnauthenticatedSessionControl
          storageTokenKey="TOKEN"
          onLogin={handleSessionControlLogin}
        />
        <div className="wrap-login">
          <Logo />
          <InputText
            label={i18n.t('User')}
            placeholder={i18n.t('youremail@domain.com.br')}
            onChange={this.handleChange}
            errorMessage={` - ${i18n.t('Required')}`}
            className={errorLogin + errorUser}
            id="user"
            value={this.state.input_text_user}
            readOnly={true}
          />
          <InputText
            label={i18n.t('Password')}
            placeholder="* * * * * * * *"
            type="password"
            onChange={this.handleChange}
            errorMessage={`- ${i18n.t('Required')}`}
            id="password"
            className={errorLogin + errorPassword}
          />
          
          {validCredentials ? '' : <InvalidUserPassword />}
          <ActionButton type="submit" componentClass="btn-action btn-login" text={i18n.t('Login')} onClickAction={this.click} />
          <div className="column" style={{width: '100%', marginTop: '30px', textAlign: 'center'}}>
                <Link  to="/requestpassword" >
                        {i18n.t('Forgot password')}   
                </Link>
              </div>
        </div>
        {loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
      </div>
    )
  }
}
