import axios from 'axios'
import env from './Environment'

import { getAuthorizationHeaders } from './Connection'

const axiosNotification = axios.create({
    baseURL: env.getUrlEnv().REACT_APP_MKTP_FRONT_BACKEND_URL,
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors'
})

axiosNotification.interceptors.request.use(
    getAuthorizationHeaders,
    error => Promise.reject(error)
)


export const getNotificationBySellerId = (url, params) => (
    new Promise((resolve, reject) => {
        axiosNotification.get(`${url}?${params}`)
          .then(({ data }) => {
              console.log(data);
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));

export const getNotificationById = (url, id) => (
    new Promise((resolve, reject) => {
        axiosNotification.get(`${url}/${id}`)
          .then(({ data }) => {
              console.log(data);
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));

export const createNotification = (url, data) => (
    new Promise((resolve, reject) => {
        axiosNotification.post(url, data)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(err => {
          reject(err)
      });
    }
));

export const editNotification = (url, data) => (
    new Promise((resolve, reject) => {
        axiosNotification.post(url, data)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(err => {
          reject(err)
      });
    }
));

export const deleteNotification = (url, params) => (
    new Promise((resolve, reject) => {
        axiosNotification.delete(`${url}/${params}`)
          .then(({ data }) => {
              console.log(data);
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));

export const deleteNotificationGroup = (url) => (
    new Promise((resolve, reject) => {
        axiosNotification.delete(url)
          .then(({ data }) => {
              console.log(data);
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));

export const uploadMassiveNotification = (url, data) => (
    new Promise((resolve, reject) => {
        axiosNotification.post(url, data)
       .then(({ data }) => {
         resolve(data)
       })
       .catch(err => {
           reject(err)
       });
   }
));