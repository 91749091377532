import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import i18n from '../../../utils/i18n/i18n'

import './InputTextArea.css'

/**
 * **************************************************************************************
 * Render a input textarea - <InputTextArea>
 * **************************************************************************************
 */
export class InputTextArea extends Component {
  constructor(props) {
    super(props)

    this.state = {
      length: 0
    }

    bindAll(this, ['onChange'])
  }

  onChange(event) {
    const { id, onChangeValue } = this.props

    const { value } = event.target

    this.setState({
      length: value.length
    })
    onChangeValue(id, value)
  }

  render() {
    const {
      id,
      className,
      label,
      placeholder,
      value,
      maxLength,
      readOnly
    } = this.props

    const { length } = this.state

    const count = `<strong>${maxLength - length}</strong>`
    const charactersLeft = i18n.t('characters left', { count })

    return (
      <div className={`wrap-input-text ${className}`}>
        <label htmlFor={id}>{label}</label>
        {/* eslint-disable react/no-danger */}
        <span className="counter" dangerouslySetInnerHTML={{ __html: charactersLeft }} />
        <textarea id={id} value={value} type="text" placeholder={placeholder} maxLength={maxLength} onChange={this.onChange} readOnly={readOnly}/>
      </div>
    )
  }
}

InputTextArea.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChangeValue: PropTypes.func
}

InputTextArea.defaultProps = {
  maxLength: 1000
}

export default InputTextArea