import React, { Component, Fragment } from 'react'

import { Redirect } from 'react-router-dom'

import Closable from '../../components/closable/Closable'

import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'

import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'

import SellerDiagnosisController from '../../controllers/SellerDiagnosisController'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'

import { format, parse, parseISO } from 'date-fns'

import './SellerDiagnosis.css'

class SellerDiagnosis extends Component {

  constructor(props) {
    super(props)

    setInterval(this.checkFilter, 10000);

    this.state = {
      showPartnerMessage: false,
      message: '',
      colorMessage: '',
      gridApi: undefined,
      loader: false,
      sellerList: [],
      seller: undefined,
      sellerId: '',
      sellerReportStatus: '',
      sellerReportUrl: '',
      sellerReportCreatedOn: '',
      disableExport: true,
      reportToday: false,
      reportData: false,
      waitingReport: false,
      waitingSeller: ''
    }

    bindAll(this, [
      'closePartnerMessage',
      'onGridReady',
      'refreshGrid',
      'onBtnExportGrid',
      'handleOnClickFilter',
      'formatDate',
      'formatStatusReport',
      'handleChangeEcommerceId',
      'searchSellerByTrandingName',
      'setValueSellerSelected',
      'checkFilter'
    ]);
  }

  checkFilter = () => {
    if (this.state.waitingReport){      
      this.refreshReportInfo(this.state.waitingSeller)
    }    
  }

  closePartnerMessage = () => {
    this.setState({ showPartnerMessage: false })
  }
C
  onGridReady = params => {
    this.setState({ gridApi: params.api })
  }

  refreshGrid = () => {
    let params = {
      force: true,
      suppressFlash: true,
    }

    this.state.gridApi.refreshCells(params);
  }

  onBtnExportGrid = () => {

    if (!this.formValidate()) {
      this.setState({
        showPartnerMessage: true,
        message: `Você deve preencher o campo Seller!`,
        colorMessage: 'header-message header-error-message'
      });
    } else {
      let ecommerceId = this.state.seller != null ? this.state.seller.ecommerceId : undefined;
      this.setState({ loader: true });
      this.getSellerReportUrl(ecommerceId);
    }
  };

  handleOnClickFilter = (event) => {

    if (!this.formValidate()) {
      this.setState({
        showPartnerMessage: true,
        message: `Você deve preencher o campo Seller!`,
        colorMessage: 'header-message header-error-message'
      });
    } else {
      this.setState({ loader: true });
      let ecommerceId = this.state.seller != null ? this.state.seller.ecommerceId : undefined;
      this.fillGridSellerFilter(ecommerceId)
    }
  }  

  setPendingInfo = (trandingName) => {
    console.log("setPendingInfo: ", trandingName);
    this.setState({
      waitingReport: true,
      waitingSeller: trandingName,
      sellerReportStatus: 'PENDING',
      sellerId: trandingName,
      sellerReportUrl: '',
      reportToday: true,
      reportData: false,
      sellerReportCreatedOn: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      buttonExportDisable: true,
      loader: false
    });              
  }

  refreshReportInfo = (trandingName) => {
      
    SellerDiagnosisController.getStatusBySeller(trandingName)
    .then(
      data => {
        const lastData = data[data.length - 1]          
        this.setState({
          waitingReport: lastData.status.localeCompare("PENDING")==0,
          waitingSeller: lastData.sellerId,
          sellerReportStatus: lastData.status,
          sellerId: lastData.sellerId,
          sellerReportUrl: encodeURI(lastData.reportUrl).localeCompare("undefined") != 0? encodeURI(lastData.reportUrl): '',
          reportToday: this.dateComparator(new Date(), lastData.createdOn),
          reportData: encodeURI(lastData.reportUrl).localeCompare("undefined") != 0? true: false,
          sellerReportCreatedOn: this.adjustGMT(lastData.createdOn),
          buttonExportDisable: !(lastData.length > 0),                        
          loader: false
        });          
      }).catch((error) => {          
        this.setState({
          loader: false,
          showPartnerMessage: true,
          message: `Erro ao tentar atualizar status do relatório: ${error}`,
          colorMessage: 'header-message header-error-message'            
        });
      });
    
    
  }

  handleOnClickGenerateReport = (event) => {
    this.setState({ loader: true });
    let ecommerceId = this.state.seller != null ? this.state.seller.ecommerceId : undefined;
    this.generateSellerReport(ecommerceId)    
  }

  formValidate = () => {
    if (!this.state.seller) { return false; }

    return true;
  }

  handleChangeEcommerceId = (event) => {
    const { value } = event.target

    this.setState({
      ecommerceId: value
    })
  }

  render() {

    const hasRoleSync = CredentialManager.userHasRole('ROLE_VIEW_SELLER_DIAGNOSIS')
    
    if (!hasRoleSync) {
      return <Redirect to="/error" />
    }

    return (
      <Fragment>
        <div className="container">
          <div className="column" style={{ width: '100%' }}>
            <h3>Diagnóstico do Seller</h3>
          </div>
          <div className="column" style={{ width: '25%' }}>
            <InputAutocomplete
              disabled={this.state.disabledAutocomplete}
              setValue={this.setValueSellerSelected}
              searchSellerByTrandingName={this.searchSellerByTrandingName}
              sellerList={this.state.sellerList}
            />
          </div>
          <div className="column" style={{ width: '40%' }}></div>
          <div className="column" style={{ width: '35%' }}></div>
          <div className="column" style={{ width: '10%', marginTop: '30px' }}>
            <ActionButton
              onClickAction={this.handleOnClickFilter}
              id="btnFiltrar"
              text="Filtrar"
              icon="icon-search"
              componentClass="btn btn-action" />
          </div>
        </div>
        {          
          (this.state.sellerId.length > 0) &&
          <div className="container">
            <div className="column" style={{ width: '100%' }}>
              <h3 style={{ fontWeight: "bold" }}>Relatório de Diagnóstico</h3>
            </div>
            <div className="container">
              <div className="column" style={{ width: '22%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>Seller:</span>
              </div>
              <div className="column" style={{ width: '78%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>{this.state.sellerId}</span>
              </div>              
              <div className="column" style={{ width: '22%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>Status:</span>
              </div>
              <div className="column" style={{ width: '78%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>{this.formatStatusReport(this.state.sellerReportStatus)}</span>
              </div>
              
              {this.state.reportToday || this.state.waitingReport &&   
              <div className="column" style={{ width: '22%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>Requisição feita em:</span>
              </div>}

              {this.state.reportToday || this.state.waitingReport &&   
              <div className="column" style={{ width: '78%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>{this.formatDate(this.state.sellerReportCreatedOn)}</span>
              </div>}

              {this.state.reportToday || this.state.waitingReport &&   
              <div className="column" style={{ width: '22%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>Url:</span>
              </div>}

              {
              this.state.reportToday && this.state.reportData &&
              <div className="column" style={{ width: '78%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>{<a href={this.state.sellerReportUrl}>Download do Relatório</a>}</span>
              </div>}
             
              {
              this.state.reportToday || this.state.waitingReport && !this.state.reportData &&
              <div className="column" style={{ width: '78%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
                <span>Download do relatório não disponível</span>
              </div>}

              <div className="column" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                <ActionButton
                  onClickAction={this.handleOnClickGenerateReport}
                  id="btnGenerateReport"
                  text="Gerar Relatório"
                  icon="icon-processing"
                  componentClass="btn btn-action"
                  disable={this.state.reportToday || this.state.waitingReport}
                />
              </div>
            </div>
          </div>
        }
        <section className="header-message-container">
          <Closable
            className={this.state.colorMessage || ''}
            isOpen={this.state.showPartnerMessage || false}
            onClose={this.closePartnerMessage}
            showCloseButton
            autoCloseTime={5000}
          >
            <span className="commission-partner-save-success">
              <i className="icon-ok-check" />
              {this.state.message}
            </span>
          </Closable>
        </section>
        {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon} /></div></div> : ''}
      </Fragment>
    )
  }

  formatDate = (data) => {
    let newDate = parseISO(data);
    let dateReturn = data;

    try {
      dateReturn = format(newDate, 'dd/MM/yyyy HH:mm:ss');
    } catch (e) {
      console.log(`Erro ao converter a coluna CreateOn: ${e}`);
    }
    return dateReturn;
  }

  dateComparator = (date1, date2) => {

    let newDate = parseISO(date2);
    newDate.setHours(newDate.getHours() - 3, newDate.getMinutes(), newDate.getSeconds())

    let newDateDay = format(newDate, 'dd')
    let newDateMonth = format(newDate, 'MM')
    let newDateYear = format(newDate, 'yyyy')

    return (
      (newDateDay.valueOf() == date1.getDate()) &&
      (newDateMonth.valueOf() == (date1.getMonth() + 1)) &&
      (newDateYear.valueOf() == date1.getFullYear())
    )
  }

  adjustGMT = (date) => {
    let newTime = parseISO(date)
    newTime.setHours(newTime.getHours() - 3, newTime.getMinutes(), newTime.getSeconds())
    return format(newTime, 'dd/MM/yyyy HH:mm:ss');
  }
  

  formatStatusReport = (data) => {

    if ("PENDING".localeCompare(data) == 0)
      return "Gerando o relatório."          
    if (!this.state.reportToday)
      return "Relatório não encontrado."
    if ("FINISHED".localeCompare(data) == 0)
      return "Relatório gerado."
    else
      return "Relatório gerado porém não foram encontrados dados para este seller."
  }

  searchSellerByTrandingName = (trandingName) => {
    SellerDiagnosisController.getSellerList(trandingName)
      .then((result) => {
        this.setState({
          sellerList: result
        })
      })
      .catch((error) => {
        this.setState({
          showPartnerMessage: true,
          message: `Erro ao buscar o Seller: ${error}`,
          colorMessage: 'header-message header-error-message'
        });
      });
  }

  setValueSellerSelected = (value) => {
    this.setState({
      seller: value
    }, () => { console.log(this.state.seller) });
  }

  generateSellerReport = (trandingName) => {

    SellerDiagnosisController.generateSellerReport(trandingName)
    .then(() => {
      this.setState({
        loader: false,
        showPartnerMessage: true,                
        message: `Requisição para geração do relatório enviada. Esse processo pode demorar alguns minutos.`,
        colorMessage: 'header-message header-success-message'
      }, () => {     
        this.setPendingInfo(trandingName)
      })            
    })
    .catch((error) => {        
        this.setState({
          loader: false,
          showPartnerMessage: true,
          message: `Erro ao tentar chamar a API do Diagnóstico do Seller: ${error}`,
          colorMessage: 'header-message header-error-message'
        });
    });    
  }

  fillGridSellerFilter = (trandingName) => {
    console.log("trandingName: ", trandingName);
    SellerDiagnosisController.getStatusBySeller(trandingName)
      .then(
        data => {
          const lastData = data[data.length - 1]
          this.setState({
            waitingReport: lastData.status.localeCompare("PENDING")==0,
            waitingSeller: lastData.sellerId,
            sellerReportStatus: lastData.status,
            sellerId: lastData.sellerId,
            sellerReportUrl: encodeURI(lastData.reportUrl).localeCompare("undefined") != 0? encodeURI(lastData.reportUrl): '',
            reportToday: this.dateComparator(new Date(), lastData.createdOn),
            reportData: encodeURI(lastData.reportUrl).localeCompare("undefined") != 0? true: false,
            sellerReportCreatedOn: this.adjustGMT(lastData.createdOn),
            buttonExportDisable: !(lastData.length > 0),                        
            loader: false
          });          
        }).catch((error) => {          
          if (error.response.status == "404")
            this.setState({
              waitingReport: false,
              sellerReportStatus: '',
              sellerId: trandingName,
              sellerReportUrl: '',
              reportToday: false,
              sellerReportCreatedOn: '',
              buttonExportDisable: true,
              loader: false
            });
          else
            this.setState({
              loader: false,
              showPartnerMessage: true,
              message: `Erro ao tentar chamar a API do Report: ${error}`,
              colorMessage: 'header-message header-error-message'
            });
        });
  }

  getSellerReportUrl = (trandingName) => {
    console.log("trandingName: ", trandingName);
    SellerDiagnosisController.getUrlReport(trandingName)
      .then(
        data => {
          window.open(data, '_blank');
          this.setState({ loader: false });
        }).catch((error) => {
          console.log(error)
          if (error.response.status == "404")
            this.setState({
              loader: false,
              showPartnerMessage: true,
              message: `Não existem produtos para este seller: ${error}`,
              colorMessage: 'header-message header-error-message'
            });
          else
            this.setState({
              loader: false,
              showPartnerMessage: true,
              message: `Erro ao tentar chamar a API do Report: ${error}`,
              colorMessage: 'header-message header-error-message'
            });
        });
  }

}

export default SellerDiagnosis