import React, { Component, Fragment } from 'react'
import i18n from '../../utils/i18n/i18n'
import InputText from '../form-components/InputText'
import ActionButton from '../../components/buttons/ActionButton'
import { CredentialManager } from '../../commons/CredentialManager'
import { format, parseISO } from 'date-fns';
import CategoryController from '../../controllers/CategoryController'
import Closable from '../../components/closable/Closable'
import { bindAll } from 'lodash'

class SelectedItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      eventType: '',
      showPartnerMessage: false,
      message: '',
      colorMessage: ''
    }

    bindAll(this, ['renderRow', 'closePartnerMessage','handleChange','handleSubmit'])
  }

  renderRow(row){
    return (<li key={Math.floor(Math.random() * 1000)}>Atualizado para Categoria de Gestão {row.miraklCategory} por {row.user} em  {format(parseISO(row.dtCreated),'dd/MM/yyyy HH:mm')}</li>)
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.state.category[name] = value.replace(/\D/,'');

    this.setState({
      eventType: event.type
    });
  }

  handleSubmit = (event) => {
    let valid = this.state.category.miraklCategory != '';

    this.state.category.userName = CredentialManager.getAuthenticatedUser().name;

    if (this.state.category.exist && valid) {
      CategoryController.updateCategory(this.state.category)
        .then((respose) => {
          this.setState({ 
            showPartnerMessage: true, 
            message: 'Alteração no De/Para de categoria realizado com sucesso.',
            colorMessage: 'header-message header-success-message'
          });

          this.props.refreshTree(respose.data);

          console.log('Alteração no De/Para de categoria realizado com sucesso.');
        })
        .catch((error) => {
          this.setState({ 
            showPartnerMessage: true, 
            message: 'Erro ao tentar alterar De/Para de categoria.',
            colorMessage: 'header-message header-error-message'
          });

          console.log(`Erro ao tentar alterar De/Para de categoria: ${error}`);
        });
      } else if (valid) {
        CategoryController.createCategory(this.state.category)
        .then((respose) => {
          this.setState({ 
            showPartnerMessage: true, 
            message: 'Novo do no De/Para de categoria realizado com sucesso.',
            colorMessage: 'header-message header-success-message'
          });

          this.props.refreshTree(respose.data);

          console.log('Novo do no De/Para de categoria realizado com sucesso.');
        })
        .catch((error) => {
          this.setState({ 
            showPartnerMessage: true, 
            message: 'Erro ao tentar incluir o no De/Para de categoria.',
            colorMessage: 'header-message header-error-message'
          });

          console.log(`Erro ao tentar incluir novo De/Para de categoria: ${error}`);
        });
      }
    
    event.preventDefault();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.category.miraklCategory !== this.state.category.miraklCategory) {
      console.log(prevState.category);
      console.log(this.state.category);
    }
  }
  
  closePartnerMessage() {
    this.setState({ showPartnerMessage: false })
  }

  render() {
    this.state = {
      ...this.props,
      eventType: this.state.eventType,
      showPartnerMessage: this.state.showPartnerMessage,
      message: this.state.message,
      colorMessage: this.state.colorMessage
    }
    const hasRoleSave = CredentialManager.userHasRole('ROLE_SAVE_CATEGORY')

    function isEmpty(obj) {
      for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
              return false;
      }
  
      return true;
    }

    let style = "block";
    if(isEmpty(this.props.category)){
      style = "none";
    }

    return(
      <Fragment>
        <div className={style}>
        <h3 id="title">{ i18n.t('Category') } - {this.props.category.name}</h3>
      
        <div className="separator" /><br/>
          <form onSubmit={this.handleSubmit}>
            <div className="wrap-input-text"  >
              <InputText
                id="categoriaNavegacao"
                value={this.state.category.name || ''}
                label={ i18n.t('Categoy Navigation') }
                maxLength={60}
                name="name"
                onChange={this.handleChange}
                disabled
              />
            </div>
            <div className="wrap-input-text">
              <InputText
                id="categoriagestao"
                label="Categoria de Gestão"
                maxLength={12}
                value={this.state.category.miraklCategory || ''}
                name="miraklCategory"
                onChange={this.handleChange}
              />
            </div>
        
            <div >
              <InputText
                id="syncMirakl"
                value={this.state.category.syncMirakl ? 'sim' : 'não'}
                label="Sincronizado com a Mirakl"
                maxLength={3}
                name="syncMirakl"
                onChange={this.handleChange}
                disabled
              />
            </div>
            <div >
            
              <InputText
                id="dtSyncMirakl"
                value={this.state.category.dtSyncMirakl ? format(parseISO(this.state.category.dtSyncMirakl),'dd/MM/yyyy HH:mm') : ''}
                label="Data Sincronismo"
                maxLength={10}
                name="dtSyncMirakl"
                onChange={this.handleChange}
                disabled
              />
            </div>
      
            <div>
              <h1 id="title">Historico</h1>
              <ul id="description">
                {Array.isArray(this.props.category.histories) ? this.props.category.histories.map(this.renderRow) : 'Sem registro de historico'}
              </ul>
            </div>
            <div className="separator" /><br/>
            <div className="btn">

              <ActionButton type="submit" componentClass="btn-action" text="Salvar" />
            </div>
          </form>
        </div>

        {/* Criar componente de mensagem */}

        <section className="header-message-container">
          <Closable
            className={this.state.colorMessage || ''}
            isOpen={this.state.showPartnerMessage || false}
            onClose={this.closePartnerMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="commission-partner-save-success">
              <i className="icon-ok-check" />
              {this.state.message}
            </span>
          </Closable>
        </section>

      </Fragment>
    )
  }
}

export default SelectedItem