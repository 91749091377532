import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom'

import { format, getDate, getMonth, getYear, getHours, getMinutes, sub, parse, differenceInDays } from 'date-fns'
import Closable from '../../../components/closable/Closable'

import ActionButton from '../../../components/buttons/ActionButton'
import InputDatePicker from '../../../components/form-components/input-date-picker/inputDatePicker'
import InputTimePicker from '../../../components/form-components/input-time-picker/InputTimePicker'
import InputAutocomplete from '../../../components/form-components/input-autocomplete/inputAutocomplete'
import InputText from '../../../components/form-components/InputText'

import ProductLockedGrid from './ProductLockedGrid';
import SellerController from '../../../controllers/SellerController'
import ProductController from '../../../controllers/ProductController'
import { CredentialManager } from '../../../commons/CredentialManager'
import CustomDialog from '../../../components/commons/dialog/CustomDialog'
import InputPercentage from '../../../components/form-components/input-percentage/InputPercentage'
import green_light from '../../../resources/images/green_light.png'
import yellow_light from '../../../resources/images/yellow_light.png'
import red_light from '../../../resources/images/red_light.png'
import { bindAll } from 'lodash'

import loaderIcon from '../../../resources/images/loaderComplete.gif'
import './ProductLocked.css'
import Dropzone from '../../../components/dropzone/DropZone';

class ProductLocked extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      startDateNow: new Date(),
      seller: undefined,
      showPartnerMessage: false,
      message: '',
      colorMessage: '',
      sellerList: [],
      statusList: [{'description':'Análise', 'value':'PENDING'},
             {'description':'Bloqueado', 'value':'LOCKED'},
             {'description':'Liberado', 'value':'OK'}],
      statusSelected: 'PENDING',
      fromDate: new Date(),
      fromTime: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0),
      toDate: new Date(),
      toTime: new Date(),
      fromDateTime: this.formatDateToISO(sub(new Date(),{ days: 30}), new Date()),
      toDateTime: this.formatDateToISO(new Date(), new Date()),
      gridApi: undefined,
      productList: [],
      inputRMS: '' ,
      matchList: ['AMBOS','SIM','NÃO'],
      matchSelected: 'AMBOS',
      addLockProductMassiveScreenOpened: false,
      fileUpload: undefined,
      editLockOpen: false,
      paramOkPercentMin: 0,
      paramOkPercentMax: 0,
      paramPendingPercentMin: 0,
      paramPendingPercentMax: 0,
      paramLockedPercentMin: 0,
      paramLockedPercentMax: 0,
      unenableLiberar: false,
      unenableRejeitar: false
    };

    bindAll(this, ['setValueSellerSelected',
                   'handleOnClickNow',
                   'handleOnClickYesterday',
                   'handleOnClickSevenDays',
                   'searchSellerByTrandingName',
                   'onChangeValue',
                   'onGridReady',
                   'fillGridGeralFilter',
                   'searchSellerByTrandingName',
                   'setValueSellerSelected',
                   'closePartnerMessage',
                   'handleChangeRms',
                   'onChangeMatchValue',
                   'onFileAdded',
                   'handleOnEditLockClick',
                   'handleChangeParamOkPercentMin',
                   'handleChangeParamOkPercentMax',
                   'handleOnCloseProductLockScreen'
                  ]);
  }

  

  closePartnerMessage = () => {
    this.setState({ showPartnerMessage: false })
  }
 
  handleOnClickNow = () => {
    this.setState({
      fromDate: new Date(),
      fromTime: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0),
      toDate: new Date(),
      toTime: new Date()
    })
  }

  handleOnClickYesterday = () => {
    this.setState({
      fromDate: sub(new Date(),{ days: 1}),
      fromTime: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate()-1, 0, 0, 0),
      toDate: sub(new Date(),{ days: 1}),
      toTime: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate()-1, 23, 59, 59)
    })
  }

  handleOnClickSevenDays = () => {
    this.setState({
      fromDate: sub(new Date(),{ days: 7}),
      fromTime: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate()-7, 0, 0, 0),
      toDate: new Date(),
      toTime: new Date()
    })
  }

  setValueSellerSelected = (value) => {
    this.setState({
      seller: value
    }, () => {console.log(this.state.seller)});
  }

  searchSellerByTrandingName = (trandingName) => {
    SellerController.getSellerList(trandingName)
      .then((result) => {
        this.setState({
          sellerList: result
        })
      })
      .catch((error) => {
        this.setState({
          showPartnerMessage: true, 
          message: `Erro ao buscar o Seller: ${error}`,
          colorMessage: 'header-message header-error-message'
        });
      });
  }

  renderOptions = () => {
    let list = [];
    this.state.statusList.map((item, idx) => {
      list.push(<option key={idx} value={item.value}>{item.description}</option>)
    });

    return list;
  }

  renderMatchOptions = () => {
    let list = [];
    this.state.matchList.map((item, idx) => {
      list.push(<option key={idx} value={item}>{item}</option>)
    });

    return list;
  }

  formatDateToISO = (date, time) => {
    let dateTime;
    
    if (date && time) {
      let day = getDate(date)
      let month = getMonth(date)
      let year = getYear(date)
      let hours = getHours(time)
      let minutes = getMinutes(time)
      dateTime = new Date(year, month, day, hours, minutes, 0)
    } else {
      return this.formatDateToISO(new Date(), new Date());
    }

    return format(dateTime, 'dd/MM/yyyy HH:mm:ss')
  }

  handleChangeFromDate = (value, e) => {
    this.setState({ 
      fromDate: value,
      fromDateTime: this.formatDateToISO(value, this.state.fromTime),
      buttonChartDisable: true
    }, () => console.log(`Time From: ${this.state.fromTime}`)); 
  }

  handleChangeFromTime = (value, e) => {
    this.setState({ 
      fromTime: value, 
      fromDateTime: this.formatDateToISO(this.state.fromDate, value),
      buttonChartDisable: true
    });
  }

  handleChangeToDate = (value, e) => {
    this.setState({ 
      toDate: value, 
      toDateTime: this.formatDateToISO(value, this.state.toTime),
      buttonChartDisable: true,
      diffBetweenDays: differenceInDays(parse(this.state.toDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()), parse(this.state.fromDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()))
    });
  }

  handleChangeToTime = (value, e) => {
    this.setState({ 
      toTime: value, 
      toDateTime: this.formatDateToISO(this.state.toDate, value),
      buttonChartDisable: true      
    });
  }

  handleOnAddLockProductMassiveClick = () => {
    this.setState({
        addLockProductMassiveScreenOpened: true
    })
  }
  
  handleOnCancelLockProductMassiveClick = () => {
    this.setState({
      addLockProductMassiveScreenOpened: false,
      fileUpload: undefined
    })
  }

  onFileAdded(file) {
    this.setState({
      fileUpload: file
    })
  }

  handleOnEditLockClick = () => {
        
    if (!this.state.editLockOpen){            
        this.getLockProductParams()
    }
}

getLockProductParams = () => {
        
  ProductController.getLockProductParams('')
  .then((result) => {
    if (result.status === 'Error') {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `${result.message}`,
        colorMessage: 'header-message header-error-message'
      });
    } else {
      this.setState({
        loader: false,              
        paramOkPercentMin:  100 * result.paramOkPercentMin,
        paramOkPercentMax:  100 * result.paramOkPercentMax,
        paramPendingPercentMin: 100 * result.paramPendingPercentMin,
        paramPendingPercentMax: 100 * result.paramPendingPercentMax,
        paramLockedPercentMin: 100 * result.paramLockedPercentMin,
        paramLockedPercentMax: 100 * result.paramLockedPercentMax,
        editLockOpen: !this.state.editLockOpen
      });
      console.log(result)
    }
  })
  .catch((error) => {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Erro ao tentar ler parâmetros de trava de preço.`,
        colorMessage: 'header-message header-error-message'
      });
  })        
}

  
  handleOnSaveAddLockProductMassiveClick = () => {
    this.setState({ loader: true });

    let user = CredentialManager.getAuthenticatedUser().name;

    if (this.state.fileUpload == undefined) {
      this.setState({
        showPartnerMessage: true,
        message: `Não foi selecionado um arquivo para ser enviado!`,
        colorMessage: 'header-message header-error-message',
        loader: false
      });
      
      return;
    }

    ProductController.uploadMassiveLockProduct(this.state.fileUpload, user)
      .then((data) => {
        this.setState({
          showPartnerMessage: true,
          message: `Arquivo enviado para bloqueio / desbloqueio massivo!`,
          colorMessage: 'header-message header-success-message',
          loader: false,
          fileUpload: undefined,
          addLockProductMassiveScreenOpened: false
        });
      })
      .catch((error) => {
        this.setState({
          showPartnerMessage: true,
          message: `Erro enviar arquivo para bloqueio / desbloqueio massivo: ${error}`,
          colorMessage: 'header-message header-error-message',
          loader: false
        });
      })
}

  onChangeMatchValue = (e) => {
    this.setState({ matchSelected: e.target.value });
  }

  onChangeValue = (e) => {
    this.setState({ 
      statusSelected: e.target.value,
      unenableLiberar: e.target.value == 'OK' ? true : false,
      unenableRejeitar: e.target.value == 'LOCKED' ? true : false
    });
  }

  fillGridGeralFilter = () => {
    this.setState({ loader: true });

    if (!this.validFilter())
      return

    var fromDate = this.formatDateToISO(this.state.fromDate, this.state.fromTime);
    var toDate = this.formatDateToISO(this.state.toDate, this.state.toTime);
    
    let ecommerceId = this.state.seller != undefined && this.state.seller.ecommerceId != undefined ? this.state.seller.ecommerceId : null;

    ProductController.getListLockProduct(fromDate, toDate, ecommerceId, this.state.statusSelected, this.state.inputRMS, this.state.matchSelected)
      .then((result) => {
        this.setState({ 
          productList: result.hasOwnProperty('lockProductResponses') ? result.lockProductResponses : [],
          loader: false,
        }, () => { 
          this.state.gridApi.setRowData(result.hasOwnProperty('lockProductResponses') ? result.lockProductResponses : []); 
          this.refreshGrid();
        });      
      }).catch((error) => {
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Erro ao tentar recuperar a lista de produtos conforme o filtro selecionado.`,
          colorMessage: 'header-message header-error-message'
        });
      })
  }

  validFilter = () => {
    if (!this.state.fromDate || 
        !this.state.toDate || 
        this.state.statusSelected === "" ) {
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `É obrigatório os campos de Datas e Status.`,
            colorMessage: 'header-message header-error-message'
          });
          return false;
        }

    return true;
  }

  onGridReady = params => {
    this.setState({ gridApi: params.api })
  }

  refreshGrid = () => {
    var params = {
      force: true,
      suppressFlash: true,
    };

    this.state.gridApi.refreshCells(params);
  }

  handleOnClickPesquisar = () => {
    this.fillGridGeralFilter();
  }

  handleOnClickExportarCSV = () => {
    var params = {
      columnSeparator: ';'
    };
    this.state.gridApi.exportDataAsCsv(params);
  };

  handleOnClickLiberar =() => {
    let list = this.state.gridApi.getSelectedRows();
    let listProductSku = [];
    let user = CredentialManager.getAuthenticatedUser().name;
    list.map((item) => {
      listProductSku.push(item.productSku);
    })

    if (!this.isListValid(listProductSku)) {
      return;
    }

    this.setState({ loader: true });

    ProductController.unlockProduct(listProductSku, user)
      .then((result) => {
        this.setState({ 
          productList: [],
          loader: false,
        }, () => { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Produtos enviados para desbloqueio!`,
            colorMessage: 'header-message header-success-message'
          });

          this.state.gridApi.setRowData([]); 
          this.refreshGrid();
        });      
      }).catch((error) => {
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Erro ao tentar liberar os produtos selecionados.`,
          colorMessage: 'header-message header-error-message'
        });
      })
  }

  handleOnClickRejeitar =() => {
    let list = this.state.gridApi.getSelectedRows();
    let listProductSku = [];
    let user = CredentialManager.getAuthenticatedUser().name;
    list.map((item) => {
      listProductSku.push(item.productSku);
    })

    if (!this.isListValid(listProductSku)) {
      return;
    }

    this.setState({ loader: true });

    ProductController.lockProduct(listProductSku, user)
      .then((result) => {
        this.setState({ 
          productList: [],
          loader: false,
        }, () => { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Produtos enviados para bloqueio!`,
            colorMessage: 'header-message header-success-message'
          });
          
          this.state.gridApi.setRowData([]); 
          this.refreshGrid();
        });      
      }).catch((error) => {
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Erro ao tentar rejeitar os produtos selecionados.`,
          colorMessage: 'header-message header-error-message'
        });
      })
  }

  handleChangeRms = (event) => {
    const { value } = event.target

    this.setState({
      inputRMS: value
    })
}

  isListValid = (listProductSku) => {
    let isValid = listProductSku.length > 0 ? true : false;

    if (!isValid) {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `É obrigatório selecionar ao menos 1 item na lista abaixo.`,
        colorMessage: 'header-message header-error-message'
      });
    }

    return isValid;
  }

  handleChangeParamOkPercentMin = (id, value) => {                
    this.state.paramOkPercentMin = value
  }

  handleChangeParamOkPercentMax = (id, value) => {                
      this.state.paramOkPercentMax = value
  }

  handleChangeParamPendingPercentMin = (id, value) => {                
    this.state.paramPendingPercentMin = value
  }

  handleChangeParamPendingPercentMax = (id, value) => {                
      this.state.paramPendingPercentMax = value
  }

  handleChangeParamLockedPercentMin = (id, value) => {                
    this.state.paramLockedPercentMin = value
  }

  handleChangeParamLockedPercentMax = (id, value) => {                
      this.state.paramLockedPercentMax = value
  }


  handleOnCloseProductLockScreen = () => {
    this.setState({
        loader: false,
        editLockOpen: false
    })
  }
  handleOnSaveLockProductClick = ()     => {
    let logUser = CredentialManager.getAuthenticatedUser().name;
    const param = (
        {
            "paramOkPercentMin": `${this.state.paramOkPercentMin/100}`,
            "paramOkPercentMax": `${this.state.paramOkPercentMax/100}`,
            "paramPendingPercentMin": `${this.state.paramPendingPercentMin/100}`,
            "paramPendingPercentMax": `${this.state.paramPendingPercentMax/100}`,
            "paramLockedPercentMin": `${this.state.paramLockedPercentMin/100}`,
            "paramLockedPercentMax": `${this.state.paramLockedPercentMax/100}`,
            "logUser": `${logUser}`
        }
    )
    this.setLockProductParams(param)
    console.log("Salvou os parâmetros de trava de Produtos")
}

setLockProductParams = (params) => {
        
  ProductController.setLockProductParams(params)
  .then((result) => {
    if (result.status === 'Error') {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `${result.message}`,
        colorMessage: 'header-message header-error-message'
      });
    } else {
      this.setState({
        loader: false,              
        showPartnerMessage: true,
        message: `Parâmetros de trava de produtos salvos.`,
        colorMessage: 'header-message .header-success-message',
        editLockOpen: !this.state.editLockOpen
      });
      console.log(result)
    }
  })
  .catch((error) => {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Erro ao tentar gravar parâmetros de trava de produto.`,
        colorMessage: 'header-message header-error-message'
      });
  })        
}

  render () {

    const hasRoleSync = CredentialManager.userHasRole('ROLE_VIEW_PRODUCT_LOCK')
    
    if (!hasRoleSync) {
      return <Redirect to="/error" />
    }

    return (
      <Fragment>
        <div className='container'>
          <div className='column' style={{width: '100%'}}> 
            <h2>Produtos Bloqueados</h2>
          </div>
          <div className="column" style={{width: '10%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickNow} 
                id="btnNow" 
                text="Hoje"
                componentClass="btn btn-action" />
          </div>
          <div className="column" style={{width: '10%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickYesterday} 
                id="btnYesterday" 
                text="Ontem"
                componentClass="btn btn-action" />
          </div>
          <div className="column" style={{width: '67%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickSevenDays} 
                id="btnSevenDays" 
                text="Últimos 7 dias"
                componentClass="btn btn-action" />
          </div>
          <div className="column" style={{ width: '13%', marginTop: '30px'}}>
              <ActionButton
                  onClickAction={this.handleOnEditLockClick}
                  id="btnEditLock"
                  text="Editar Trava"
                  icon="icon-edit"
                  componentClass="btn btn-action" />
          </div>
          
          <div className="column" style={{width: '15%', marginTop: '30px'}}>
            De: <InputDatePicker startDate={this.state.fromDate} name="fromDate" handleChange={(value, e) => this.handleChangeFromDate(value, e)} />
          </div>
          <div className="column" style={{width: '15%', marginTop: '30px'}}>
          <InputTimePicker value={this.state.fromTime} name="fromTime" handleChange={(value, e) => this.handleChangeFromTime(value, e)} />
          </div>
          <div className="column" style={{width: '15%', marginTop: '30px'}}>
          Até: <InputDatePicker startDate={this.state.toDate} name="toDate" handleChange={(value, e) => this.handleChangeToDate(value, e)} />
          </div>
          <div className="column" style={{width: '55%', marginTop: '30px'}}>
            <InputTimePicker value={this.state.toTime} name="toTime" handleChange={(value, e) => this.handleChangeToTime(value, e)} />
          </div>
          <div className="column" style={{width: '40%', marginTop: '30px'}}>
              Seller:
          </div>
          <div className="column" style={{width: '60%', marginTop: '30px'}}>
              Status:
          </div>
          
          <div className="column" style={{width: '40%', marginTop: '10px'}}>
            <InputAutocomplete 
              setValue={this.setValueSellerSelected} 
              searchSellerByTrandingName={this.searchSellerByTrandingName} 
              sellerList={this.state.sellerList}
              width={350} 
            />
          </div>
          <div className='column' style={{width: '60%', marginTop: '5px'}}>
            <select value={this.state.statusSelected} onChange={this.onChangeValue} style={{width: '30%'}}>
              {this.renderOptions()}
            </select>
          </div>

          <div className="column" style={{width: '40%', marginTop: '30px'}}>
              Match:
          </div>
          <div className="column" style={{width: '60%', marginTop: '30px'}}>
              RMS:
          </div>
          <div className='column' style={{width: '40%'}}>
            <select value={this.state.matchSelected} onChange={this.onChangeMatchValue} style={{width: '30%'}}>
              {this.renderMatchOptions()}
            </select>
          </div>
          
          <div className="column" style={{width: '18%'}}>
              <InputText
                        id="inputRMS"
                        value={this.state.inputRMS}
                        placeholder={'Digite aqui o RMS'}
                        maxLength={30}
                        onChange={this.handleChangeRms}
                    />
          </div>

          <div className='column' style={{width: '42%'}}></div>
          
          <div className="column" style={{width: '10%', marginTop: '30px'}}>
            <ActionButton 
              onClickAction={this.handleOnClickPesquisar} 
              id="btnPesquisar" 
              text="Pesquisar"
              componentClass="btn btn-action" />
          </div>
          <div className="column" style={{width: '12%', marginTop: '30px'}}>
            <ActionButton 
              onClickAction={this.handleOnClickExportarCSV} 
              id="btnExportarCSV" 
              text="Exportar CSV"
              componentClass="btn btn-action" />
          </div>
          <div className="column" style={{ width: '78%', marginTop: '30px'}}>
                  <ActionButton
                          onClickAction={this.handleOnAddLockProductMassiveClick}
                          id="btnAddAdjustmentMassive"
                          text="Importar CSV"
                          componentClass="btn btn-action" />                        
              </div>

        </div>
        <div className="container">
     
          <div className="column" style={{width: '90%', marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
            <ActionButton 
              onClickAction={this.handleOnClickLiberar} 
              id="btnLiberar" 
              text="Liberar"
              componentClass="btn btn-action"
              disable={this.state.unenableLiberar} />
          </div>
          <div className="column" style={{width: '10%', marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
            <ActionButton 
              onClickAction={this.handleOnClickRejeitar} 
              id="btnRejeitar" 
              text="Rejeitar"
              componentClass="btn btn-action"
              disable={this.state.unenableRejeitar} />
          </div>

          <ProductLockedGrid  
            height={450} 
            width="100%" 
            ProductList={this.state.productList} 
            onGridReady={this.onGridReady}
          />
        </div>

        <CustomDialog
              title='Adicionar Atividades Massa'
              open= {this.state.addLockProductMassiveScreenOpened}
              id="lockProductMassive"
          >

              <div className='column' style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                <span style={{ 'font-size': '30px', 'color': '#156992', 'font-weight': 'bold'}}>Upload do arquivo CSV: </span>
              </div>
              <div className="column" style={{ width: '100%', display: 'flex',  alignItems:'center'}}>
                  <Dropzone onFileAdded={this.onFileAdded} file={this.state.fileUpload} accept=".csv" />
              </div>
              <div className="column" style={{ width: '100%'}}></div>

              <div className="column" style={{
                  width: '35%',
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
              }}>

                  <ActionButton 
                      onClickAction={this.handleOnCancelLockProductMassiveClick}
                      id="btnCancelAddLockProductMassive"
                      text="Cancelar"
                      componentClass="btn btn-action" />

                  <ActionButton
                      onClickAction={this.handleOnSaveAddLockProductMassiveClick}
                      id="btnSaveAddLockProductMassive"
                      text="Enviar"
                      componentClass="btn btn-action"                            
                      />
              </div>
              
          </CustomDialog>


          <CustomDialog title='Alteração de travas de produto' open={this.state.editLockOpen}
          id="travaDeProdutoParam">
             
             <div className="column" style={{ width: '100%' }}>
                 <h2 style={{fontWeight: "bold"}}>Alteração na trava</h2>
             </div>
             <div className="container">
                 <div className="column" style={{ width: '15%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>Liberado </span>
                 </div>
                 <div className="column" style={{ width: '5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                  <img src={green_light}  width="15px" height="15px"/>
                 </div>
                 <div className="column" style={{ width: '5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>de</span>
                 </div>
                 <div className="column" style={{ width: '35%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <InputPercentage 
                         value={this.state.paramOkPercentMin}
                         onChangeValue={(id, value) => this.handleChangeParamOkPercentMin(id, value)}
                         />                                
                 </div>
                 <div className="column" style={{ width: '10%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>até</span>
                 </div>
                 <div className="column" style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <InputPercentage 
                         value={this.state.paramOkPercentMax}
                         onChangeValue={(id, value) => this.handleChangeParamOkPercentMax(id, value)}
                         
                         />
                 </div>

                 <div className="column" style={{ width: '15%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>Análise  </span>
                 </div>
                 <div className="column" style={{ width: '5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                  <img src={yellow_light}  width="15px" height="15px"/>
                 </div>
                 <div className="column" style={{ width: '5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>de  </span>
                 </div>
                 <div className="column" style={{ width: '35%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <InputPercentage 
                         value={this.state.paramPendingPercentMin}
                         onChangeValue={(id, value) => this.handleChangeParamPendingPercentMin(id, value)}
                         />                                
                 </div>
                 <div className="column" style={{ width: '10%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>até </span>
                 </div>
                 <div className="column" style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <InputPercentage 
                         value={this.state.paramPendingPercentMax}
                         onChangeValue={(id, value) => this.handleChangeParamPendingPercentMax(id, value)}
                         
                         />
                 </div>

                 <div className="column" style={{ width: '15%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>Bloqueado  </span>
                 </div>
                 <div className="column" style={{ width: '5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                  <img src={red_light}  width="15px" height="15px"/>
                 </div>
                 <div className="column" style={{ width: '5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>de  </span>
                 </div>
                 <div className="column" style={{ width: '35%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <InputPercentage 
                         value={this.state.paramLockedPercentMin}
                         onChangeValue={(id, value) => this.handleChangeParamLockedPercentMin(id, value)}
                         />                                
                 </div>
                 <div className="column" style={{ width: '10%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <span>até </span>
                 </div>
                 <div className="column" style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                     <InputPercentage 
                         value={this.state.paramLockedPercentMax}
                         onChangeValue={(id, value) => this.handleChangeParamLockedPercentMax(id, value)}
                         
                         />
                 </div>

             </div>
             <div className="column" style={{
                        width: '35%',
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>

                        <ActionButton
                            onClickAction={this.handleOnCloseProductLockScreen}
                            id="btnCancelEditLock"
                            text="Cancelar"
                            componentClass="btn btn-default" />

                        <ActionButton
                            onClickAction={this.handleOnSaveLockProductClick}
                            id="btnSaveEditLock"
                            text="Salvar"
                            componentClass="btn btn-action"
                            />

                    </div>
             
          </CustomDialog>


        <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
      </Fragment>
    );
  }
}

export default ProductLocked;
