import React, { Component } from 'react';
import { bindAll } from 'lodash'

class MenuGrid extends Component { 
  constructor(props) {
    super(props);

    bindAll(this, [
      'handleClick'
     ]);

    this.state = {
      display: 'none'
    };
  }

  handleClick = () => {
    console.log(this.state.display)
    if ( this.state.display == 'none' ) {
        this.setState({display: 'block'})
    } else {
      this.setState({display: 'none'})
    }
  }

  handleEditarClick = () => {
    console.log('Editar')
  }

  handleRedefinirClick = () => {
    console.log('Redefinir')
  }

  handleDesativarClick = () => {
    console.log('Desativar')
  }

  handleExcluirClick = () => {
    console.log('Excluir')
  }

  render () {
    return (
      <div className='menu-grid'>
        <button id={this.props.id} className='menu-botton' onClick={this.props.handleClick}>
          <i className="icon-menu-hamburger" />
        </button>
        <div className='menu-item' style={{display: this.state.display}}>
          <a href="#" onClick={this.handleEditarClick}>Editar</a>
          <a href="#" onClick={this.handleRedefinirClick}>Redefinir Senha</a>
          <a href="#" onClick={this.handleDesativarClick}>Desativar</a>
          <a href="#" onClick={this.handleExcluirClick}>Excluir</a>
        </div>
      </div>
    );
  }
}

export default MenuGrid;
