import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'

/** css */
import './Failure.css'

export default function Failure(props) {

  const {
    title, resume, message, to, actionText
  } = props

  return (
    <div className="failure-page">
      <div className="failure-page-content">
        <h1 className="title"><Trans>{title}</Trans></h1>
        <h3 className="failure"><Trans>{resume}</Trans></h3>
        <h3 className="failure"><Trans>{message}</Trans></h3>
      </div>
      <div className="failure-page-actions">
        <Link to={to}>
          <span className="go-back-home">
            <Trans>{actionText}</Trans>
          </span>
        </Link>
      </div>
      <div className="failure-page-logo" />
    </div>
  )
}


Failure.propTypes = {
  title: PropTypes.string.isRequired,
  resume: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired
}
