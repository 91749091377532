import { getListLockProduct, unlockProduct, lockProduct, uploadMassiveLockProduct ,getLockProductParams, setLockProductParams} from "../commons/ProductApi";
import { format } from 'date-fns'

const ProductController = {
    getListLockProduct: function(fromDate, toDate, sellerId, status, rms, matchSelected) {
        //let fDate = format(fromDate, 'dd/MM/yyyy HH:mm:ss');
        //let tDate = format(toDate, 'dd/MM/yyyy HH:mm:ss');
        let pSellerId = sellerId != null ? sellerId : 0;
        let pRms = rms != null ? rms : 0;
        let params = `fromDate=${fromDate}&toDate=${toDate}&sellerId=${pSellerId}&status=${status}&rms=${pRms}&match=${matchSelected}`;
        return getListLockProduct("/product/listLockProduct", params);
    },
    unlockProduct: function(listSku, user) {
        let data = {
            product_sku: listSku,
            user: user
        };
        return unlockProduct("/product/unlockProduct", data);
    },
    lockProduct: function(listSku, user) {
        let data = {
            product_sku: listSku,
            user: user
        };
        return lockProduct("/product/lockProduct", data);
    },
    uploadMassiveLockProduct: function(file, user) {
         
        return this.convertBase64(file, user);
    },
    convertBase64: (file, user) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
            let data = {
                base64: fileReader.result,
                user: user
            };
            return uploadMassiveLockProduct("/product/uploadMassiveLockProduct", data);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      },
    getLockProductParams: function(paramId) {
        let params = `paramId=${paramId}`;
        
        return getLockProductParams("/product/getLockProductParams", params);
    },

    setLockProductParams: function(params)    {
        return setLockProductParams("/product/setLockProductParams", params);
    }
    
};



export default ProductController;