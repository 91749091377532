import React, { useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { format, parse, parseISO } from 'date-fns'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


const PriceLockedGrid = props => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [rowData, setRowData] = useState(props.productsList );    

    const dateComparator = (date1, date2) => {
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);

        if (date1Number == null && date2Number == null) {
          return 0;
        }

        if (date1Number == null) {
          return -1;
        }

        if (date2Number == null) {
          return 1;
        }

        return date1Number - date2Number;
    }

    const monthToComparableNumber = (date) => {
        if (date || date.length !== 10) {
            return null;
        }

        var yearNumber = date.substring(6, 10);
        var monthNumber = date.substring(3, 5);
        var dayNumber = date.substring(0, 2);
        var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

        return result;
    }

    const currencyFormatter = (params) => {
        return formatNumber(params.value);
    }

    const formatNumber = (number) => {
        let numberReturn = number.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

        return numberReturn && numberReturn !== "NaN" ? numberReturn : '';
    }
 
    const formatDate = (data) => {
        let newDate = parseISO(data.value != undefined ? data.value : data);
        let dateReturn = data.value != undefined ? data.value : data;

        try {
            dateReturn = format(newDate, 'dd/MM/yyyy HH:mm');
        } catch (e) {
            console.log(`Erro ao converter a coluna CreateOn: ${e}`);
        }
        
        return dateReturn;
    }

    const getValueLocked = (params) => {
        switch(params.data.status) {
            case 'OK':
                return formatNumber(params.data.newPrice);
            case 'LOCKED':
                return params.data.lockedPrice != undefined ? formatNumber(params.data.lockedPrice) : formatNumber(params.data.newPrice);
            default:
                return 0;
        }
    }

    const getDateChange = (params) => {
        return params.data.changedOn != undefined ? formatDate(params.data.changedOn) : formatDate(params.data.createdOn);
    }

    return (
        <div className="ag-theme-material" style={{ height: props.height, width: props.width }}>
            <AgGridReact
                onGridReady={props.onGridReady}
                rowData={rowData}
                pagination={true}
                enableCellChangeFlash={true}
                suppressExcelExport={true}     
                rowMultiSelectWithClick={true} 
                rowSelection="multiple"          
                headerCheckboxSelection={true}
                enableRangeSelection={true}
                enableCellTextSelection={true}
            >
                <AgGridColumn hide={!props.showCheckBox} lockPosition={true} headerName=""           field="selecionar"             width={50} headerCheckboxSelection={true} checkboxSelection={true} />
                <AgGridColumn lockPosition={true} resizable={true} headerName="Seller"               field="sellerName"             width={300} />
                <AgGridColumn lockPosition={true} resizable={true} headerName="Produto"              field="productSku"             width={150} />
                <AgGridColumn lockPosition={true} resizable={true} headerName="Oferta"               field="offerId"                width={150} />
                <AgGridColumn lockPosition={true} resizable={true} headerName="Estoque"              field="quantity"               width={100} />
                <AgGridColumn lockPosition={true} resizable={true} headerName="Valor Anterior"       field="lastPrice"              width={140} valueFormatter={currencyFormatter}/>
                <AgGridColumn lockPosition={true} resizable={true} headerName="Valor Bloqueado"      field="newPrice"               cellRenderer={getValueLocked}   width={140} />
                <AgGridColumn lockPosition={true} resizable={true} headerName="Status"               field="status"                 width={150} />
                <AgGridColumn lockPosition={true} resizable={true} headerName="Editor"               field="createdBy"              width={200} />
                <AgGridColumn lockPosition={true} resizable={true} headerName="Última modificação"   field="createdOn"              cellRenderer={getDateChange}    width={170}  />
            </AgGridReact>
        </div>
    )
}

export default PriceLockedGrid;