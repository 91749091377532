import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CheckComponentChildrenAreAllowed, CheckComponentHasChildren } from '../../../commons/ComponentValidations'

import './Dropdown.css'

const getClassName = (defaultClass, additionalClass) => (additionalClass ? `${defaultClass} ${additionalClass}` : defaultClass)

const MenuItem = (props) => {
  const { className, text, route } = props
  return (
    <li className={getClassName('menu-item', className)} id={`menu_item_${text.toLowerCase()}`}><Link to={route}>{text}</Link></li>
  )
}

const MenuItemClick = (props) => {
  const { className, text, onClick } = props
  return (
    <li className={getClassName('menu-item', className)} id={`menu_item_${text.toLowerCase()}`}><Link to={text} onClick={onClick}>{text}</Link></li>
  )
}

MenuItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
}

MenuItemClick.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

const Dropdown = (props) => {
  const {
    className, title, children, id, hamburger
  } = props
  return (
    <div className={getClassName('dropdown', className)} id={`dropdown_${id}`}>
      <span className="dropdow-title">
        <i className={ hamburger ? "icon-menu-hamburger" : "icon-user"} />
        <span className="text">{title}</span>
      </span>

      <ul className="dropdown-content">
        <div className="menu-chamfer">
          <div className="menu-border" />
        </div>
        {children}
      </ul>
    </div>
  )
}
Dropdown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName]
    CheckComponentHasChildren(componentName, React.Children.count(prop))
    CheckComponentChildrenAreAllowed(componentName, prop, React.Children,
      [MenuItem.name, MenuItemClick.name])
  }
}

export { Dropdown, MenuItem, MenuItemClick }