import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'

import './DropZone.css'

const fileListToArray = (list) => {
  const fileList = []
  for (let i = 0; i < list.length; i += 1) {
    fileList.push(list.item(i))
  }
  return fileList
}

export default class DropZone extends Component {
  constructor(props) {
    super(props)
    this.state = { highlight: false }
    this.fileInputRef = React.createRef()

    bindAll(this, [
      'openFileDialog',
      'onFileAdded',
      'onDragOver',
      'onDragLeave',
      'onDrop',
      'addSingleFile'
    ])
  }

  openFileDialog() {
    this.fileInputRef.current.click()
  }

  addSingleFile(file) {
    const { onFileAdded, accept } = this.props

    const pattern = `.${accept}+$`

    if (file.name.match(new RegExp(pattern))) {
      onFileAdded(file)
    }
  }

  onFileAdded(event) {
    const { files } = event.target

    const fileList = fileListToArray(files)
    if (fileList.length > 0) {
      const file = fileList[0]
      this.addSingleFile(file)
    }
  }

  onDragOver(event) {
    event.preventDefault()

    this.setState({ highlight: true })
  }

  onDragLeave() {
    this.setState({ highlight: false })
  }

  onDrop(event) {
    event.preventDefault()
    const { files } = event.dataTransfer

    const fileList = fileListToArray(files)
    const file = fileList[0]
    this.addSingleFile(file)

    this.setState({ highlight: false })
  }

  render() {
    const { highlight } = this.state
    const { file, accept } = this.props

    return (
      <div
        className={`drop-zone ${highlight ? 'highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: 'pointer' }}
      >
        <input
          ref={this.fileInputRef}
          className="file-input"
          type="file"
          onChange={this.onFileAdded}
          accept={accept}
        />
        <div>
          {file ? (
            <div className="drop-zone-description">
              <span>
                <i className="icon-file-code-settings" />
                /
                {file.name}
              </span>
            </div>
          ) : (
            <div className="drop-zone-description">
              <span>
                <i className="icon-cloud-upload" />
                Upload the file dropping It here or &nbsp;
              </span>
              <span className="click-to-select">click here to select</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

DropZone.propTypes = {
  accept: PropTypes.string,
  onFileAdded: PropTypes.func.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string
  })
}