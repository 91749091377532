
import { getCategories, 
         getCategoriesFirestore, 
         synchronizeCategories, 
         updateCategory,
         createCategory } from "../commons/Connection";

const CategoryController = {
    getCategories: function() {
        return getCategories("/v1/category");
    },
    getCategoriesFirestore: function() {
        return getCategoriesFirestore("/v1/category/allCategoriesMiraklVtex");
    }, 
    synchronizeCategories: function() {
        return synchronizeCategories("/v1/synchronize");
    }, 
    updateCategory: function(category) {
        return updateCategory("/v1/category/updateCategory", category);
    }, 
    createCategory: function(category) {
        return createCategory("/v1/category/createCategory", category);
    }
};

export default CategoryController;