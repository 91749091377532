import React, { Component, Fragment } from 'react'

import { Redirect } from 'react-router-dom'

import Closable from '../../components/closable/Closable'

import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'

import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import LogPriceStockGrid from './LogPriceStockGrid'

import OfferController from '../../controllers/OfferController'
import InputText from '../../components/form-components/InputText'
import EnrichmentPriceStockList from '../../services/EnrichmentPriceStockList'

import './LogPriceStock.css'

class LogPriceStock extends Component {
    constructor(props) {
      super(props)
  
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        gridApi: undefined,
        loader: false,
        sellerList: [],
        seller: undefined,
        rms: '',
        priceStockList: [],
        disableExport: true
      }

      bindAll(this, [
                     'closePartnerMessage',
                     'onGridReady',
                     'refreshGrid',
                     'onBtnExportGrid',
                     'handleOnClickFilter',
                     'handleChangeRms']);
    }

    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    onGridReady = params => {
        this.setState({ gridApi: params.api })
    }
  
    refreshGrid = () => {
        let params = {
            force: true,
            suppressFlash: true,
        }
  
        this.state.gridApi.refreshCells(params);
    }

    onBtnExportGrid = () => {
        this.state.gridApi.exportDataAsCsv();
    };

    handleOnClickFilter = (event) => {
        this.setState({ loader: true });

        let rms = this.state.rms ? this.state.rms : undefined;

        if (!this.formValidate()) {
            this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Você deve preencher o campo RMS!`,
                colorMessage: 'header-message header-error-message'
              });
        } else {
            OfferController.getLogPriceStock(rms)
            .then((data) => {
                this.state.gridApi.setRowData(data); 
                this.refreshGrid();
                this.setState({ loader: false }, 
                    () => { 
                            if (data.length > 0) { 
                              this.setState({ disableExport: false }) 
                            } else { 
                              this.setState({ disableExport: true }) 
                            } 
                  });        
            })
            .catch((error) => {
                this.setState({
                    loader: false,
                    showPartnerMessage: true, 
                    message: `Erro ao consultar o Log Preço e Estoque!`,
                    colorMessage: 'header-message header-error-message'
                  }, () => {  console.log(`Erro ao consultar o Log Price and Stock.`); });
            })
        }
    }
    
    formValidate = () => { 
        if (!this.state.rms) { return false; }

        return true;
    }

    handleChangeRms = (event) => {
        const { value } = event.target

        this.setState({
          rms: value
        })
    }
    
    render() {

      const hasRoleSync = CredentialManager.userHasRole('ROLE_VIEW_OFFER_PRICE')

      if (!hasRoleSync) {
        return <Redirect to="/error" />
      }

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '100%'}}> 
              <h3>Log de Preço e Estoque</h3>
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              RMS:
            </div>
            <div className="column" style={{width: '25%'}}>
              <InputText
                    id="inputSku"
                    value={this.state.rms}
                    placeholder={'Digite aqui o RMS'}
                    maxLength={100}
                    onChange={this.handleChangeRms} 
                />
            </div>
            <div className="column" style={{width: '40%'}}></div>
            <div className="column" style={{width: '35%'}}></div>
            <div className="column" style={{width: '10%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickFilter} 
                id="btnFiltrar" 
                text="Filtrar"
                icon="icon-search"
                componentClass="btn btn-action" />
            </div>
            <div className="column" style={{width: '90%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.onBtnExportGrid} 
                id="btnChart" 
                text="Exportar CSV"
                icon="icon-download"
                disable={this.state.disableExport}
                componentClass="btn btn-action" />
            </div>
          </div>
          <div className="container">
            <LogPriceStockGrid 
              height={450} 
              width="100%" 
              priceStockList={this.state.priceStockList} 
              onGridReady={this.onGridReady} />
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default LogPriceStock