import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import Closable from '../../components/closable/Closable'
import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'
import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import InputText from '../../components/form-components/InputText'
import ManagementController from '../../controllers/ManagementController'

import './IncludeUser.css'

class ChangePassword extends Component {
    constructor(props) {
      super(props)
      
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        loader: false,
        inputPassword: '',
        inputPasswordRepeated: ''
      }
      
      bindAll(this, [
                     'closePartnerMessage',
                     'handleOnClickFilter',
                     'handleRolesSelected',
                     'handleMktpListRoles'
                    ]);
                    
    }
    
    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    handleRolesSelected = (roles) => {
      this.setState({ selectedRoles: roles })
    }

    handleMktpListRoles = (roles) => {
      this.setState({ mktpListRoles: roles })
    }

    handleOnClickFilter = (event) => {
      this.setState({ loader: true });

      let username = CredentialManager.getAuthenticatedUser().name;
      let inputPassword = this.state.inputPassword ? this.state.inputPassword : undefined;
      
      if (this.formValidate()) {
        ManagementController.updateUserPassword(username, inputPassword)
          .then((result) => {
            if (result.status === 'Error') {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `${result.message}`,
                colorMessage: 'header-message header-error-message'
              });
            } else {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Senha alterada com sucesso.`,
                colorMessage: 'header-message header-success-message'
              });
            }
          })
          .catch((error) => {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Erro eo tentar alterar a senha.`,
                colorMessage: 'header-message header-error-message'
              });
          })
      } 
  }

  handleCancelClick = () => {
    window.location.replace('/')
  }

  handlePassword = (event) => {
    const { value } = event.target
    this.setState({
      inputPassword: value
    })
  }

  handlePasswordRepeated = (event) => {
    const { value } = event.target
    this.setState({
      inputPasswordRepeated: value
    })
  }

  formValidate = () => { 



        if (this.state.inputPassword == '' || this.state.inputPassword == undefined ) { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Você deve preencher o campo Nova Senha.`,
            colorMessage: 'header-message header-error-message'
          });
          return false; 
        }
        var decimal =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/;
        if (!this.state.inputPassword.match(decimal)) { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `A senha deve conter 6 dígitos, uma letra minúscula, uma letra maiúscula, um número e um caractere especial.`,
            colorMessage: 'header-message header-error-message'
          });
          return false; 
        }

        if (this.state.inputPasswordRepeated == '' || this.state.inputPasswordRepeated == undefined ) { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Você deve preencher o campo Repetir Nova Senha.`,
            colorMessage: 'header-message header-error-message'
          });
          return false; 
        }

        if (this.state.inputPassword  !=  this.state.inputPasswordRepeated ) { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Os valores dos campos precisam ser iguais.`,
            colorMessage: 'header-message header-error-message'
          });
          return false; 
        }

        return true;
    }
    
    render() {

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '100%'}}> 
              <h3>Alterar Senha</h3>
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Nova senha:
            </div>                                                               
            <div className="column" style={{width: '10px'}}>
              <InputText
                    id="inputPassword"
                    value={this.state.inputPassword}
                    placeholder={'Digite aqui sua nova senha.'}
                    maxLength={15}
                    type="password"
                    onChange={this.handlePassword}
                />
            </div>

            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Repita a senha:
            </div>                                                               
            <div className="column" style={{width: '10px'}}>
              <InputText
                    id="inputPasswordRepeated"
                    value={this.state.inputPasswordRepeated}
                    placeholder={'Digite novamente sua senha.'}
                    maxLength={15}
                    type="password"  
                    onChange={this.handlePasswordRepeated}                  
                />
            </div>
            
            <div className="column" style={{width: '12%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickFilter} 
                id="btnFiltrar" 
                text="Alterar Senha"
                componentClass="btn btn-action" />
            </div>
            <div className="column" style={{ width: '88%', marginTop: '30px'}}>
              <ActionButton
                onClickAction={this.handleCancelClick}
                id="btnCancelar"
                text="Cancelar"
                componentClass="btn btn-default" />    
            </div>
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default ChangePassword