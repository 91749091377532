import { getLogPriceStock, executeReprocessOffers, getLockedPriceParams, setLockedPriceParams } from "../commons/Connection";

const OfferController = {
    getLogPriceStock: function(rms) {
        return getLogPriceStock("/v1/offers/list-price-inventory", rms);
    },
    executeReprocessOffers: function(rms, seller) {
        let data = {};

        if (rms) {
            data.rmsProducts = rms;
        }

        if (seller) {
            data.seller = seller.ecommerceId;
        }

        console.log(data)

        return executeReprocessOffers("/v1/reprocessoffers", data);
    },
    getLockedPriceParams: function() {
        return getLockedPriceParams("/v1/priceLocked/info");
    },
    setLockedPriceParams: function(params)    {
        return setLockedPriceParams("/v1/priceLocked/update", params);
    }
};

export default OfferController;