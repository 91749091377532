import React, { Component, Fragment } from 'react'

import { Redirect } from 'react-router-dom'

import Closable from '../../components/closable/Closable'

import InputDatePicker from '../../components/form-components/input-date-picker/inputDatePicker'
import InputTimePicker from '../../components/form-components/input-time-picker/InputTimePicker'

import ReportOffersGrid from './ReportOffersGrid'
import ReportOffersSellerGrid from './ReportOffersSellerGrid'
import { bindAll } from 'lodash'

import { format, getDate, getMonth, getYear, getHours, getMinutes, compareDesc, sub, parse, differenceInDays } from 'date-fns'

import { CredentialManager } from '../../commons/CredentialManager'

import './report_offers.css'
import ActionButton from '../../components/buttons/ActionButton'

import ReportController from '../../controllers/ReportController'
import SellerController from '../../controllers/SellerController'

import ChartDialog from '../chartDialog/ChartDialog'

import TransposeData from '../../services/TransposeData'

import loaderIcon from '../../resources/images/loaderComplete.gif'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'

class ReportOffers extends Component {
    constructor(props) {
      super(props)
  
      this.state = { 
        selectedOption: 'hora',
        fromDate: sub(new Date(),{ days: 30}),
        fromTime: new Date(),
        toDate: new Date(),
        toTime: new Date(),
        fromDateTime: this.formatDateToISO(sub(new Date(),{ days: 30}), new Date()),
        toDateTime: this.formatDateToISO(new Date(), new Date()),
        diffBetweenDays: 30,
        offerList: [],
        offerSellerList: [],
        optionsChart: [],
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        gridApi: undefined,
        titleChartDialog: '',
        openChartDialog: false,
        buttonChartDisable: true,
        buttonExportDisable: true,
        loader: false,
        sellerList: [],
        seller: undefined,
        disabledAutocomplete: false,
        tipoRelatorio: 'geral',
        hiddenChartButton: false,
        changeLayoutButton: '15%',
        clearAutocomplete: null
      }

      bindAll(this, ['onValueChange',
                     'handleChangeFromDate',
                     'handleChangeFromTime',
                     'handleChangeToDate',
                     'handleChangeToTime',
                     'handleOnClickFilter',
                     'handleOnClickChartDialog',
                     'closePartnerMessage',
                     'onGridReady',
                     'onClose',
                     'searchSellerByTrandingName',
                     'setValueSellerSelected',
                     'onValueChangeTipoRelatorio']);
    }

    componentDidMount(){
      
    }

    onValueChange(event) {    
      this.setState({ 
        selectedOption: event.target.value, 
        buttonChartDisable: true, 
        disabledAutocomplete: event.target.value === 'hora' ? false : true 
      });

      this.clearAutocomplete();

      console.log(`Choise: ${event.target.value === 'hora' ? false : true }`);
    }

    clearAutocomplete = () => {
      let eventClear = document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
      if (eventClear != undefined) {
        eventClear.click();
      }
    } 

    onValueChangeTipoRelatorio(event) {    
      this.setState({ 
        selectedOption: 'hora',
        tipoRelatorio: event.target.value,
        hiddenChartButton: !this.state.hiddenChartButton,
        changeLayoutButton: this.state.changeLayoutButton === '15%' ? '90%' : '15%',
        buttonChartDisable: !this.state.buttonChartDisable,
        buttonExportDisable: true,
        offerList: [],
        offerSellerList: [],
        sellerList: [],
        disabledAutocomplete: false,
        seller: undefined
      });

      this.clearAutocomplete();
    }

    handleChangeFromDate = (value, e) => {
      this.setState({ 
        fromDate: value,
        fromDateTime: this.formatDateToISO(value, this.state.fromTime),
        buttonChartDisable: true
      }); 
    }

    handleChangeFromTime = (value, e) => {
      this.setState({ 
        fromTime: value, 
        fromDateTime: this.formatDateToISO(this.state.fromDate, value),
        buttonChartDisable: true
      });
    }

    handleChangeToDate = (value, e) => {
      this.setState({ 
        toDate: value, 
        toDateTime: this.formatDateToISO(value, this.state.toTime),
        buttonChartDisable: true,
        diffBetweenDays: differenceInDays(parse(this.state.toDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()), parse(this.state.fromDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()))
      });
    }

    handleChangeToTime = (value, e) => {
      this.setState({ 
        toTime: value, 
        toDateTime: this.formatDateToISO(this.state.toDate, value),
        buttonChartDisable: true      
      });
    }

    formatDateToISO = (date, time) => {
      let dateTime;

      if (date && time) {
        let day = getDate(date)
        let month = getMonth(date)
        let year = getYear(date)
        let hours = getHours(time)
        let minutes = getMinutes(time)
        dateTime = new Date(year, month, day, hours, minutes, 0)
      } else {
        return this.formatDateToISO(new Date(), new Date());
      }

      return format(dateTime, 'dd/MM/yyyy HH:mm:ss')
    }

    closePartnerMessage = () => {
      this.setState({ showPartnerMessage: false })
    }

    handleOnClickFilter = (event) => {
      let diffInDays = differenceInDays(parse(this.state.toDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()), parse(this.state.fromDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()));
      let isSellerFilter = this.state.tipoRelatorio === 'seller';

      if (!this.validForm() && !isSellerFilter) {
        this.setState({
          showPartnerMessage: true, 
          message: 'Todos os campos são obrigatórios!',
          colorMessage: 'header-message header-error-message'
        });
      } else if (!this.validDates() && !isSellerFilter) {
        this.setState({
          showPartnerMessage: true, 
          message: 'Data inicial não pode ser maior que data final!',
          colorMessage: 'header-message header-error-message'
        });
      } else if (diffInDays > 90 && !isSellerFilter) { 
        this.setState({
          showPartnerMessage: true, 
          message: 'Não é permitido consultar período maior que 90 dias!',
          colorMessage: 'header-message header-error-message'
        });
      } else {
        this.setState({ loader: true });
        let ecommerceId = this.state.seller != null ? this.state.seller.ecommerceId : undefined;

        if (this.state.tipoRelatorio === 'geral') {
          this.fillGridGeralFilter(ecommerceId);
        } else {
          this.fillGridSellerFilter(ecommerceId);
        }
      }
    }

    fillGridGeralFilter = (ecommerceId) => {
      ReportController.getReport(this.state.fromDateTime, 
        this.state.toDateTime, 
        this.state.selectedOption,
        ecommerceId).then(data => {

          this.setState({ 
            offerList: data, 
            buttonChartDisable: (data.length <= 1), 
            buttonExportDisable: !(data.length > 0),
            loader: false }, 
            () => { 
              this.state.gridApi.setRowData(data); 
              this.refreshGrid();
            });
          }).catch((error) => {
            this.setState({
              loader: false,
              showPartnerMessage: true, 
              message: `Erro ao tentar chamar a API do Report: ${error}`,
              colorMessage: 'header-message header-error-message'
            });
          })
    }

    fillGridSellerFilter = (ecommerceId) => {
      ReportController.getReportBySeller(ecommerceId)
        .then(data => {

          this.setState({ 
            offerList: data, 
            buttonExportDisable: !(data.length > 0), 
            loader: false }, 
            () => { 
              this.state.gridApi.setRowData(data); 
              this.refreshGrid();
            });
          }).catch((error) => {
            this.setState({
              loader: false,
              showPartnerMessage: true, 
              message: `Erro ao tentar chamar a API do Report: ${error}`,
              colorMessage: 'header-message header-error-message'
            });
          });
    }

    onBtnExportGrid = () => {
      this.state.gridApi.exportDataAsCsv();
    };

    validForm = () => {
      if (!this.state.fromDate) { return false; }
      if (!this.state.fromTime) { return false; }
      if (!this.state.toDate) { return false; }
      if (!this.state.toTime) { return false; }

      return true;
    }

    validDates = () => {
      if (compareDesc(this.state.fromDate, this.state.toDate) == -1) { return false; }

      return true;
    }

    handleOnClickChartDialog = (event) => {
      TransposeData.getDataOfChartDiff(this.state.gridApi, this.state.selectedOption)
        .then((data) => {
          this.setState({ 
            openChartDialog: true, 
            dataChart: data[0], 
            optionsChart: { 
              colors: ['#4285F4', '#DB4437', '#F4B400', '#0F9D58', '#AB47BC', '#59F9F7'],
            },
            formattersChart: data[1],
            titleChartDialog: 'Gráfico de Diferença Percentual' 
          });

          console.log(`Formatters received: ${JSON.stringify(data[1])}`);
        })
        .catch((error) => {
          console.log(`Erro ao tentar transpor dados da grid para o gráfico de diferença: ${error}`);
          this.setState({
            showPartnerMessage: true, 
            message: `Erro ao tentar transpor dados da grid para o gráfico de diferença: ${error}`,
            colorMessage: 'header-message header-error-message'
          });
        });
    }

    handleOnClickChartIncreaseDialog = (event) => {
      TransposeData.getDataOfChartIncrease(this.state.gridApi, this.state.selectedOption)
        .then((data) => {
          this.setState({ 
            openChartDialog: true, 
            dataChart: data[0], 
            optionsChart: { 
              colors: ['#4285F4', '#DB4437', '#F4B400', '#0F9D58'],
            },
            formattersChart: data[1],
            titleChartDialog: 'Gráfico de Crescimento' 
          });

          console.log(`Formatters received: ${JSON.stringify(data[1])}`);
        })
        .catch((error) => {
          console.log(`Erro ao tentar transpor dados da grid para o gráfico de crescimento: ${error}`);
        });
    }

    onGridReady = params => {
      this.setState({ gridApi: params.api })
    }

    refreshGrid = () => {
      var params = {
        force: true,
        suppressFlash: true,
      };

      this.state.gridApi.refreshCells(params);
    }

    onClose = () => {
      this.setState({
        openChartDialog: false
      })
    }

    searchSellerByTrandingName = (trandingName) => {
      SellerController.getSellerList(trandingName)
        .then((result) => {
          this.setState({
            sellerList: result
          })
        })
        .catch((error) => {
          this.setState({
            showPartnerMessage: true, 
            message: `Erro ao buscar o Seller: ${error}`,
            colorMessage: 'header-message header-error-message'
          });
        });
    }

    setValueSellerSelected = (value) => {
      this.setState({
        seller: value
      }, () => {console.log(this.state.seller)});
    }

    render() {

      const hasRoleSync = CredentialManager.userHasRole('ROLE_VIEW_OFFER_REPORT')

      if (!hasRoleSync) {
        return <Redirect to="/error" />
      }

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '100%'}}> 
              <h2>Relatório de Ofertas</h2>
            </div>

            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              <h4>Tipo de Relatório:</h4>
            </div>
            <div className="column" style={{width: '100%', marginBottom: '30px'}}>
              <ul>
                <li className="item">
                    <span>
                      <input type="radio" name="filtroPorSeller" value="geral" checked={this.state.tipoRelatorio === "geral"} onChange={this.onValueChangeTipoRelatorio}/> Relatório de Ofertas por Período
                    </span>
                </li>
                <li className="item">
                    <span>
                      <input type="radio" name="filtroPorSeller" value="seller" checked={this.state.tipoRelatorio === "seller"} onChange={this.onValueChangeTipoRelatorio}/> Relatório de Ofertas por Seller
                    </span>
                </li>
              </ul>
            </div>

          {this.state.tipoRelatorio === 'geral' && 
          <>
            <div className="column" style={{width: '100%'}}>
              Filtro por Data:
            </div>
            <div className="column" style={{width: '20%'}}>
              De: <InputDatePicker startDate={this.state.fromDate} name="fromDate" handleChange={(value, e) => this.handleChangeFromDate(value, e)} />
            </div>
            <div className="column" style={{width: '20%'}}>
            <InputTimePicker name="fromTime" handleChange={(value, e) => this.handleChangeFromTime(value, e)} />
            </div>
            <div className="column" style={{width: '20%'}}>
            Até: <InputDatePicker startDate={this.state.toDate} name="toDate" handleChange={(value, e) => this.handleChangeToDate(value, e)} />
            </div>
            <div className="column" style={{width: '40%'}}>
              <InputTimePicker name="toTime" handleChange={(value, e) => this.handleChangeToTime(value, e)} />
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Agrupamento:
            </div>
            <div className="column" style={{width: '100%'}}>
              <ul>
                <li className="item">
                    <span>
                      <input type="radio" name="filtro" value="hora" checked={this.state.selectedOption === "hora"} onChange={this.onValueChange}/> Hora
                    </span>
                </li>
                <li className="item">
                    <span>
                      <input type="radio" name="filtro" value="dia" checked={this.state.selectedOption === "dia"} onChange={this.onValueChange}/> Dia
                    </span>
                </li>
                <li className="item">
                  <span>
                    <input type="radio" name="filtro" value="semana" checked={this.state.selectedOption === "semana"} onChange={this.onValueChange}/> Semana
                  </span>
                </li>
                <li className="item">
                  <span>
                    <input type="radio" name="filtro" value="mes" checked={this.state.selectedOption === "mes"} onChange={this.onValueChange}/> Mês
                  </span>
                </li>
                <li className="item">
                  <span>
                    <input type="radio" name="filtro" value="ano" checked={this.state.selectedOption === "ano"} onChange={this.onValueChange}/> Ano
                  </span>
                </li>
              </ul>
            </div>
          </>
          } 
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Seller:
            </div>
            <div className="column" style={{width: '100%'}}>
              <InputAutocomplete 
                disabled={this.state.disabledAutocomplete}
                setValue={this.setValueSellerSelected} 
                searchSellerByTrandingName={this.searchSellerByTrandingName} 
                sellerList={this.state.sellerList} 
              />
            </div>
            <div className="column" style={{width: '10%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickFilter} 
                id="btnFiltrar" 
                text="Filtrar"
                icon="icon-search"
                componentClass="btn btn-action" />
            </div>
            <div className="column" style={{width: this.state.changeLayoutButton, marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.onBtnExportGrid} 
                id="btnChart" 
                text="Exportar CSV"
                icon="icon-download"
                disable={this.state.buttonExportDisable}
                componentClass="btn btn-action" />
            </div>

            { !this.state.hiddenChartButton && 
            <> 
              <div className="column" style={{width: '20%', marginTop: '30px'}}>
                <ActionButton 
                  onClickAction={this.handleOnClickChartDialog} 
                  id="btnChart" 
                  text="Gráfico de Diferença %"
                  icon="icon-processing"
                  disable={this.state.buttonChartDisable}
                  componentClass="btn btn-action" />
              </div>
              <div className="column" style={{width: '55%', marginTop: '30px'}}>
                <ActionButton 
                  onClickAction={this.handleOnClickChartIncreaseDialog} 
                  id="btnChart" 
                  text="Gráfico de Crescimento"
                  icon="icon-processing"
                  disable={this.state.buttonChartDisable}
                  componentClass="btn btn-action" />
              </div>
            </> 
            }
          </div>
          <div className="container">

            { this.state.tipoRelatorio == 'geral' && 
              <ReportOffersGrid 
                height={450} 
                width="100%" 
                OfferList={this.state.offerList} 
                onGridReady={this.onGridReady} />
            }

            { this.state.tipoRelatorio == 'seller' && 
              <ReportOffersSellerGrid 
                height={450} 
                width="100%" 
                OfferList={this.state.offerSellerList} 
                onGridReady={this.onGridReady} />
            }

          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          <ChartDialog
            onClose={this.onClose}
            title={this.state.titleChartDialog}
            open={this.state.openChartDialog}
            data={this.state.dataChart}
            options={this.state.optionsChart}
            formatters={this.state.formattersChart}
          />
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default ReportOffers