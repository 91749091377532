import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';

import "react-datepicker/dist/react-datepicker.css";

const InputDatePicker = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  
  registerLocale('pt', pt);

  return (
    <DatePicker 
        selected={props.startDate} 
        dateFormat="dd/MM/yyyy"
        locale="pt" 
        maxTime={props.maxTime}
        onChange={(date, e) => { props.handleChange(date, e); setStartDate(date)}}
        disabled={props.disabled}
    />
  );
};

export default InputDatePicker;