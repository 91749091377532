import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';

export default function InputAutocomplete(props) {
    const [value, setValue] = React.useState(props.sellerList[0]);
    const [inputValue, setInputValue] = React.useState("");
  
    return (
      <div>
        <Autocomplete
          value={value}
          disabled={props.disabled}
          onChange={(event, newValue) => {
            //setValue(newValue);
            props.setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            if (newInputValue.length > 2) {
                props.searchSellerByTrandingName(newInputValue);
            }
          }}
          id="controllable-states-demo"
          options={props.sellerList}
          multiple={ props.multiple }
          getOptionLabel={(option) => option.tradingName}
          style={{ width: props.width != undefined ? props.width : 300 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" />
          )}
          noOptionsText="Sem opções..."
          renderOption={(option) => {
            return (
              <Grid container alignItems="center">
                <Grid item xs>
                    <span style={{ fontWeight: 700 }}>
                        {`${option.sellerId} - ${option.tradingName}`}
                    </span>
    
                    <Typography variant="body2" color="textSecondary">
                        {`CNPJ: ${option.cnpj}`}
                    </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    );
}