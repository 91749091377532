import React, { PureComponent } from 'react';

class MenuItemGrid extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      
    };
  }

  render () {
    return (
      <div>
          <a href="#" onClick={this.props.handleItemClick}>{ this.props.text }</a>
      </div>
    );
  }
}

export default MenuItemGrid;
