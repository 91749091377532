import { getLockedsAndUnlockedsOffers, getSellerList, getSellerByEcommerceId, processUnlockOffers} from "../commons/Connection";

const PriceLockedController = {
    getSellerList: function(trandingName) {
        return getSellerList("/v1/sellers/search-by-name", trandingName);
    },    
    getLockedsOffers: function(from, to, statusList, ecommerceId, rms) {
        return getLockedsAndUnlockedsOffers("/v1/offers/lockeds", from, to, statusList, ecommerceId, rms);
    },
    getUnlockedsOffers: function(from, to, statusList, ecommerceId, rms) {
        return getLockedsAndUnlockedsOffers("/v1/offers/unlockeds", from, to, statusList, ecommerceId, rms);
    },    
    getSellerByEcommerceId: function(ecommerceId) {
        return getSellerByEcommerceId("/v1/sellers/ecommerce-id", ecommerceId);
    },
    processUnlockOffers: function(offers, user){
        return processUnlockOffers("/v1/offers/unlock", offers, user);
    }
};

export default PriceLockedController;