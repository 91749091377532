import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import Closable from '../../components/closable/Closable'
import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'
import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import InputText from '../../components/form-components/InputText'
import ManagementController from '../../controllers/ManagementController'
import TransferListIncludeUser from '../../components/form-components/transfer-list/TransferListIncludeUser'


import './IncludeUser.css'

class IncludeUser extends Component {
    constructor(props) {
      super(props)
      
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        loader: false,
        selectedRoles:  [] ,
        mktpListRoles:  [] ,
        inputUsername: ''
      }
      
      bindAll(this, [
                     'closePartnerMessage',
                     'handleOnClickFilter',
                     'handleRolesSelected',
                     'handleMktpListRoles'
                    ]);
                    
    }
    
    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    handleRolesSelected = (roles) => {
      this.setState({ selectedRoles: roles })
    }

    handleMktpListRoles = (roles) => {
      this.setState({ mktpListRoles: roles })
    }

    handleOnClickFilter = (event) => {
      this.setState({ loader: true });

      let inputUsername = this.state.inputUsername ? this.state.inputUsername : undefined;
      let listRoles = this.state.selectedRoles.length > 0 ? this.state.selectedRoles : undefined;
      
      if (this.formValidate()) {
        ManagementController.createUser(inputUsername, listRoles)
          .then((result) => {
            if (result.status === 'Error') {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `${result.message}`,
                colorMessage: 'header-message header-error-message'
              });
            } else {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Usuário criado com sucesso.`,
                colorMessage: 'header-message header-success-message'
              });
            }
          })
          .catch((error) => {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Erro eo tentar criar o usuário.`,
                colorMessage: 'header-message header-error-message'
              });
          })
      } 
  }

  handleCancelClick = () => {
    window.location.replace('manageruser')
  }

  handleChangeUsername = (event) => {
    const { value } = event.target

    this.setState({
      inputUsername: value
    })
  }
    
    formValidate = () => { 
        if (this.state.inputUsername == '' || this.state.inputUsername == undefined ) { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Você deve preencher o campo Username.`,
            colorMessage: 'header-message header-error-message'
          });
          return false; 
        }

        if (this.state.selectedRoles.length <= 0) { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Você deve adicionar ao menos 1 permissão para o usuário.`,
            colorMessage: 'header-message header-error-message'
          });

          return false; 
        }
        return true;
    }
    
    render() {

      const hasRoleSync = CredentialManager.userHasRole('ROLE_ADMIN')

      if (!hasRoleSync) {
        return <Redirect to="/error" />
      }

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '100%'}}> 
              <h3>Incluir Usuário</h3>
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Login:
            </div>                                                               
            <div className="column" style={{width: '50%'}}>
              <InputText
                    id="inputUsername"
                    value={this.state.inputUsername}
                    placeholder={'Digite aqui o Username'}
                    maxLength={100}
                    onChange={this.handleChangeUsername}
                />
            </div>
            <div className="column" style={{width: '50%'}}>

            </div>
            <div className="column" style={{width: '100%'}}>
              Permissões:
            </div>
            <div className="column" style={{width: '100%'}}>
                
                <TransferListIncludeUser handleRolesSelected={this.handleRolesSelected} 
                              mktpListRoles={this.state.mktpListRoles}
                              handleMktpListRoles={this.handleMktpListRoles}
                              />

            </div>
            <div className="column" style={{width: '12%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickFilter} 
                id="btnFiltrar" 
                text="Criar Usuário"
                componentClass="btn btn-action" />
            </div>
            <div className="column" style={{ width: '88%', marginTop: '30px'}}>
              <ActionButton
                      onClickAction={this.handleCancelClick}
                      id="btnCancelar"
                      text="Cancelar"
                      componentClass="btn btn-default" />                        
            </div>  
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default IncludeUser