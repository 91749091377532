import React, { Component } from 'react'
import CategoriesTree from './CategoriesTree'
import SelectedItem from './SelectedItem'
import './CustomizeCategories.css'

class CategoryAux extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categoryItem: {}
    }
    
  }

  onClickTreeItemCallback = (categoryItem) => {
    this.setState({
      categoryItem: categoryItem
    })
    this.forceUpdate()
  }

  findItemOnTreeList = (categories, children, categoryUpdated) => {
    if (children != null) {
        categories = children;
    }

    if (categories != null) {
      categories.forEach((category, index) => {
        if(category.vtexCategory == categoryUpdated.vtexCategory) {
          categories[index] = Object.assign(categories[index], categoryUpdated);
        }

        if (category.hasChildren) {
          this.findItemOnTreeList(null, category.children, categoryUpdated);
        }
      });
    }
  }

  refreshTree = async (category) => {
    await this.findItemOnTreeList(this.props.treeList, undefined, category);
    this.forceUpdate();
  } 

  render() { 
   
    return (
      <div id="customize-categories">
        <div id="categories-tree">
          <CategoriesTree treeList={this.props.treeList} onClickTreeItemCallback={this.onClickTreeItemCallback} />
          
        </div>
        <div id="selected-items">

          <div>
            <SelectedItem category={this.state.categoryItem} refreshTree={this.refreshTree} />
          </div>
        </div>
      </div>
    )
  }
}

export default CategoryAux