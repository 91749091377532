import { getHolidayList, postAddHoliday } from "../commons/Connection";

const ManagerHolidayController = {
    getHolidayList: function(year, active) {
        return getHolidayList("/v1/holiday", year, active);
    },
    postAddHoliday: function(date, description, changeBy, active) {        
        return postAddHoliday("/v1/holiday", date, description, changeBy, active);
    }
};

export default ManagerHolidayController;