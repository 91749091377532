import { getSellerList, getSellerByEcommerceId } from "../commons/Connection";

const SellerController = {
    getSellerList: function(trandingName) {
        return getSellerList("/v1/sellers/search-by-name", trandingName);
    },
    getSellerByEcommerceId: function(ecommerceId) {
        return getSellerByEcommerceId("/v1/sellers/ecommerce-id", ecommerceId);
    }
};

export default SellerController;