import { getSellerList, getTopSellersList, getTopSellersSheet, getItemsByPeriod, getItemsByPeriodSheet } from "../commons/Connection";

const TopSellersController = {
    getSellerList: function (trandingName) {
        return getSellerList("/v1/sellers/search-by-name", trandingName);
    },

    getTopSellersList: function (groupBy, init, end, total, cluster, sellerId){
        return getTopSellersList("/v1/top-sellers", groupBy, init, end, total, cluster, sellerId)
    },

    getTopSellersSheet: function (groupBy, init, end, total, cluster, sellerId){
        return getTopSellersSheet("/v1/top-sellers/file", groupBy, init, end, total, cluster, sellerId)
    },

    getItemsByPeriod: function (groupBy, init, end, sellerId){
        return getItemsByPeriod("/v1/top-sellers/by-seller", groupBy, init, end, sellerId)
    },

    getItemsByPeriodSheet: function (groupBy, init, end, sellerId){
        return getItemsByPeriodSheet("/v1/top-sellers/file/by-seller", groupBy, init, end, sellerId)
    }

 };

export default TopSellersController;