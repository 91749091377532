import { getNotificationBySellerId, getNotificationById, createNotification, deleteNotification, deleteNotificationGroup, editNotification, uploadMassiveNotification} from "../commons/NotificationApi";
import { format } from 'date-fns'

const NotificationController = {
    getNotificationBySellerId: function(title, sellerListSelected, fromDate, toDate) {
        let sellerListSelectedParams = '';
        let titleParam = '';
        sellerListSelected.map((item) => {
            sellerListSelectedParams = sellerListSelectedParams + `&id=${item}`
        })
        if (title) {
            titleParam =  `&title=${title}`;
        }

        let params = `fromDate=${fromDate}&toDate=${toDate}${sellerListSelectedParams}${titleParam}`;

        return getNotificationBySellerId("/v1/notification/by/params", params);
    },
    getNotificationById: function(id) {

        return getNotificationById("/v1/notification", id);
    },
    createNotification: function(title, message, callToAction, sellerListId ) {
        let data = {};

        if (title) {
            data.title = title;
        }
        if (message) {
            data.message = message;
        }
        if (callToAction) {
            data.cta = callToAction;
        }
        if (sellerListId) {
            data.user_id = sellerListId;
        }
        return createNotification("/v1/notification", data);
    },
    editNotification: function(notificationId, title, message, callToAction, sellerId ) {
        let data = {};

        if (notificationId) {
            data.id = notificationId;
        }
        if (title) {
            data.title = title;
        }
        if (message) {
            data.message = message;
        }
        if (callToAction) {
            data.cta = callToAction;
        }
        if (sellerId) {
            data.user_id = sellerId;
        }
        return editNotification("/v1/notification/edit", data);
    },
    deleteNotification: function( id) {
        let params = id;
        return deleteNotification("/v1/notification", params);
    },
    deleteNotificationGroup: function( group_id) {
        let deleteGroupUrl =  `/v1/notification/${group_id}/group`
        return deleteNotificationGroup(deleteGroupUrl); 
    },
    uploadMassiveNotification: function(file, title, message,callToAction ) {
         
        return this.convertBase64(file, title, message, callToAction);
    },
    convertBase64: (file, title, message, callToAction) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
            let data = {
                base64: fileReader.result,
                title: title,
                message: message,
                callToAction: callToAction
            };
            return uploadMassiveNotification("/v1/notification/uploadMassiveNotification", data);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      },

};
export default NotificationController;