import { toDate, isValid, parseISO, parse, format } from 'date-fns'

const TransposeData = {
    getDataOfChartDiff: function(gridApi, selectedOption) { 
        return new Promise((resolve, reject) => {
            let dataChart = [];
            let formattersChart = [];

            if (gridApi != undefined) {
              //add the first line (header)
              switch(selectedOption) {
                case 'dia':
                    dataChart.push([
                      { type: 'date', label: 'Dia' },
                      'Mirakl/Middleware',
                      'Mirakl/Vtex',
                      'Mirakl/Linx',
                      'Middleware/Vtex',
                      'Middleware/Linx',
                      'Vtex/Linx'
                    ]);

                    formattersChart = [
                      {
                        type: 'DateFormat',
                        column: 0,
                        options: {
                          pattern: 'dd/MM/yyyy',
                        },
                      }
                    ];
                  break;
                case 'hora':
                  dataChart.push([
                    { type: 'datetime', label: 'Hora' },
                    'Mirakl/Middleware',
                    'Mirakl/Vtex',
                    'Mirakl/Linx',
                    'Middleware/Vtex',
                    'Middleware/Linx',
                    'Vtex/Linx'
                  ]);

                  formattersChart = [
                    {
                      type: 'DateFormat',
                      column: 0,
                      options: {
                        pattern: 'dd/MM/yyyy HH:mm',
                      },
                    }
                  ];
                  break;
                case 'semana':
                  dataChart.push([
                    { type: 'date', label: 'Semana' },
                    'Mirakl/Middleware',
                    'Mirakl/Vtex',
                    'Mirakl/Linx',
                    'Middleware/Vtex',
                    'Middleware/Linx',
                    'Vtex/Linx'
                  ]);

                  formattersChart = [
                    {
                      type: 'DateFormat',
                      column: 0,
                      options: {
                        pattern: 'dd/MM/yyyy',
                      },
                    }
                  ];
                  break;
                case 'mes':
                    dataChart.push([
                      { type: 'date', label: 'Mês' },
                      'Mirakl/Middleware',
                      'Mirakl/Vtex',
                      'Mirakl/Linx',
                      'Middleware/Vtex',
                      'Middleware/Linx',
                      'Vtex/Linx'
                  ]);

                  formattersChart = [
                    {
                      type: 'DateFormat',
                      column: 0,
                      options: {
                        pattern: 'MM/yyyy',
                      },
                    }
                  ];
                  break;
                case 'ano': 
                  dataChart.push([
                    { type: 'date', label: 'Ano' },
                    'Mirakl/Middleware',
                    'Mirakl/Vtex',
                    'Mirakl/Linx',
                    'Middleware/Vtex',
                    'Middleware/Linx',
                    'Vtex/Linx'
                  ]);

                  formattersChart = [
                    {
                      type: 'DateFormat',
                      column: 0,
                      options: {
                        pattern: 'yyyy',
                      },
                    }
                  ];
                  break;
              }
      
              gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
                dataChart[index+1] = new Array();
      
                if (selectedOption === 'hora') {
                    dataChart[index+1].push(parse(rowNode.data.createdOn, 'dd/MM/yyyy HH:mm', new Date()));
                } else if (selectedOption === 'semana' || selectedOption === 'dia') {
                    dataChart[index+1].push(parse(rowNode.data.createdOn, 'dd/MM/yyyy', new Date()));
                } else if (selectedOption === 'mes' ) {
                    dataChart[index+1].push(parse(rowNode.data.createdOn, 'MM/yyyy', new Date()));
                } else {
                    dataChart[index+1].push(rowNode.data.createdOn);
                }
      
                dataChart[index+1].push(parseFloat(rowNode.data.percentMiraklMidware.replace(',','.')));
                dataChart[index+1].push(parseFloat(rowNode.data.percentMiraklVtex.replace(',','.')));
                dataChart[index+1].push(parseFloat(rowNode.data.percentMiraklLinx.replace(',','.')));
                dataChart[index+1].push(parseFloat(rowNode.data.percentMidwareVtex.replace(',','.')));
                dataChart[index+1].push(parseFloat(rowNode.data.percentMidwareLinx.replace(',','.')));
                dataChart[index+1].push(parseFloat(rowNode.data.percentVtexLinx.replace(',','.')));
              });

              resolve([dataChart, formattersChart]);
            }
        })
    },
    getDataOfChartIncrease: function(gridApi, selectedOption) { 
        return new Promise((resolve, reject) => {
            let dataChart = [];
            let formattersChart = [];

            if (gridApi != undefined) {
              //add the first line (header)
              switch(selectedOption) {
                case 'dia':
                  dataChart.push([
                      { type: 'date', label: 'Dia' },
                      'Total Mirakl',
                      'Total Linx',
                      'Total Middleware',
                      'Total Vtex'
                    ]);

                    formattersChart = [
                      {
                        type: 'DateFormat',
                        column: 0,
                        options: {
                          pattern: 'dd/MM/yyyy',
                        },
                      }
                    ];
                  break;
                case 'hora':
                    dataChart.push([
                      { type: 'datetime', label: 'Hora' },
                      'Total Mirakl',
                      'Total Linx',
                      'Total Middleware',
                      'Total Vtex'
                    ]);

                    formattersChart = [
                      {
                        type: 'DateFormat',
                        column: 0,
                        options: {
                          pattern: 'dd/MM/yyyy HH:mm',
                        },
                      }
                    ];
                  break;
                case 'semana':
                    dataChart.push([
                      { type: 'date', label: 'Semana' },
                      'Total Mirakl',
                      'Total Linx',
                      'Total Middleware',
                      'Total Vtex'
                    ]);

                    formattersChart = [
                      {
                        type: 'DateFormat',
                        column: 0,
                        options: {
                          pattern: 'dd/MM/yyyy',
                        },
                      }
                    ];
                  break;
                case 'mes':
                    dataChart.push([
                      { type: 'date', label: 'Mês' },
                      'Total Mirakl',
                      'Total Linx',
                      'Total Middleware',
                      'Total Vtex'
                    ]);          
                    
                    formattersChart = [
                      {
                        type: 'DateFormat',
                        column: 0,
                        options: {
                          pattern: 'MM/yyyy',
                        },
                      }
                    ];
                  break;    
                case 'ano': 
                    dataChart.push([
                      { type: 'date', label: 'Ano' },
                      'Total Mirakl',
                      'Total Linx',
                      'Total Middleware',
                      'Total Vtex'
                    ]);

                    formattersChart = [
                      {
                        type: 'DateFormat',
                        column: 0,
                        options: {
                          pattern: 'yyyy',
                        },
                      }
                    ];
                  break;
              }
      
              gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
                dataChart[index+1] = new Array();
      
                if (selectedOption === 'hora') {
                    dataChart[index+1].push(parse(rowNode.data.createdOn, 'dd/MM/yyyy HH:mm', new Date()));
                } else if (selectedOption === 'semana' || selectedOption === 'dia') {
                    dataChart[index+1].push(parse(rowNode.data.createdOn, 'dd/MM/yyyy', new Date()));
                } else if (selectedOption === 'mes' ) {
                    dataChart[index+1].push(parse(rowNode.data.createdOn, 'MM/yyyy', new Date()));
                } else {
                    dataChart[index+1].push(rowNode.data.createdOn);
                }
      
                dataChart[index+1].push(parseFloat(rowNode.data.totalMirakl));
                dataChart[index+1].push(parseFloat(rowNode.data.totalVtex));
                dataChart[index+1].push(parseFloat(rowNode.data.totalMidwareOk));
                dataChart[index+1].push(parseFloat(rowNode.data.totalLinx));
              });

              resolve([dataChart, formattersChart]);
            }
        })
    }
}

export default TransposeData;