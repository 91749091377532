import { doAuth } from '../../../commons/Connection'
import { GET_USER_INFO } from '../../../commons/Services'

const getUserInfo = () => new Promise((resolve, reject) => {
  //console.log("calling user info")
  doAuth(GET_USER_INFO)
    .then(response => resolve(response))
    .catch((error) => {
      window.console.log(error)
      reject(error)
    })
})

export default getUserInfo
