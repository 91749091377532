import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindAll, isEqual } from 'lodash'
import ActionButton from '../buttons/ActionButton'

import './Closable.css'

class Closable extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      currentTimeout: null
    }

    bindAll(this, ['validateAutoClose'])
  }

  componentDidMount() {
    this.validateAutoClose(this.props)
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props
    const { currentTimeout } = this.state
    if (!isOpen && nextProps.isOpen) {
      clearTimeout(currentTimeout)
      this.validateAutoClose(nextProps.isOpen)
    }
  }

  validateAutoClose(nextIsOpen) {
    const { autoCloseTime, isOpen, onClose } = this.props

    if (autoCloseTime && (isEqual(isOpen, true) || isEqual(nextIsOpen, true))) {
      const currentTimeout = setTimeout(() => {
        onClose()
      }, autoCloseTime)
      this.setState({
        currentTimeout
      })
    }
  }

  render() {
    const {
      isOpen, className, children, onClose, showCloseButton
    } = this.props

    return isOpen ? (
      <div className={`closable ${className}`}>
        {children}
        {showCloseButton ? (
          <ActionButton
            componentClass="closable-close"
            icon="icon-close"
            onClickAction={onClose}
          />) : null}
      </div>) : null
  }
}

Closable.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node)]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool.isRequired,
  autoCloseTime: PropTypes.number
}

export default Closable
