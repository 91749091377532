import React, { Component, Fragment } from 'react';
import ActionButton from '../../components/buttons/ActionButton'
import BillingAdjustmentGrid from './BillingAdjustmentGrid'
import CustomDialog from '../../components/commons/dialog/CustomDialog'
import { bindAll } from 'lodash'
import ManualAdjustmentController from '../../controllers/ManualAdjustmentController'
import SellerController from '../../controllers/SellerController'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'
import Closable from '../../components/closable/Closable'
import { Select, ListItemText } from '@material-ui/core'
import InputMoney from '../../components/form-components/input-money/InputMoney'
import DropZone from '../../components/dropzone/DropZone'

import loaderIcon from '../../resources/images/loaderComplete.gif'

const adjustmentTypeOptionsList = [    
  { value: 'Débito', text: 'Débito' },
  { value: 'Crédito', text: 'Crédito' }
];

class BillingAdjustments extends Component { 
  constructor(props){
    super(props)

    this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',            
        billingAdjustmentList: [],
        addAdjustmentScreenOpened: false,
        addAdjustmentMassiveScreenOpened: false,
        gridApi: undefined,
        loader: false,
        seller: undefined,
        sellerList: [],
        adjustmentTypeOption: '',
        manualAdjustmentValue: 0,
        fileUpload: undefined
    }

    bindAll(this, [
        'refreshGrid',
        'fillGridBillingAdjustments',
        'onGridReady',
        'setValueSellerSelected',
        'closePartnerMessage',
        'onFileAdded'
    ]);
  }

  
  handleOnAddAdjustmentClick = () => {
    this.setState({
        addAdjustmentScreenOpened: true
    })
}

handleOnCancelAddAdjustmentClick = () => {
    this.setState({
        addAdjustmentScreenOpened: false
    })
}

handleOnSaveAddAdjustmentClick = () => {
  this.setState({
    addAdjustmentScreenOpened: false
  })        
}    

handleOnAddAdjustmentMassiveClick = () => {
  this.setState({
      addAdjustmentMassiveScreenOpened: true
  })
}

handleOnCancelAddAdjustmentMassiveClick = () => {
  this.setState({
    addAdjustmentMassiveScreenOpened: false
  })
}

handleOnSaveAddAdjustmentMassiveClick = () => {
    this.setState({ loader: true });

    if (this.state.fileUpload == undefined) {
      this.setState({
        showPartnerMessage: true,
        message: `Não foi selecionado um arquivo para ser enviado!`,
        colorMessage: 'header-message header-error-message',
        loader: false
      });
      
      return;
    }

    ManualAdjustmentController.uploadMassiveBillingAdjustment(this.getUploadRequest())
      .then((data) => {
        this.setState({
          showPartnerMessage: true,
          message: `Arquivo enviado para ajuste manual massivo!`,
          colorMessage: 'header-message header-success-message',
          loader: false,
          fileUpload: undefined,
          addAdjustmentMassiveScreenOpened: false
        });
      })
      .catch((error) => {
        this.setState({
          showPartnerMessage: true,
          message: `Erro enviar arquivo para ajuste manual massivo: ${error}`,
          colorMessage: 'header-message header-error-message',
          loader: false
        });
      })
}    

onGridReady = (params) => {
    this.setState({ gridApi: params.api })
}

refreshGrid = () => {
    let params = {
        force: true,
        suppressFlash: true,
    }        
    this.state.gridApi.refreshCells(params);
}    

fillGridBillingAdjustments = () => {        
                    
      this.setState({ loader: true });
      ManualAdjustmentController.getBillingAdjustments()
        .then(
            data => {                    
                console.log(data.billingAdjustmentList);
                this.state.gridApi.setRowData(data.billingAdjustmentList);
                this.refreshGrid();

                this.setState({
                    loader: false
                });
            }
        ).catch((error) => {
            console.log(error);
            this.setState({
                loader: false,
                showPartnerMessage: true,
                message: `Erro ao tentar chamar a API de comissionamento. ${error}`,
                colorMessage: 'header-message header-error-message'
            });
        });        
  }

  setValueSellerSelected = (value) => {
      this.setState({
          seller: value
      }, () => { console.log(this.state.seller) });
  }

  searchSellerByTrandingName = (trandingName) => {
    SellerController.getSellerList(trandingName)
      .then((result) => {
        this.setState({
          sellerList: result
        })
      })
      .catch((error) => {
        this.setState({
          showPartnerMessage: true, 
          message: `Erro ao buscar o Seller: ${error}`,
          colorMessage: 'header-message header-error-message'
        });
      });
  }

  getUploadRequest() {
    const { fileUpload } = this.state
    const file = new FormData()
    //const fileBlob = new Blob(fileUpload, { type: 'application/pdf' })
    file.append('file', fileUpload)
    return {
      file
    }
  }

  onFileAdded(file) {
    this.setState({
      fileUpload: file
    })
  }
  
  componentDidMount() {
      this.fillGridBillingAdjustments()
  }

  closePartnerMessage = () => {
      this.setState({ showPartnerMessage: false })
  }

  handleChangeSelectAdjustmentType = (event) => {
      this.setState({ adjustmentTypeOption: event.target.value })
  }

  handleChangeManualAdjustmentValue = (id, value) => {                
      this.state.manualAdjustmentValue = value
  }

  render () {
    return (
      <Fragment>
          <div className="container">
              <div className="column" style={{ width: '100%', height: '40px' }}>
                  <h2>Ajuste Manual</h2>
              </div>
              <div className="column" style={{ width: '50%', alignContent: 'right', display: 'none'}}>
                  <ActionButton
                          onClickAction={this.handleOnAddAdjustmentClick}
                          id="btnAddAdjustment"
                          text="Adicionar Ajuste"
                          icon="icon-edit"
                          componentClass="btn btn-action" />                        
              </div>              
              <div className="column" style={{ width: '50%', alignContent: 'right'}}>
                  <ActionButton
                          onClickAction={this.handleOnAddAdjustmentMassiveClick}
                          id="btnAddAdjustmentMassive"
                          text="Adicionar Ajuste Massivo"
                          icon="icon-edit"
                          componentClass="btn btn-action" />                        
              </div>
          </div>
          <div  className="container">
              <BillingAdjustmentGrid
                  height={450} 
                  width="100%"                         
                  onGridReady={this.onGridReady}
                  billingAdjustmentList={this.state.billingAdjustmentList}                 
              />
          </div>

          <CustomDialog
              title='Adicionar Ajuste'
              open= {this.state.addAdjustmentScreenOpened}
              id="ajusteManual"
          >

              <div className="column" style={{ width: '100%', display: 'flex',  alignItems:'center'}}>
                  <span style={{ width: '50px' }}>Seller: </span>
                  <InputAutocomplete                            
                      setValue={this.setValueSellerSelected}
                      searchSellerByTrandingName={this.searchSellerByTrandingName}
                      sellerList={this.state.sellerList}
                  />
              </div>
              <div className="column" style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
                  <span>Tipo de ajuste:&nbsp;</span>
                  <Select value={this.state.adjustmentTypeOption} onChange={this.handleChangeSelectAdjustmentType}>
                      {adjustmentTypeOptionsList.map(item => {
                          return (<option key={item.value} value={item.value}>{item.text}</option>);
                      })}
                  </Select>
              </div>
              <div className="column" style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
                  <span>Valor:&nbsp;</span>
                  <InputMoney                    
                      id="valorAjuste"             
                      value={this.state.manualAdjustmentValue} 
                      onChangeValue={(id, value) => this.handleChangeManualAdjustmentValue(id, value)}                            
                  />
                  <span>Descrição:&nbsp;</span>
                  <ListItemText/>
              </div>
              <div className="column" style={{ width: '20%'}}></div>

              <div className="column" style={{
                  width: '35%',
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'space-between'
              }}>

                  <ActionButton
                      onClickAction={this.handleOnCancelAddAdjustmentClick}
                      id="btnCancelAddAdjustment"
                      text="Cancelar"
                      componentClass="btn btn-default" />

                  <ActionButton
                      onClickAction={this.handleOnSaveAddAdjustmentClick}
                      id="btnSaveAddAdjustment"
                      text="Adicionar Ajuste"
                      componentClass="btn btn-action"                            
                      />
              </div>
              
          </CustomDialog>

          <CustomDialog
              title='Adicionar Ajuste em Massa'
              open= {this.state.addAdjustmentMassiveScreenOpened}
              id="ajusteManualMassive"
          >

              <div className='column' style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                <span style={{ 'font-size': '30px', 'color': '#156992', 'font-weight': 'bold'}}>Upload de arquivo XLS: </span>
              </div>
              <div className="column" style={{ width: '100%', display: 'flex',  alignItems:'center'}}>
                  <DropZone onFileAdded={this.onFileAdded} file={this.state.fileUpload} accept=".xlsx" />
              </div>
              <div className="column" style={{ width: '100%'}}></div>

              <div className="column" style={{
                  width: '35%',
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold'
              }}>
                  <ActionButton
                      onClickAction={this.handleOnCancelAddAdjustmentMassiveClick}
                      id="btnCancelAddAdjustmentMassive"
                      text="Cancelar"
                      componentClass="btn btn-default" />

                  <ActionButton
                      onClickAction={this.handleOnSaveAddAdjustmentMassiveClick}
                      id="btnSaveAddAdjustmentMassive"
                      text="Enviar"
                      componentClass="btn btn-action"                            
                      />
              </div>
          </CustomDialog>
          
          <section className="header-message-container">
              <Closable
                  className={this.state.colorMessage || ''}
                  isOpen={this.state.showPartnerMessage || false}
                  onClose={this.closePartnerMessage}
                  showCloseButton
                  autoCloseTime={5000}
              >
                  <span className="commission-partner-save-success">
                      <i className="icon-ok-check" />
                      {this.state.message}
                  </span>
              </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>         
    );
  }
}

export default BillingAdjustments;
