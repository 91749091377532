import React from 'react'

/** components */
import Title from './title/Title'

export default function Header() {
  const locationPathName = window.location.pathname
  return (
    <div className="header">
      <Title />
    </div>
  )
}
