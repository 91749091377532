import axios from 'axios'
import env from './Environment'

import { getAuthorizationHeaders } from './Connection'

const axiosProduct = axios.create({
    baseURL: env.getUrlEnv().REACT_APP_MKTP_PRODUCT,
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors'
})

axiosProduct.interceptors.request.use(
    getAuthorizationHeaders,
    error => Promise.reject(error)
)

export const getListLockProduct = (url, params) => (
    new Promise((resolve, reject) => {
        axiosProduct.get(`${url}?${params}`)
          .then(({ data }) => {
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));

export const unlockProduct = (url, listSku) => (
    new Promise((resolve, reject) => {
        axiosProduct.post(url, listSku)
          .then(({ data }) => {
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));

export const lockProduct = (url, listSku) => (
    new Promise((resolve, reject) => {
        axiosProduct.post(url, listSku)
          .then(({ data }) => {
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));

export const uploadMassiveLockProduct = (url, data) => (
     new Promise((resolve, reject) => {
        //axiosProduct.post(url, JSON.stringify(data))
        axiosProduct.post(url, data)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => {
            reject(err)
        });
    }
));

export const getLockProductParams = (url, params) => (
    new Promise((resolve, reject) => {
        axiosProduct.get(`${url}?${params}`)
          .then(({ data }) => {
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));

export const setLockProductParams = (url, params) => (
    new Promise((resolve, reject) => {
        axiosProduct.post(url, params)
          .then(({ data }) => {
              resolve(data)
          })
          .catch(err => {
              reject(err)
          });
    }
));
