import { createUser, 
         updateUser, 
         getUserByUsername, 
         getUserListByUsername, 
         isNewUser, 
         getAllUsers,
         updateLastLogon,
         deleteUserByUsername } from "../commons/UserApi";

const ManagementController = {
    createUser: function(userName, listRoles ) {
        let data = {};

        if (userName) {
            data.userName = userName;
        }

        if (listRoles) {
            data.roles = listRoles;
        }

        return createUser("/v1/management/createUser", data);
    },
    updateUser: function(userName, listRoles ) {
        let data = {};

        if (userName) {
            data.userName = userName;
        }

        if (listRoles) {
            data.roles = listRoles;
        }

        return updateUser("/v1/management/updateUser", data);
    },

    updateUserPassword: function(userName, newPassword ) {
        let data = {};

        if (userName) {
            data.userName = userName;
        }

        if (newPassword) {
            data.password = newPassword;
        }

        data.isNewUser = false;

        return updateUser("/v1/management/updateUser", data);
    },
    updateActive: function(userName, active ) {
        let data = {
            userName: userName,
            active: active
        };

        return updateUser("/v1/management/updateUser", data);
    },
    getAllUsers: function() {
        return getAllUsers("/v1/management/getAllUsers");
    },
    getUserByUsername: function(userName) {
        return getUserByUsername("/v1/management/getUserByUsername", userName);
    },
    getUserListByUsername: function(userName) {
        return getUserListByUsername("/v1/management/getUserListByUsername", userName);
    },
    isNewUser: function(userName) {
        return isNewUser("/v1/management/isNewUser", userName);
    },
    updateLastLogon: function(userName) {
        let data = {};

        if (userName) {
            data.userName = userName;
        }

        return updateLastLogon("/v1/management/updateLastLogon", data);
    },
    deleteUserByUsername: function(userName) {
        return deleteUserByUsername("/v1/management/deleteUserByUsername", userName);
    }    
};

export default ManagementController;