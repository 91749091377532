import React from 'react'
import PropTypes from 'prop-types'

import './ActionButton.css'

const ActionButton = (props) => {
  const {
    componentClass, onClickAction, icon, text, id, type, disable, title
  } = props
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={`btn-default ${componentClass} ${disable ? 'disable' : 'enable'}`}
      onClick={onClickAction}
      disabled={disable}
      id={id}
      type={type || 'button'}
      title={title}
    >
      {icon ? <i className={icon} /> : null}
      {text ? <span className="text-description">{text}</span> : null}
    </button>)
}
ActionButton.propTypes = {
  componentClass: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.string,
  onClickAction: PropTypes.func,
  id: PropTypes.string,
  disable: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string
}
export default ActionButton
