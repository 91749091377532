import { uploadMassiveBillingAdjustment, doGetDataHub} from "../commons/Connection";

const ManualAdjustmentController = {
    uploadMassiveBillingAdjustment: function(body) {
        return uploadMassiveBillingAdjustment("/v1/billing-adjustments/massive", body.file);
    },
    getBillingAdjustments: function() {
        return doGetDataHub("/v1/billing-adjustments");
    }
};

export default ManualAdjustmentController;