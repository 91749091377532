import React, { Component, Fragment } from 'react'

import { Redirect } from 'react-router-dom'

import Closable from '../../components/closable/Closable'

import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'
import { format, getDate, getMonth, getYear, getHours, getMinutes, compareDesc, sub, parse, differenceInDays } from 'date-fns'

import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import TopSellersGrid from './TopSellersGrid'
import ItemsByPeriodGrid from './ItemsByPeriodGrid'

import TopSellersController from '../../controllers/TopSellersController'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'

import InputDatePicker from '../../components/form-components/input-date-picker/inputDatePicker'
import InputTimePicker from '../../components/form-components/input-time-picker/InputTimePicker'
import InputMoney from '../../components/form-components/input-money/InputMoney'
import InputPercentage from '../../components/form-components/input-percentage/InputPercentage'
import { Select, MenuItem, Checkbox, OutlinedInput, ListItemText, ListItemSecondaryAction } from '@material-ui/core'

import './TopSellers.css'
import { isThisSecond } from 'date-fns'
import { Warning } from '@material-ui/icons'
import Dynamic from '../../components/dynamic-tabs/Dynamic'
import moment from 'moment'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const clusterFilterOptionsList = [
    { value: '', text: 'Sem filtro' },
    { value: 'Key_Account', text: 'Key Account' },
    { value: 'Long_Tail', text: 'Long Tail' },
    { value: 'Cross_Border', text: 'CrossBorder' }
];

const displayFilterOptionsList = [
    { value: 10, text: '10' },
    { value: 20, text: '20' },
    { value: 50, text: '50' },
    { value: 100, text: '100' }
];


class TopSellers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showPartnerMessage: false,
            message: '',
            colorMessage: '',
            gridApi: undefined,
            loader: false,
            disableExport: false,
            editLockOpen: false,
            sellerList: [],
            sellerList_tab2: [],
            sellerListSearch: [],
            sellerListSearch_tab2: [],
            fromDate: sub(new Date().setHours(0, 0, 0, 0), { days: 30 }),
            toDate: new Date(),
            fromDate_tab2: sub(new Date().setHours(0, 0, 0, 0), { days: 90 }),
            toDate_tab2: new Date(),
            reportType: 'period',
            groupByReport: 'group-by-week',
            groupByReport_tab2: 'group-by-week',
            displayFilterOption: 10,
            clusterFilterOption: '',
            seller: '',
            seller_tab2: '',
            tabPublishedProducts: 'btn btn-default',
            tabItemsByPeriod: 'btn btn-action-tab',
            tabPending: 'btn btn-action-tab',
            tabs: [],
            tabs_tab2: [],
            panels: [],
            panels_tab2: [],
            selectedTab: '0',
            selectedTab_tab2: '0',
            tabIndex: -1,
            tabIndex_tab2: -1,
            mainTabs: [],
            mainPanels: [],
            filterMessage: ''
        }

        bindAll(this, [
            'closePartnerMessage',
            'onGridReady',
            'refreshGrid',
            'searchSellerByTrandingName',
            'setValueSellerSelected',
            'setValueSellerSelected_tab2',
            'onValueChangeReportType',
            'onValueChangeGroupByReport',
            'onValueChangeGroupByReport_tab2',
            'handleOnClickFilter',
            'handleOnClickFilterByPeriod',
            'createTabList',
            'createTabByPeriodList',
            'handleChangeSelectDisplayFilter',
            'handleChangeSelectClusterFilter',
            'createMainTabList',
            'createMainPanelList',
            'createTopSellerPanel',
            'createItemsByPeriodPanel',
            'clearForms'
        ]);
    }

    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    onGridReady = params => {
        this.setState({ gridApi: params.api })
    }

    refreshGrid = () => {
        let params = {
            force: true,
            suppressFlash: true,
        }
        this.state.gridApi.refreshCells(params);
    }

    formatDateToISO = (date, time) => {
        let dateTime;

        if (date && time) {
            let day = getDate(date)
            let month = getMonth(date)
            let year = getYear(date)
            let hours = getHours(time)
            let minutes = getMinutes(time)
            dateTime = new Date(year, month, day, hours, minutes, 0)
        } else {
            return this.formatDateToISO(new Date(), new Date());
        }

        return format(dateTime, 'dd/MM/yyyy HH:mm:ss')
    }

    onValueChangeReportType(event) {
        this.setState({
            reportType: event.target.value
        }, () => {
            this.checkDateFilterRange(this.state.groupByReport)
        })
    }

    checkDateFilterRange(filterIndex){
        let returningMessage = ''
        var a = moment(this.state.fromDate)
        var b = moment(this.state.toDate)
      
        if (this.state.reportType === 'period'){
            if ((filterIndex === "group-by-week") && (b.diff(a, 'weeks') > 5))            
                returningMessage = 'Atenção: O intervalo máximo permitido para o agrupamento semanal é de 5 semanas.'
            else if ((filterIndex === "group-by-month") && (b.diff(a, 'months') > 6))                        
                returningMessage = 'Atenção: O intervalo máximo permitido para o agrupamento mensal é de 6 meses.'
        }

        this.setState({
                filterMessage: returningMessage
        })                
    }

    onValueChangeGroupByReport(event) {
        this.setState({
            groupByReport: event.target.value            
        }, () => {
            this.checkDateFilterRange(this.state.groupByReport)
        })
    }

    onValueChangeGroupByReport_tab2(event) {
        this.setState({
            groupByReport_tab2: event.target.value
        })
    }

    handleChangeSelectDisplayFilter(event) {
        this.setState({
            displayFilterOption: event.target.value
        })
    }

    handleChangeSelectClusterFilter(event) {
        this.setState({
            clusterFilterOption: event.target.value
        })
    }

    formValidate = () => {
        if (!this.state.seller) { return false; }

        return true;
    }

    formValidate_tab2 = () => {
        if (!this.state.seller_tab2) { return false; }

        return true;
    }    

    handleChangeTabs = (event) => {
        console.log(event);

        this.clearForms();
    }

    clearForms = () => {
        let eventClear = document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
        if (eventClear != undefined) {
          eventClear.click();
        }

        this.setState({             
                        seller: '',
                        seller_tab2: '',
                        sellerList: [],
                        sellerList_tab2: [],
                        sellerListSearch: [],
                        sellerListSearch_tab2: [],
                        fromDate: sub(new Date().setHours(0, 0, 0, 0), { days: 30 }),
                        toDate: new Date(),
                        fromDate_tab2: sub(new Date().setHours(0, 0, 0, 0), { days: 90 }),
                        toDate_tab2: new Date(),
                        reportType: 'period',
                        groupByReport: 'group-by-week',
                        groupByReport_tab2: 'group-by-week',
                        displayFilterOption: 10,
                        clusterFilterOption: '',
                        tabs: [],
                        tabs_tab2: [],
                        panels: [],
                        panels_tab2: [],
                        selectedTab: '0',
                        selectedTab_tab2: '0',
                        tabIndex: -1,
                        tabIndex_tab2: -1,
                    });
    } 

    handleOnClickFilter = () => {

        if (this.state.reportType === "seller" && !this.formValidate()) {
            this.setState({
                showPartnerMessage: true,
                message: `Você deve preencher o campo Seller!`,
                colorMessage: 'header-message header-error-message'
            });
        }
        else {

            const groupBy = this.state.groupByReport
            const init = format(this.state.fromDate, 'yyyy-MM-dd')
            const end = format(this.state.toDate, 'yyyy-MM-dd')
            const total = this.state.displayFilterOption
            const cluster = (this.state.reportType === "period") ? this.state.clusterFilterOption : ''
            const sellerId = (this.state.reportType === "seller") ? this.state.seller.sellerId : ''

            TopSellersController.getTopSellersList(groupBy, init, end, total, cluster, sellerId)
                .then(
                    data => {
                        this.setState({
                            sellerList: data,
                            loader: false,
                            tabIndex: this.state.tabIndex + 1
                        }, () => {
                            this.createTabList(this.state.tabIndex).then(([resultTabs, resultPanels]) => {
                                this.setState({
                                    tabs: resultTabs,
                                    panels: resultPanels,
                                    selectedTab: this.state.tabIndex.toString(),
                                    tabIndex: this.state.tabIndex + this.state.sellerList.length
                                })
                            }).catch((error) => {
                                console.log(error)
                            })
                        });
                    }).catch((error) => {
                        console.log(error)
                        if (error.response.status == "404")
                            this.setState({
                                loader: false,
                                showPartnerMessage: true,
                                message: `Não existem dados de Sellers com esses filtros.`,
                                colorMessage: 'header-message header-error-message'
                            });
                        else
                            this.setState({
                                loader: false,
                                showPartnerMessage: true,
                                message: `Erro ao tentar chamar a API de Top Selllers: ${error}`,
                                colorMessage: 'header-message header-error-message'
                            });
                    })
        }
    }

    handleOnClickFilterByPeriod = () => {

        if (!this.formValidate_tab2()) {
            this.setState({
                showPartnerMessage: true,
                message: `Você deve preencher o campo Seller!`,
                colorMessage: 'header-message header-error-message'
            });
        }
        else {

            const groupBy = this.state.groupByReport_tab2
            const init = format(this.state.fromDate_tab2, 'yyyy-MM-dd')
            const end = format(this.state.toDate_tab2, 'yyyy-MM-dd')            
            const sellerId = this.state.seller_tab2.sellerId

            TopSellersController.getItemsByPeriod(groupBy, init, end, sellerId)
                .then(
                    data => {
                        this.setState({
                            sellerList_tab2: data,
                            loader: false,
                            tabIndex_tab2: this.state.tabIndex_tab2 + 1
                        }, () => {
                            this.createTabByPeriodList(this.state.tabIndex_tab2).then(([resultTabs, resultPanels]) => {
                                this.setState({
                                    tabs_tab2: resultTabs,
                                    panels_tab2: resultPanels,
                                    selectedTab_tab2: this.state.tabIndex_tab2.toString(),
                                    tabIndex_tab2: this.state.tabIndex_tab2 + this.state.sellerList_tab2.length
                                })
                            }).catch((error) => {
                                console.log(error)
                            })
                        });
                    }).catch((error) => {
                        console.log(error)
                        if (error.response.status == "404")
                            this.setState({
                                loader: false,
                                showPartnerMessage: true,
                                message: `Não existem dados de Sellers com esses filtros.`,
                                colorMessage: 'header-message header-error-message'
                            });
                        else
                            this.setState({
                                loader: false,
                                showPartnerMessage: true,
                                message: `Erro ao tentar chamar a API de Items por período: ${error}`,
                                colorMessage: 'header-message header-error-message'
                            });
                    })
        }
    }

    handleOnClickDownloadFile = () => {

        if (this.state.reportType === "seller" && !this.formValidate()) {
            this.setState({
                showPartnerMessage: true,
                message: `Você deve preencher o campo Seller!`,
                colorMessage: 'header-message header-error-message'
            });
        }
        else {

            const groupBy = this.state.groupByReport
            const init = format(this.state.fromDate, 'yyyy-MM-dd')
            const end = format(this.state.toDate, 'yyyy-MM-dd')
            const total = this.state.displayFilterOption
            const cluster = (this.state.reportType === "period") ? this.state.clusterFilterOption : ''
            const sellerId = (this.state.reportType === "seller") ? this.state.seller.sellerId : ''

            TopSellersController.getTopSellersSheet(groupBy, init, end, total, cluster, sellerId)
                .then().catch((error) => {
                    console.log(error)
                    if (error.response.status == "404")
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Não existem dados de Sellers com esses filtros.`,
                            colorMessage: 'header-message header-error-message'
                        });
                    else
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Erro ao tentar chamar a API de download de Top Selllers: ${error}`,
                            colorMessage: 'header-message header-error-message'
                        });
                })
        }
    }

    handleOnClickDownloadFileByPeriod = () => {

        if (!this.formValidate_tab2()) {
            this.setState({
                showPartnerMessage: true,
                message: `Você deve preencher o campo Seller!`,
                colorMessage: 'header-message header-error-message'
            });
        }
        else {

            const groupBy = this.state.groupByReport_tab2
            const init = format(this.state.fromDate_tab2, 'yyyy-MM-dd')
            const end = format(this.state.toDate_tab2, 'yyyy-MM-dd')
            const sellerId = this.state.seller_tab2.sellerId

            TopSellersController.getItemsByPeriodSheet(groupBy, init, end, sellerId)
                .then().catch((error) => {
                    console.log(error)
                    if (error.response.status == "404")
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Não existem dados de Sellers com esses filtros.`,
                            colorMessage: 'header-message header-error-message'
                        });
                    else
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Erro ao tentar chamar a API de download de Top Selllers: ${error}`,
                            colorMessage: 'header-message header-error-message'
                        });
                })
        }
    }

    async createMainTabList() {
        var resultMainTabs = []

        resultMainTabs.push(this.createNewTab(0, 'Top Sellers'))
        resultMainTabs.push(this.createNewTab(1, 'Itens Produzidos por período'))
        //resultMainTabs.push(this.createNewTab(2, 'Pendentes de Produção'))

        return resultMainTabs
    }

    async createMainPanelList() {
        var resultMainPanels = []

        resultMainPanels.push(this.createTopSellerPanel(0))
        resultMainPanels.push(this.createItemsByPeriodPanel(1))
        //resultMainPanels.push(this.createPendents(2))

        return resultMainPanels
    }

    createTabList = (tabIndex) => new Promise((resolve) => {
        var resultTabs = []
        var resultPanels = []

        for (let index = 0; index < this.state.sellerList.length; index++) {
            resultTabs.push(this.createNewTab(index + tabIndex, this.createTabName(this.state.sellerList[index].sellers[0].period)))
            resultPanels.push(this.createNewPanel(index + tabIndex, index))
        }
        console.log(resultTabs)
        console.log(resultPanels)
        resolve([resultTabs, resultPanels])
    })

    createTabName = (items) => {

        var tabName = ''
        if (this.state.groupByReport == "group-by-week")
            tabName = 'Sem ' + items.week + ' - '
        if (this.state.groupByReport != "group-by-year")
            tabName += items.month + '/'
        tabName += items.year

        return tabName
    }

    createTabName_tab2 = (items) => {

        var tabName = ''
        if (this.state.groupByReport_tab2 == "group-by-week")
            tabName = 'Sem ' + items.week + ' - '
        if (this.state.groupByReport_tab2 != "group-by-year")
            tabName += items.month + '/'
        tabName += items.year

        return tabName
    }    

    createNewTab = (tabValue, tabLabel) => {
        const newTab = {
            value: { tabValue },
            label: { tabLabel }
        }
        return newTab;
    }

    handleChangeFromDate = (value, e) => {
        this.setState({
            fromDate: value,
            buttonChartDisable: true
        }, () => {
            this.checkDateFilterRange(this.state.groupByReport)
        });
    }

    handleChangeToDate = (value, e) => {
        this.setState({
            toDate: value,
            buttonChartDisable: true
        }, () => {
            this.checkDateFilterRange(this.state.groupByReport)
        });
    }

    handleChangeFromDate_tab2 = (value, e) => {
        this.setState({
            fromDate_tab2: value,
            buttonChartDisable: true
        });
    }

    handleChangeToDate_tab2 = (value, e) => {
        this.setState({
            toDate_tab2: value,
            buttonChartDisable: true
        });
    }

    createNewPanel = (tabIndex, index) => {
        const newPanel = {
            value: { tabIndex },
            child: () => <div>
                <TopSellersGrid
                    height={450}
                    width="100%"
                    onGridReady={this.onGridReady}
                    sellerList={this.state.sellerList[index] == null ? [] : this.state.sellerList[index].sellers}
                />
            </div>
        }
        return newPanel
    }

    createTabByPeriodList = (tabIndex) => new Promise((resolve) => {
        var resultTabs = []
        var resultPanels = []

        for (let index = 0; index < this.state.sellerList_tab2.length; index++) {
            resultTabs.push(this.createNewTab(index + tabIndex, this.createTabName_tab2(this.state.sellerList_tab2[index].sellers[0].period)))
            resultPanels.push(this.createNewByPeriodPanel(index + tabIndex, index))
        }
        console.log(resultTabs)
        console.log(resultPanels)
        resolve([resultTabs, resultPanels])
    })

    createNewByPeriodPanel = (tabIndex, index) => {
        const newPanel = {
            value: { tabIndex },
            child: () => <div>
                <ItemsByPeriodGrid
                    height={450}
                    width="100%"
                    onGridReady={this.onGridReady}
                    sellerList={this.state.sellerList_tab2[index] == null ? [] : this.state.sellerList_tab2[index].sellers}
                />
            </div>
        }
        return newPanel
    }    

    createTopSellerPanel = (tabIndex) => {
        const topSellerPanel = {
            value: {tabIndex},
            child: () =>
                <div>
                    <div className="container2">
                        <div className="column" style={{ width: '100%' }}>
                            <span>Tipo de Relatório:</span>                       
                        </div>

                        <div className="column" style={{ width: '100%', marginTop: '15px' }}>
                            <ul>
                                <li className="item">
                                    <span>
                                        <input type="radio" name="reportSellerFilter" value="period" checked={this.state.reportType === "period"} onChange={this.onValueChangeReportType} /> Relatório por Período
                                    </span>
                                </li>
                                <li className="item">
                                    <span>
                                        <input type="radio" name="reportSellerFilter" value="seller" checked={this.state.reportType === "seller"} onChange={this.onValueChangeReportType} /> Relatório por Seller
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className="column" style={{ width: '100%', marginTop: '30px' }}>
                            <span>Data:</span>
                        </div>
                        <div className="column" style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px' }}>
                            De:
                            <InputDatePicker startDate={this.state.fromDate} name="fromDate" handleChange={(value, e) => this.handleChangeFromDate(value, e)} />
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            Até:&nbsp;
                            <InputDatePicker startDate={this.state.toDate} name="toDate" handleChange={(value, e) => this.handleChangeToDate(value, e)} />                    
                        </div>
                        <div><span style={{color:'red'}}>{this.state.filterMessage}</span></div>
                        <div className="column" style={{ width: '100%', marginTop: '30px' }}>
                            <span>Agrupamento:</span>
                        </div>
                        <div className="column" style={{ width: '100%', marginTop: '15px' }}>
                            <ul>
                                <li className="item">
                                    <span>
                                        <input type="radio" name="reportGroupBy" value="group-by-week" checked={this.state.groupByReport === "group-by-week"} onChange={this.onValueChangeGroupByReport} /> Semana
                                    </span>
                                </li>
                                <li className="item">
                                    <span>
                                        <input type="radio" name="reportGroupBy" value="group-by-month" checked={this.state.groupByReport === "group-by-month"} onChange={this.onValueChangeGroupByReport} /> Mês
                                    </span>
                                </li>
                                <li className="item">
                                    <span>
                                        <input type="radio" name="reportGroupBy" value="group-by-year" checked={this.state.groupByReport === "group-by-year"} onChange={this.onValueChangeGroupByReport} /> Ano
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {this.state.reportType === "seller" &&
                        <>
                            <div className="column" style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                                <span>Seller:</span>
                            </div>
                            <div className="column" style={{ width: '30%', display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '15px' }}>
                                
                                <InputAutocomplete
                                    disabled={false}
                                    setValue={this.setValueSellerSelected}
                                    searchSellerByTrandingName={this.searchSellerByTrandingName}
                                    sellerList={this.state.sellerListSearch}
                                />
                            </div>
                        </>
                        }

                        {this.state.reportType === "seller" &&
                            <div className="column" style={{ width: '70%', display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '15px' }}>
                            </div>}

                        {this.state.reportType === "period" &&
                            <div className="column" style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '15px' }}>
                                Cluster:&nbsp;
                                <select value={this.state.clusterFilterOption} onChange={this.handleChangeSelectClusterFilter} style={{ width: '160px' }}>
                                    {clusterFilterOptionsList.map(item => {
                                        return (<option key={item.value} value={item.value}>{item.text}</option>);
                                    })}
                                </select>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                Exibição:&nbsp;
                                <select value={this.state.displayFilterOption} onChange={this.handleChangeSelectDisplayFilter} style={{ width: '140px' }}>
                                    {displayFilterOptionsList.map(item => {
                                        return (<option key={item.value} value={item.value}>{item.text}</option>);
                                    })}
                                </select>                            
                            </div>
                        }

                        <div className="column" style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                            <ActionButton
                                onClickAction={this.handleOnClickFilter}
                                id="btnFilter"
                                text="Filtrar"
                                icon="icon-search"
                                disable={this.state.filterMessage != ''}
                                componentClass="btn btn-action" />
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <ActionButton
                                onClickAction={this.handleOnClickDownloadFile}
                                id="btnExportCsv"
                                text="Exportar XLS"
                                icon="icon-search"
                                disable={this.state.filterMessage != ''}
                                componentClass="btn btn-action" />                            
                        </div>
                    </div>
                    <div className="container2" style={{ marginTop: '10px' }}>
                        <div className="column" style={{ width: '100%' }}>
                            <Dynamic
                                tabs={this.state.tabs}
                                panels={this.state.panels}
                                selectedTab={this.state.selectedTab}
                            />
                        </div>
                    </div>
                </div>
        }
        return topSellerPanel
    }

    createItemsByPeriodPanel = (tabIndex) => {
        const itemsByPeriodPanel = {
            value: {tabIndex},
            child: () =>
                <div>
                    <div className="container2">
                        <div className="column" style={{ width: '100%', marginTop: '15px' }}>
                            <span>Data:</span>
                        </div>
                        <div className="column" style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px' }}>
                            De:&nbsp;
                            <InputDatePicker startDate={this.state.fromDate_tab2} name="fromDate" handleChange={(value, e) => this.handleChangeFromDate_tab2(value, e)} />
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            Até:&nbsp;
                            <InputDatePicker startDate={this.state.toDate_tab2} name="toDate" handleChange={(value, e) => this.handleChangeToDate_tab2(value, e)} />                    
                        </div>                        
                        <div className="column" style={{ width: '100%', marginTop: '30px' }}>
                            <span>Agrupamento:</span>
                        </div>
                        <div className="column" style={{ width: '100%', marginTop: '15px' }}>
                            <ul>
                                <li className="item">
                                    <span>
                                        <input type="radio" name="reportGroupBy" value="group-by-week" checked={this.state.groupByReport_tab2 === "group-by-week"} onChange={this.onValueChangeGroupByReport_tab2} /> Semana
                                    </span>
                                </li>
                                <li className="item">
                                    <span>
                                        <input type="radio" name="reportGroupBy" value="group-by-month" checked={this.state.groupByReport_tab2 === "group-by-month"} onChange={this.onValueChangeGroupByReport_tab2} /> Mês
                                    </span>
                                </li>
                                <li className="item">
                                    <span>
                                        <input type="radio" name="reportGroupBy" value="group-by-year" checked={this.state.groupByReport_tab2 === "group-by-year"} onChange={this.onValueChangeGroupByReport_tab2} /> Ano
                                    </span>
                                </li>
                            </ul>
                        </div>
                        
                        <div className="column" style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                            <span>Seller:</span>
                        </div>
                        <div className="column" style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '15px' }}>
                            <InputAutocomplete
                                disabled={false}
                                setValue={this.setValueSellerSelected_tab2}
                                searchSellerByTrandingName={this.searchSellerByTrandingName_tab2}
                                sellerList={this.state.sellerListSearch_tab2}
                            />
                        </div>

                        <div className="column" style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                            <ActionButton
                                onClickAction={this.handleOnClickFilterByPeriod}
                                id="btnFilter"
                                text="Filtrar"                                
                                icon="icon-search"
                                componentClass="btn btn-action" />
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <ActionButton
                                onClickAction={this.handleOnClickDownloadFileByPeriod}
                                id="btnExportCsv"
                                text="Exportar XLS"
                                icon="icon-search"
                                componentClass="btn btn-action" />                            
                        </div>
                    </div>
                    <div className="container2" style={{ marginTop: '10px' }}>
                        <div className="column" style={{ width: '100%', fontWeight: 700 }}>
                            <Dynamic
                                tabs={this.state.tabs_tab2}
                                panels={this.state.panels_tab2}
                                selectedTab={this.state.selectedTab_tab2}
                            />
                        </div>
                    </div>
                </div>
        }
        return itemsByPeriodPanel
    }

    createPendents = (tabIndex) => {
        const pendentsPanel = {
            value: { tabIndex },
            child: () => <div>
                <div className="column" style={{ width: '100%' }}>
                    <span>TODO: Pendentes de Produção</span>
                </div>
            </div>
        }
        return pendentsPanel
    }

    render() {
        const hasRoleViewTopSellers = CredentialManager.userHasRole('ROLE_VIEW_TOP_SELLERS')

        if (!hasRoleViewTopSellers) {
            return <Redirect to="/error" />
        }

        return (
            <Fragment>
                <div className="container">
                    <div className="column" style={{ width: '100%' }}>
                        <h2>Catalogação de Produtos</h2>
                    </div>
                    <div className="column" style={{ width: '100%', fontWeight: 700, marginTop: '30px' }}>
                        <Dynamic
                            tabs={this.state.mainTabs}
                            panels={this.state.mainPanels}
                            selectedTab={0}
                            externalHandleChange={this.handleChangeTabs}
                        />
                    </div>
                </div>
                <section className="header-message-container">
                    <Closable
                        className={this.state.colorMessage || ''}
                        isOpen={this.state.showPartnerMessage || false}
                        onClose={this.closePartnerMessage}
                        showCloseButton
                        autoCloseTime={5000}
                    >
                        <span className="commission-partner-save-success">
                            <i className="icon-ok-check" />
                            {this.state.message}
                        </span>
                    </Closable>
                </section>

                {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon} /></div></div> : ''}
            </Fragment>
        )
    }

    searchSellerByTrandingName = (trandingName) => {
        TopSellersController.getSellerList(trandingName)
            .then((result) => {
                this.setState({
                    sellerListSearch: result
                })
            })
            .catch((error) => {
                this.setState({
                    showPartnerMessage: true,
                    message: `Erro ao buscar o Seller: ${error}`,
                    colorMessage: 'header-message header-error-message'
                });
            });
    }

    searchSellerByTrandingName_tab2 = (trandingName) => {
        TopSellersController.getSellerList(trandingName)
            .then((result) => {
                this.setState({
                    sellerListSearch_tab2: result
                })
            })
            .catch((error) => {
                this.setState({
                    showPartnerMessage: true,
                    message: `Erro ao buscar o Seller: ${error}`,
                    colorMessage: 'header-message header-error-message'
                });
            });
    }

    setValueSellerSelected = (value) => {
        this.setState({
            seller: value
        }, () => { console.log(this.state.seller) })
    }

    setValueSellerSelected_tab2 = (value) => {
        this.setState({
            seller_tab2: value
        })
    }

    componentDidMount() {
        this.createMainTabList().then((data) => this.setState({
            mainTabs: data
        }, () => {
            this.createMainPanelList().then((data) => this.setState({
                mainPanels: data
            }, () => {
                console.log("mainTabs: ", this.state.mainTabs)
                console.log("mainPanels: ", this.state.mainPanels)
            }))
        }))
    }

}

export default TopSellers