import i18n from 'i18next'

/** locales */
import ptbrLocale from './locales/ptbr'

i18n.init({
  // we init with resources
  resources: {
    ptbr: ptbrLocale
  },
  fallbackLng: 'ptbr',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
})

export default i18n
