import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CategoryAux from '../../components/customize-categories/CategoryAux'

import { bindAll } from 'lodash'

import CategoryController from '../../controllers/CategoryController'
import GenerateTreeList from '../../services/GenerateTreeList'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import Closable from '../../components/closable/Closable'

const CategoryList = require('../../resources/json/VtexCategories.json')
const Firestore = require('../../resources/json/firestore.json')


/** Tab styles */
const styles = () => ({
  root: {
    flexGrow: 1
  },
  tabsRoot: {
    color: '#156992',
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#156992'
  },
  tabRoot: {},
  label: {
    fontSize: '18px',
    fontWeight: '400',
    fontFamily: [
      'RobotoCondensed'
    ].join(',')
  }
})

class Category extends Component {
  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      categoriesFirestore: [],
      treeList: [],
      loader : false,
      showPartnerMessage: false,
      message: '',
      colorMessage: ''
    }

    bindAll(this, ['processTreeList','closePartnerMessage']);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  closePartnerMessage() {
    this.setState({ showPartnerMessage: false })
  }

  processTreeList = (categories, categoriesFirestore) => new Promise((resolve, reject) => {
    this.setState({
     treeList: GenerateTreeList.getTreeList(categories, categoriesFirestore)
    });

    resolve();
  });

  componentWillMount() {
    this.setState({ loader : true });

    CategoryController.getCategories()
      .then(data => {
        this.setState({
          categories: data
        });

        CategoryController.getCategoriesFirestore()
        .then(data => {
          this.setState({
            categoriesFirestore: data
          });

          this.processTreeList(this.state.categories, this.state.categoriesFirestore)
            .then(() => {
              this.setState({
                loader : false 
              });
            })
            .catch((error) => {
              console.log(error);
              this.setState({ 
                loader : false,
                showPartnerMessage: true, 
                message: 'Erro ao processar árvore de categorias.',
                colorMessage: 'header-message header-error-message'
              });
            })
        })
        .catch(err => {
          console.log(err)
          this.setState({ 
            loader : false,
            showPartnerMessage: true, 
            message: 'Erro ao obter as categorias da base De/Para.',
            colorMessage: 'header-message header-error-message'
          });
        });
      })
      .catch(err => {
        console.log(err)
        this.setState({ 
          loader : false,
          showPartnerMessage: true, 
          message: 'Erro ao obter as categorias da Vtex.',
          colorMessage: 'header-message header-error-message'
        });
      });
  }

  render() {
    const { loader, treeList } = this.state;

    return (
      <Fragment>

        <div className="content payment-reports">
          <CategoryAux treeList={treeList} />

          {loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </div>

        {/* Criar componente de mensagem */}

        <section className="header-message-container">
          <Closable
            className={this.state.colorMessage || ''}
            isOpen={this.state.showPartnerMessage || false}
            onClose={this.closePartnerMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="commission-partner-save-success">
              <i className="icon-ok-check" />
              {this.state.message}
            </span>
          </Closable>
        </section>
      </Fragment>
    )
  }
}

export default withStyles(styles)(Category)
