import React from 'react'
import PropTypes from 'prop-types'
import { CustomDialog, CustomDialogFooter, CustomDialogHeader } from '../../components/commons/dialog/CustomDialog'
import i18n from '../../utils/i18n/i18n'
import './ChartDialog.css'
import Chart from "react-google-charts";

/**
 * **************************************************************************************
 * Renders ChartDialog - <ChartDialog>
 * **************************************************************************************
 */
const ChartDialog = (props) => {

  const {
    onClose, open
  } = props

  const header = (
    <CustomDialogHeader
      id="chart-description-dialog-header"
      title={i18n.t(props.title)}
      icon="icon-common-file-text-search"
      onClose={onClose}
    />
  )
  const buttom = [{
    class: 'close-button',
    name: i18n.t('Close'),
    action: onClose,
    key: Math.random().toString()
  }]

  const footer = <CustomDialogFooter id="billing-adjustment-description-dialog-footer" buttons={buttom} />

  console.log(`Opções Chart: ${JSON.stringify(props.options)}`)
  console.log(`Formatter in the Chart: ${JSON.stringify(props.formatters)}`)
  return (
    <CustomDialog maxWidth={false} id="chart-description-dialog" header={header} footer={footer} open={open} onClose={onClose}>
      <div>
      <Chart
        width={'800px'}
        height={'360px'}
        chartType="Line"
        loader={<div>Loading...</div>}
        data={props.data}
        options={props.options}
        formatters={props.formatters}
      />
      </div>
    </CustomDialog>
  )
}
ChartDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}
export default ChartDialog