import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom'
import { format, getDate, getMonth, getYear, getHours, getMinutes, sub, parse, differenceInDays } from 'date-fns'
import Closable from '../../components/closable/Closable'
import ActionButton from '../../components/buttons/ActionButton'
import InputDatePicker from '../../components/form-components/input-date-picker/inputDatePicker'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'
import InputText from '../../components/form-components/InputText'
import NotificationGrid from './NotificationGrid';
import SellerController from '../../controllers/SellerController'
import NotificationController from '../../controllers/NotificationController'
import { CredentialManager } from '../../commons/CredentialManager'
import { bindAll } from 'lodash'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import CustomDialog from '../../components/commons/dialog/CustomDialog'
import InputTextArea from '../../components/form-components/input-text-area/InputTextArea'
import './Notification.css'
import DialogAlert from '../offers/DialogAlert/DialogAlert'
import { red } from '@material-ui/core/colors';

class Notification extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      sellerFilterListSelected: undefined,
      sellerEditSelected: undefined,
      showPartnerMessage: false,
      message: '',
      colorMessage: '',
      sellerFilterList: [],
      sellerEditList: [],
      fromDate: new Date(),
      fromTime: new Date(new Date().getYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0),
      toDate: new Date(),
      toTime: new Date(),
      fromDateTime: this.formatDateToISO(sub(new Date(),{ days: 30}), new Date()),
      toDateTime: this.formatDateToISO(new Date(), new Date()),
      gridApi: undefined,
      productList: [],
      inputTitle: '',
      deleteNotificationOpened: false,
      deleteNotificationGroupOpened: false,
      visualizarEditarOpened: false,
      editInputTitle: '',
      editInputMessage: '',
      editInputCallToAction: '',
      createdAt: '',
      deliveredAt: '',
      readedAt: '',
      clickedAt:'',
      notificationId: undefined,
    };

    bindAll(this, ['setValueFilterListSellerSelected',
                   'setValueEditSellerSelected',
                   'searchFilterSellerByTrandingName',
                   'searchEditSellerByTrandingName',
                   'onGridReady',
                   'fillGridGeralFilter',
                   'closePartnerMessage',
                   'handleChangeTitle',
                   'handleOnIncludeNotificationClick',
                   'handleOnClickEditarNotificacao'
                  ]);
  }

  handleDeleteNotificationClick = () => {
    if (!this.validChoice())
      return
      this.setState({
        deleteNotificationOpened: true
      })
  }
  handleDeleteNotificationGroupClick = () => {
    if (!this.validChoice())
      return
      this.setState({
        deleteNotificationGroupOpened: true
      })
  }
  validChoice = () => {
    if(this.state.gridApi.getSelectedRows().length == 0 ){
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `É obrigatório selecionar algum registro.`,
        colorMessage: 'header-message header-error-message'
      });
      return false;
    }
    return true;
  }
  validVisualizarEditarChoice = () => {
    if(this.state.gridApi.getSelectedRows().length == 0 || this.state.gridApi.getSelectedRows().length > 1 ){
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `É obrigatório selecionar 1 registro para esta ação.`,
        colorMessage: 'header-message header-error-message'
      });
      return false;
    }
    return true;
  }


  handleCancelDeleteNotificationClick = () => {
    this.setState({
      deleteNotificationOpened: false,
    })
  }
  handleCancelDeleteNotificationGroupClick = () => {
    this.setState({
      deleteNotificationGroupOpened: false,
    })
  }

  closePartnerMessage = () => {
    this.setState({ showPartnerMessage: false })
  }

  setValueFilterListSellerSelected = (value) => {
    this.setState({
      sellerFilterListSelected: value
    }, () => {console.log(this.state.sellerFilterListSelected)});
  }

  setValueEditSellerSelected = (value) => {
    this.setState({
      sellerEditSelected: value
    }, () => {
      console.log( this.state.sellerEditSelected.ecommerceId)});
  }

  searchFilterSellerByTrandingName = (trandingName) => {
    SellerController.getSellerList(trandingName)
      .then((result) => {
        this.setState({
          sellerFilterList: result
        })
      })
      .catch((error) => {
        this.setState({
          showPartnerMessage: true, 
          message: `Erro ao buscar o Seller: ${error}`,
          colorMessage: 'header-message header-error-message'
        });
      });
  }

  searchEditSellerByTrandingName = (trandingName) => {
    SellerController.getSellerList(trandingName)
      .then((result) => {
        this.setState({
          sellerEditList: result
        })
      })
      .catch((error) => {
        this.setState({
          showPartnerMessage: true, 
          message: `Erro ao buscar o Seller: ${error}`,
          colorMessage: 'header-message header-error-message'
        });
      });
  }


  formatDateToISO = (date, time) => {
    let dateTime;
    
    if (date && time) {
      let day = getDate(date)
      let month = getMonth(date)
      let year = getYear(date)
      let hours = getHours(time)
      let minutes = getMinutes(time)
      dateTime = new Date(year, month, day, hours, minutes, 0)
    } else {
      return this.formatDateToISO(new Date(), new Date());
    }

    return format(dateTime, 'yyyy-MM-dd HH:mm:ss')
  }

  handleChangeFromDate = (value, e) => {
    this.setState({ 
      fromDate: value,
      fromDateTime: this.formatDateToISO(value, this.state.fromTime),
      buttonChartDisable: true
    }, () => console.log(`Time From: ${this.state.fromTime}`)); 
  }

  handleChangeFromTime = (value, e) => {
    this.setState({ 
      fromTime: value, 
      fromDateTime: this.formatDateToISO(this.state.fromDate, value),
      buttonChartDisable: true
    });
  }

  handleChangeToDate = (value, e) => {
    this.setState({ 
      toDate: value, 
      toDateTime: this.formatDateToISO(value, this.state.toTime),
      buttonChartDisable: true,
      diffBetweenDays: differenceInDays(parse(this.state.toDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()), parse(this.state.fromDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()))
    });
  }

  handleChangeToTime = (value, e) => {
    this.setState({ 
      toTime: value, 
      toDateTime: this.formatDateToISO(this.state.toDate, value),
      buttonChartDisable: true      
    });
  }

  fillGridGeralFilter = () => {
    this.setState({ loader: true });

    if (!this.validFilter())
      return

    var fromDate = this.formatDateToISO(this.state.fromDate, this.state.fromTime);
    var toDate = this.formatDateToISO(this.state.toDate, this.state.toTime);
    
    let sellerListSelected = [];
    if (this.state.sellerFilterListSelected != '' && this.state.sellerFilterListSelected != undefined ) {   
      let list = this.state.sellerFilterListSelected; 
      list.map((item) => {
        sellerListSelected.push(item.ecommerceId);
      })
    }

    NotificationController.getNotificationBySellerId(this.state.inputTitle,sellerListSelected, fromDate, toDate)
      .then((result) => {
        console.log(result);
        this.setState({           
          loader: false,
        }, () => { 
          this.state.gridApi.setRowData(result); 
          this.refreshGrid();
        });      
      }).catch((error) => {
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Erro ao tentar recuperar a lista de notificações conforme o filtro selecionado.`,
          colorMessage: 'header-message header-error-message'
        });
      })
  }
  handleOnIncludeNotificationClick = () => {
    window.location.replace( 'includenotification');
}

validFilter = () => {
    if (!this.state.fromDate || !this.state.toDate) {
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `É obrigatório os campos de Data.`,
          colorMessage: 'header-message header-error-message'
        });
        return false;
    }
    return true;
  }
  validEdit = () => {
    if (this.state.editInputTitle == '' || this.state.editInputTitle == undefined ) { 
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Você deve preencher o campo Título.`,
        colorMessage: 'header-message header-error-message'
      });
      return false; 
    }
    if (this.state.editInputMessage == '' || this.state.editInputMessage == undefined ) { 
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Você deve preencher o campo Mensagem.`,
        colorMessage: 'header-message header-error-message'
      });
      return false; 
    }
    /*if (this.state.sellerEditList == '' || this.state.sellerEditList == undefined ) { 
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Você deve preencher o campo Seller.`,
        colorMessage: 'header-message header-error-message'
      });
      return false; 
    }*/
    return true;
  }

  handleOnClickExcluirNotification =() => {
    let list = this.state.gridApi.getSelectedRows();
    let listNotification = [];
    list.map((item) => {
      listNotification.push(item._id);
    })

    this.setState({ loader: true });

    listNotification.forEach((item) => { 

      NotificationController.deleteNotification(item)
      .then((result) => {
        this.setState({ 
          productList: [],
          loader: false,
        }, () => { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Notificações excluídas!`,
            colorMessage: 'header-message header-success-message',
            deleteNotificationOpened: false
          });
          this.handleOnClickPesquisar();
          this.refreshGrid();
        });      
      }).catch((error) => {
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Erro ao tentar excluir as notificações.`,
          colorMessage: 'header-message header-error-message'
        });
      })
    }) 
  }

  handleOnClickExcluirNotificationGroup =() => {
    let list = this.state.gridApi.getSelectedRows();
    let listNotification = [];
    list.map((item) => {
      listNotification.push(item.group_id);
    })

    this.setState({ loader: true });

    listNotification.forEach((item) => { 

      NotificationController.deleteNotificationGroup(item)
      .then((result) => {
        this.setState({ 
          productList: [],
          loader: false,
        }, () => { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Notificações excluídas!`,
            colorMessage: 'header-message header-success-message',
            deleteNotificationGroupOpened: false
          });
          //this.state.gridApi.setRowData([]); 
          this.handleOnClickPesquisar();
          this.refreshGrid();
        });      
      }).catch((error) => {
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Erro ao tentar excluir as notificações.`,
          colorMessage: 'header-message header-error-message'
        });
      })
    }) 
  }

  onGridReady = params => {
    this.setState({ gridApi: params.api })
  }

  refreshGrid = () => {
    var params = {
      force: true,
      suppressFlash: true,
    };

    this.state.gridApi.refreshCells(params);
  }

  handleOnClickPesquisar = () => {
    this.fillGridGeralFilter();
  }

  handleChangeTitle = (event) => {
    const { value } = event.target

    this.setState({
      inputTitle: value
    })
}
handleOnClickVisualizarEditar = () => {
        
  if (!this.state.visualizarEditarOpened){            
      this.getNotificationById()
  }
}

getNotificationById = () => {

  if (!this.validVisualizarEditarChoice()){
    return
  }
  
  let list = this.state.gridApi.getSelectedRows();
  let listNotification = [];
  list.map((item) => {
    listNotification.push(item._id);
  })

  this.setState({ loader: true, notificationId: listNotification[0]});
        
  NotificationController.getNotificationById(listNotification[0]).then((result) => {
    if (result.status === 'Error') {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `${result.message}`,
        colorMessage: 'header-message header-error-message'
      });
    } else {
      let createdAt  = new Date(result.createdAt);
      let delivered_at  = new Date(result.delivered_at);
      let readed_at  = new Date(result.readed_at);
      let clicked_at  = new Date(result.clicked_at);
      this.setState({              
        editInputTitle: result.title,
        editInputMessage: result.message,
        editInputCallToAction: result.cta,
        createdAt: result.createdAt != null ?  format(createdAt, 'dd/MM/yyyy') : '',
        deliveredAt: result.delivered_at != null ?  format(delivered_at, 'dd/MM/yyyy') : '',
        readedAt: result.readed_at != null ?  format(readed_at, 'dd/MM/yyyy') : '',
        clickedAt: result.clicked_at != null ?  format(clicked_at, 'dd/MM/yyyy') : '',
        visualizarEditarOpened: !this.state.visualizarEditarOpened,
        loader: false
      });
      let ecommerceId = this.state.seller != undefined && this.state.seller.ecommerceId != undefined ? this.state.seller.ecommerceId : null;
    }
  })
  .catch((error) => {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Erro eo tentar buscar a notificação.`,
        colorMessage: 'header-message header-error-message'
      });
  })        
}

handleOnClickEditarNotificacao = (event) => {
  this.setState({ loader: true });

  if (this.validEdit()) {
      let editInputTitle = this.state.editInputTitle ? this.state.editInputTitle : undefined;
      let editInputMessage = this.state.editInputMessage ? this.state.editInputMessage : undefined;
      let editInputCallToAction = this.state.editInputCallToAction ? this.state.editInputCallToAction : undefined;
      let sellerId = this.state.sellerEditSelected != undefined && this.state.sellerEditSelected.ecommerceId != undefined ? this.state.sellerEditSelected.ecommerceId : null;

    NotificationController.editNotification(this.state.notificationId, editInputTitle, editInputMessage, editInputCallToAction, sellerId)
      .then((result) => {
        if (result.status === 'Error') {
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `${result.message}`,
            colorMessage: 'header-message header-error-message',
            visualizarEditarOpened: false
          });
        } else {
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Notificação alterada com sucesso.`,
            colorMessage: 'header-message header-success-message',
            visualizarEditarOpened: false
          });
          this.handleOnClickPesquisar();
          this.refreshGrid();
        }
      })
      .catch((error) => {
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Erro eo tentar alterar a notificação.`,
            colorMessage: 'header-message header-error-message',
            visualizarEditarOpened: false
          });
      })
  } 
}
handleChangeEditTitle = (event) => {
  const { value } = event.target

  this.setState({
    editInputTitle: value
  })
}


handleChangeEditMessage = (event, value) => {
  this.setState({
    editInputMessage: value
  })
}
handleChangeEditCallToAction = (event) => {
  const { value } = event.target

  this.setState({
    editInputCallToAction: value
  })
}

handleOnCancelEditNotification = () => {
  this.setState({
      loader: false,
      visualizarEditarOpened: false
  })
}



  render () {
    

    const textAlertExcluirNotificao = (
      <>
          <h2 style={{fontWeight: "bold"}}>Esta ação é irreversível!</h2><br/>
          <h2 style={{fontWeight: "bold"}}>Existem { this.state.gridApi != undefined ? this.state.gridApi.getSelectedRows().length : 0 } notificação(ões) que está(ão) selecionadas para exclusão, tem certeza que deseja seguir? </h2>
      </>
    )

    const textAlertExcluirNotificaoEmGrupo = (
      <>
          <h2 style={{fontWeight: "bold"}}>Esta ação é irreversível!</h2><br/>
          <h2 style={{fontWeight: "bold"}}>Existem { this.state.gridApi != undefined ? this.state.gridApi.getSelectedRows().length : 0 } notificação(ões) que está(ão) selecionadas para exclusão em Grupo de Sellers, tem certeza que deseja seguir? </h2>
      </>
    )

    const hasRoleAdmNotification = CredentialManager.userHasRole('ROLE_VIEW_PRODUCT_LOCK')
    
    if (!hasRoleAdmNotification) {
      return <Redirect to="/error" />
    }

    return (
      <Fragment>
        <div className='container'>
          <div className='column' style={{width: '85%'}}> 
            <h2>Central de Notificações</h2>
          </div>
          <div className="column" style={{ width: '15%'}}>
              <ActionButton
                  onClickAction={this.handleOnIncludeNotificationClick}
                  id="btnEditLock"
                  text="Incluir Notificação"
                  icon="icon-edit"
                  componentClass="btn btn-action" />
          </div>
          <div className="column" style={{width: '100%'}}>
            Data criação 
          </div>
          <div className="column" style={{width: '15%',  marginTop: '5px'}}>
            De: <InputDatePicker startDate={this.state.fromDate} name="fromDate" handleChange={(value, e) => this.handleChangeFromDate(value, e)} />
          </div>

          <div className="column" style={{width: '85%', marginTop: '5px'}}>
          Até: <InputDatePicker startDate={this.state.toDate} name="toDate" handleChange={(value, e) => this.handleChangeToDate(value, e)} />
          </div>
          
          <div className="column" style={{width: '40%', marginTop: '30px'}}>
              Seller:
          </div>
          <div className="column" style={{width: '60%', marginTop: '30px'}}>
              Título:
          </div>
          
          <div className="column" style={{width: '40%', marginTop: '10px'}}>
            <InputAutocomplete 
              setValue={this.setValueFilterListSellerSelected} 
              searchSellerByTrandingName={this.searchFilterSellerByTrandingName} 
              sellerList={this.state.sellerFilterList}
              width={500} 
              multiple={true}
            />
          </div>
          <div className='column' style={{width: '60%', marginTop: '5px'}}>
          <InputText
                        id="inputTitle"
                        value={this.state.inputTitle}
                        placeholder={'Digite aqui o Título'}
                        maxLength={30}
                        onChange={this.handleChangeTitle}
                        style={{width: '30%'}}
                    />
          </div>

         

          
          <div className="column" style={{width: '10%', marginTop: '30px'}}>
            <ActionButton 
              onClickAction={this.handleOnClickPesquisar} 
              id="btnPesquisar" 
              text="Pesquisar"
              componentClass="btn btn-action" />
          </div>
        </div>
        <div className="container">
        <div className="column" style={{width: '83%', marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
            <ActionButton 
              onClickAction={this.handleOnClickVisualizarEditar} 
              id="btnVisualizarEditar" 
              text="Visualizar"
              componentClass="btn btn-action"
              />
          </div>
          <div className="column" style={{width: '7%', marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
            <ActionButton 
              onClickAction={this.handleDeleteNotificationClick}
              id="btnExcluir" 
              text="Excluir"
              componentClass="btn btn-action"
              />
          </div>
          <div className="column" style={{width: '10%', marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
            <ActionButton 
              onClickAction={this.handleDeleteNotificationGroupClick} 
              id="btnRejeitar" 
              text="Excluir Grupo"
              componentClass="btn btn-action"
              />
          </div>

          <NotificationGrid  
            height={450} 
            width="100%" 
            ProductList={this.state.productList} 
            onGridReady={this.onGridReady}
          />
        </div>
        <CustomDialog maxWidth='1000px' title='Visualizar' open= {this.state.visualizarEditarOpened} id="visualizarEditarNotificationClick">
            <div className="column" style={{ width: '100%' }}>
                 <h2 style={{fontWeight: "bold"}}>Visualizar Notificação</h2>
             </div>
             <div className="container">
                <div className="column" style={{ width: '100%' }}>
                  <span>Título</span>
                </div>                                                               
                <div className="column" style={{ width: '100%' }}>
                  <InputText
                        id="editInputTitle"
                        value={this.state.editInputTitle}
                        placeholder={'Digite aqui o Título'}
                        maxLength={45}
                        readOnly={true}
                        onChange={this.handleChangeEditTitle}
                    />
                </div>
                <div className="column" style={{ width: '100%' }}>
                 <span>Mensagem</span>
                </div>                                                               
                <div className="column" style={{ width: '100%' }}>
                  <InputTextArea
                        id="editInputMessage"
                        value={this.state.editInputMessage}
                        onChangeValue={this.handleChangeEditMessage}
                        readOnly={true}
                        maxLength={220}
                    />
                </div>
                <div className="column" style={{ width: '100%' }}>
                  <span>Call to Action - Link para Ação:</span>
                </div>                                                               
                <div className="column" style={{ width: '100%' }}>
                  <InputText
                        id="editInputCallToAction"
                        value={this.state.editInputCallToAction}
                        placeholder={'Digite aqui o link'}
                        onChange={this.handleChangeEditCallToAction}
                        readOnly={true}
                    />
                </div>
                <div className="column" style={{ width: '100%' }}>
                    <span>Para:</span>
                </div> 
                {/*<div className="column" style={{ width: '100%' }}>
                    <InputAutocomplete 
                      setValue={this.setValueEditSellerSelected} 
                      searchSellerByTrandingName={this.searchEditSellerByTrandingName} 
                      sellerList={this.state.sellerEditList}
                      width={350} 
                      disabled={true}
                    />
                </div>*/}
                
                <div className="column" style={{ width: '25%' }}>
                    <span>Criado em:</span>
                </div>     
                <div className="column" style={{ width: '25%' }}>
                    <span>Recebido em:</span>
                </div>     
                <div className="column" style={{ width: '25%' }}>
                    <span>Lido em:</span>
                </div>     
                <div className="column" style={{ width: '25%' }}>
                    <span>Interação em:</span>
                </div>
                <div className="column" style={{ width: '25%'}}>
                  <span>{this.state.createdAt}</span>
                </div>
                <div className="column" style={{ width: '25%' }}>
                  <span>{this.state.deliveredAt}</span>
                </div>
                <div className="column" style={{ width: '25%' }}>
                    <span>{this.state.readedAt}</span>
                </div>
                <div className="column" style={{ width: '25%' }}>                 
                    <span>{this.state.clickedAt}</span>
                </div>
                {/*<div className="column" style={{width: '30%' , display: 'flex', marginTop: '50px', justifyContent: 'space-between', alignItems: 'center', height: '30px'}}>   
                        <ActionButton 
                            onClickAction={this.handleOnClickEditarNotificacao} 
                            id="btnEditNotification" 
                            text="Salvar Notificação"
                            componentClass="btn btn-action" />  
                  </div>
                */}
                  <div className="column" style={{width: '100%', display: 'flex', marginTop: '50px', justifyContent: 'space-between', alignItems: 'center', height: '30px'}}>                                                     
                        <ActionButton
                            onClickAction={this.handleOnCancelEditNotification}
                            id="btnCancelEditNotification"
                            text="Fechar"
                            componentClass="btn btn-default" />

                        
                  </div>
              </div>
          </CustomDialog>

          <DialogAlert 
                    title='Excluir notificações'
                    text={textAlertExcluirNotificao}
                    icon='icon-warning'
                    isOpen={this.state.deleteNotificationOpened}
                    actionYes={this.handleOnClickExcluirNotification}
                    actionNo={this.handleCancelDeleteNotificationClick}
                />

          <DialogAlert 
                    title='Excluir Notificações em Grupo'
                    text={textAlertExcluirNotificaoEmGrupo}
                    icon='icon-warning'
                    isOpen={this.state.deleteNotificationGroupOpened}
                    actionYes={this.handleOnClickExcluirNotificationGroup}
                    actionNo={this.handleCancelDeleteNotificationGroupClick}
                />

        <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
      </Fragment>
    );
  }
}

export default Notification;
