import React, { Component, Fragment } from 'react'

import { Redirect } from 'react-router-dom'

import Closable from '../../components/closable/Closable'

import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'

import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import InputText from '../../components/form-components/InputText'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'

import OfferController from '../../controllers/OfferController'
import SellerController from '../../controllers/SellerController'

import ChipInput from 'material-ui-chip-input'

import './ReprocessOffers.css'

class ReprocessOffers extends Component {
    constructor(props) {
      super(props)
  
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        loader: false,
        sellerList: [],
        seller: undefined,
        priceStockList: [],
        disableExport: true,
        rmsList: []
      }

      bindAll(this, [
                     'closePartnerMessage',
                     'handleOnClickFilter',
                     'setValueSellerSelected',
                     'searchSellerByTrandingName',
                     'handleAddChip',
                     'handleDeleteChip'
                    ]);
    }

    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }
  
    setValueSellerSelected = (value) => {
      this.setState({
        seller: value
      }, () => {console.log(this.state.seller)});
    }

    handleOnClickFilter = (event) => {
        this.setState({ loader: true });

        let rms = this.state.rmsList.length > 0 ? this.state.rmsList : undefined;
        let seller = this.state.seller ? this.state.seller : undefined;

        if (this.formValidate()) {
          OfferController.executeReprocessOffers(rms, seller)
            .then((result) => {
              if (result.status === 'Error') {
                this.setState({
                  loader: false,
                  showPartnerMessage: true, 
                  message: `${result.message}`,
                  colorMessage: 'header-message header-error-message'
                });
              } else {
                this.setState({
                  loader: false,
                  showPartnerMessage: true, 
                  message: `Oferta(s) enviada(s) para reprocessamento.`,
                  colorMessage: 'header-message header-success-message'
                });
              }
            })
            .catch((error) => {
                this.setState({
                  loader: false,
                  showPartnerMessage: true, 
                  message: `Erro eo tentar executar o reprocessamento.`,
                  colorMessage: 'header-message header-error-message'
                });
            })
        }
    }
    
    formValidate = () => { 
        if (this.state.rmsList.length <= 0 && !this.state.seller) { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Você deve preencher ao menos 1 dos campos para o processamento.`,
            colorMessage: 'header-message header-error-message'
          });

          return false; 
        }

        //Validar se os RMS não passaram de 100...
        if (this.state.rmsList.length > 100) {
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Não é permitido adicionar mais de 100 RMS para reprocessamento.`,
            colorMessage: 'header-message header-error-message'
          });

          return false;
        } 

        return true;
    }

    setValueSellerSelected = (value) => {
      this.setState({
        seller: value
      }, () => {console.log(this.state.seller)});
    }

    searchSellerByTrandingName = (trandingName) => {
      SellerController.getSellerList(trandingName)
        .then((result) => {
          this.setState({
            sellerList: result
          })
        })
        .catch((error) => {
          this.setState({
            showPartnerMessage: true, 
            message: `Erro ao buscar o Seller: ${error}`,
            colorMessage: 'header-message header-error-message'
          });
        });
    }

    handleAddChip = (chip) => {
      let chips = this.state.rmsList;
      let chipsComma;
      let chipsSemiColon;
      let chipsComErro = [];

      if (chip.indexOf(',') > 0) {
        chipsComma = chip.split(',');

        chipsComma.forEach(item => {
          if (this.isCharacterValid(item.trim())) {
            chips.push(item.trim());
          } else {
            chipsComErro.push(item.trim());
          }
        });
      } else if (chip.indexOf(';') > 0) {
        chipsSemiColon = chip.split(';');

        chipsSemiColon.forEach(item => {
          if (this.isCharacterValid(item.trim())) {
            chips.push(item.trim());
          } else {
            chipsComErro.push(item.trim());
          }        
        });
      } else {
        if (this.isCharacterValid(chip.trim())) {
          chips.push(chip.trim());
        } else {
          chipsComErro.push(chip.trim());
        }
      }

      if (chipsComErro.length > 0) {
        this.setState({
          rmsList: chips,
          loader: false,
          showPartnerMessage: true, 
          message: `RMS inválido! Só é permitido letras e números na composição do RMS.`,
          colorMessage: 'header-message header-error-message'
        });
      } else {
        this.setState({ rmsList: chips });
      }
    }

    handleDeleteChip = (chip, index) => {
      let chips = this.arrayRemove(this.state.rmsList, chip);
      this.setState({ rmsList: chips });
    }

    arrayRemove = (arr, value) => { 
      return arr.filter(function(ele){ 
          return ele != value; 
      });
    }

    isCharacterValid = (text) => {
      return /^[a-zA-Z0-9]+$/.test(text);
    }
    
    render() {

      const hasRoleSync = CredentialManager.userHasRole('ROLE_VIEW_OFFER_PRICE')

      if (!hasRoleSync) {
        return <Redirect to="/error" />
      }

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '100%'}}> 
              <h3>Reprocessamento de Ofertas</h3>
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              RMS:({ this.state.rmsList.length })
            </div>
            <div className="column" style={{width: '25%'}}>
            <ChipInput
              id="inputSku"
              value={this.state.rmsList}
              onAdd={(chip) => this.handleAddChip(chip)}
              onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
            />
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Seller:
            </div>
            <div className="column" style={{width: '100%'}}>
              <InputAutocomplete 
                disabled={this.state.disabledAutocomplete}
                setValue={this.setValueSellerSelected} 
                searchSellerByTrandingName={this.searchSellerByTrandingName} 
                sellerList={this.state.sellerList} 
              />
            </div>
            <div className="column" style={{width: '40%'}}></div>
            <div className="column" style={{width: '35%'}}></div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickFilter} 
                id="btnFiltrar" 
                text="Reprocessar"
                icon="icon-search"
                componentClass="btn btn-action" />
            </div>
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default ReprocessOffers