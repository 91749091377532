import { getSellerDiagnosis, getSellerList, getSellerByEcommerceId } from "../commons/Connection";

const SellerDiagnosisController = {
    getSellerList: function(trandingName) {
        return getSellerList("/v1/sellers/search-by-name", trandingName);
    },    
    getSellerDiagnosis: function(tradingName) {        
        return getSellerDiagnosis("/v1/seller-diagnosis/filter-async", tradingName);
    },
    getSellerByEcommerceId: function(ecommerceId) {
        return getSellerByEcommerceId("/v1/sellers/ecommerce-id", ecommerceId);
    },    
    generateSellerReport: function(tradingName) {
        return getSellerDiagnosis("/v1/seller-diagnosis/report-async", tradingName);
    },
    getStatusBySeller: function(tradingName) {
        return getSellerDiagnosis("/v1/seller-diagnosis/get-status-by-seller", tradingName);
    }
};

export default SellerDiagnosisController;