import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import Closable from '../../components/closable/Closable'
import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'
import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import InputText from '../../components/form-components/InputText'
import ManagerHolidayController from '../../controllers/ManagerHolidayController'
import ManagerHolidayGrid from './ManagerHolidayGrid'
import DialogAlert from '../../components/DialogAlert/DialogAlert'
import CustomDialog from '../../components/commons/dialog/CustomDialog'
import InputDatePicker from '../../components/form-components/input-date-picker/inputDatePicker'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/esm/locale';

import './ManagerHoliday.css'
import { border } from '@mui/system'

class ManagerHoliday extends Component {
    constructor(props) {
      super(props)
      
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        loader: false,
        selectedRoles:  [] ,
        mktpListRoles:  [] ,
        inputUsername: '',    
        allUsersList: [],
        gridApi: undefined,
        dialogQuestionRedefine: false,
        dialogEditHoliday: false,
        editHolidayTitle: '',
        dialogQuestionDelete: false,
        userNameSelected: '',        
        yearToFilter: format(new Date().setHours(0,0,0,0),'yyyy',{locale: ptBR}),
        yearFiltered: '',
        holidayDateSelected: '',
        holidayDate: new Date(),
        holidayDescription: '',
        enableDatePicker: false
      }
      
      bindAll(this, [
                     'closePartnerMessage',                     
                     'handleRolesSelected',
                     'handleMktpListRoles'
                    ]);
    }

    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    handleRolesSelected = (roles) => {
      this.setState({ selectedRoles: roles })
    }

    handleMktpListRoles = (roles) => {
      this.setState({ mktpListRoles: roles })
    }

  checkName = (item) => {
    if (item.userName == '' || item.userName == undefined) {
      return false;
    } else if (this.state.inputUsername == '' || this.state.inputUsername == undefined) {
      return true;
    }

    return item.userName.includes(this.state.inputUsername);
  }

  handleChangeUsername = (event) => {
    const { value } = event.target

    let listUserFiltered = this.state.allUsersList.filter(this.checkName)

    console.log(listUserFiltered)
    this.setState({
      inputUsername: value
    }, () => {
      this.state.gridApi.setRowData(listUserFiltered);
      this.refreshGrid();
    })
  }

  handleNoClick = () => {
    this.setState({ dialogQuestionDelete: false });
  }

  handleOnClickDelete = (holidayDate) => {
    this.setState({ loader: true });

    let formatedDate = format(this.state.holidayDate.setHours(0,0,0,0),'dd/MM/yyyy',{locale: ptBR})
    let user = CredentialManager.getAuthenticatedUser().name      
        
    ManagerHolidayController.postAddHoliday(formatedDate, this.state.holidayDescription, user, false)    
    .then((data) => {
      this.setState({
        loader: false,
        showPartnerMessage: true,
        message: `Feriado apagado com sucesso.`,
        colorMessage: 'header-message header-success-message',
        dialogQuestionDelete: false,
      });
    })
    .then(() => {
      this.fillAllUsersGrid(this.state.yearToFilter, true);
    })
    .catch((error) => {
      this.setState({
        loader: false,
        showPartnerMessage: true,
        message: `Erro ao tentar excluir o feriado: ${error}`,
        colorMessage: 'header-message header-error-message',
        dialogQuestionDelete: false,
      });
    })
  }

  handleClickButtonsGrid = {       
    editHoliday: (holidayDate, description) => {
      
      let timeStamp = Date.parse(holidayDate + " 00:00:00 GMT-0300")
      var date = new Date(timeStamp)

      this.setState({ 
        dialogEditHoliday: true,
        editHolidayTitle: 'Editar Feriado',
        holidayDateSelected: this.formatDate(holidayDate, 'dd MMMM yyyy'),
        holidayDate: date,
        holidayDescription: description,
        enableDatePicker: false
      });
    },
    deleteHoliday: (holidayDate) => {

      let timeStamp = Date.parse(holidayDate + " 00:00:00 GMT-0300")
      var date = new Date(timeStamp)

      this.setState({ 
        dialogQuestionDelete: true,
        holidayDateSelected: this.formatDate(holidayDate, 'dd MMMM yyyy'),
        holidayDate: date
      });
    }
  }
    
  formValidate = () => { 
      if (this.state.inputUsername == '' || this.state.inputUsername == undefined ) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve preencher o campo Username.`,
          colorMessage: 'header-message header-error-message'
        });
        return false; 
      }

      if (this.state.selectedRoles.length <= 0) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve adicionar ao menos 1 permissão para o usuário.`,
          colorMessage: 'header-message header-error-message'
        });

        return false; 
      }
      return true;
    }

    onGridReady = (params) => {
        this.setState({ gridApi: params.api })
    }
    
    refreshGrid = () => {
        let params = {
            force: true,
            suppressFlash: true,
        }        
        this.state.gridApi.refreshCells(params);
    }    

    fillAllUsersGrid = (year, active) => {
      this.setState({ loader: true });
      
      ManagerHolidayController.getHolidayList(year, active)
      .then((data) => {
        this.state.gridApi.setRowData(data);
        this.refreshGrid();

        this.setState({
            allUsersList: data,
            loader: false,
            yearFiltered: year
        });
      })
      .catch((error) => {
        this.setState({
            loader: false,
            showPartnerMessage: true,
            message: `Erro ao tentar obter todos os usuários da base de dados: ${error}`,
            colorMessage: 'header-message header-error-message'
        });
      })
    }

    handleOnAddUserClick = () => {
      console.log('Redirecionar para a tela de inclusão!')
      window.location.replace('includeuser')
    }

    handleSaveHolidayClick = () => {
      this.setState({ 
        loader: true, 
        yearToFilter: format(this.state.holidayDate.setHours(0,0,0,0),'yyyy',{locale: ptBR})
      });
      let formatedDate = format(this.state.holidayDate.setHours(0,0,0,0),'dd/MM/yyyy',{locale: ptBR})
      let user = CredentialManager.getAuthenticatedUser().name      

      ManagerHolidayController.postAddHoliday(formatedDate, this.state.holidayDescription, user, true)
      .then((data) => {
        this.setState({
          loader: false,
          showPartnerMessage: true,
          message: `Dados salvos com sucesso.`,
          colorMessage: 'header-message header-success-message',
          dialogEditHoliday: false,
        });
      })
      .then(() => {
        this.fillAllUsersGrid(this.state.yearToFilter, true);
      })
      .catch((error) => {
        this.setState({
          loader: false,
          showPartnerMessage: true,
          message: `Erro ao tentar incluir ou editar o feriado da base de dados: ${error}`,
          colorMessage: 'header-message header-error-message'
        });
      })
    }

    componentDidMount = () => {
      this.fillAllUsersGrid(this.state.yearToFilter, true);
    }
    
    handleOnFilterHoliday = () => {
      this.fillAllUsersGrid(this.state.yearToFilter, true);
    }

    onValueChangeYear = (event) => {      
      this.setState({        
        yearToFilter: event.target.value
      })
    }

    handleOnAddHoliday = () => {

      this.setState({ 
        dialogEditHoliday: true,
        enableDatePicker: true,
        editHolidayTitle: 'Incluir Feriado',
        holidayDate: new Date(),
        holidayDescription: ''
      });

    }

    handleOnCancelAddAdjustmentClick = () => {
      this.setState({        
        dialogEditHoliday: false,
        enableDatePicker: false
      })      
    }

    handleOnSaveAddAdjustmentClick = () => {
      this.setState({        
        dialogEditHoliday: false
      })      
    }

    handleChangeHolidayDate = (value, e) => {
      this.setState({
        holidayDate: value
      })
    }

    handleChangeDescription = (event) => {
      const { value } = event.target
  
      this.setState({
        holidayDescription: value
      })
    }

    formatDate = (data, template) => {
      let newDate = parseISO(data);
      let dateReturn = data.seconds;

      try {
          dateReturn = format(newDate, template,{locale: ptBR});
      } catch (e) {
          console.log(`Erro ao converter a coluna dateHoliday: ${e}`);
      }      
      console.log('dateReturn:' + dateReturn)
      return dateReturn;
    }

    render() {

      const hasRoleSync = CredentialManager.userHasRole('ROLE_ADMIN')

      const textAlertDeleteHoliday = (
        <>
            <h2 style={{fontWeight: "bold"}}>Tem certeza que deseja excluir o feriado: {this.state.holidayDateSelected} ? </h2>
        </>
      )      

      if (!hasRoleSync) {
        return <Redirect to="/error" />
      }

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '100%'}}> 
              <h2>Cadastro de Feriados</h2>
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              <span>Filtrar por ano: </span>
              <input type="number" name="filtroAno" value={this.state.yearToFilter} min="2021" max="2099" onChange={this.onValueChangeYear} style={{fontSize: '20px'}}/>
            </div>            
            <div className="column" style={{ width: '10%', textAlign: 'left', marginTop: '30px'}}>
            <ActionButton
                      onClickAction={this.handleOnFilterHoliday}
                      id="btnAddUser"
                      text="Filtrar"
                      icon="icon-search"
                      componentClass="btn btn-action" />
            </div>
            <div className="column" style={{ width: '90%', textAlign: 'left', marginTop: '30px'}}>
              <ActionButton
                      onClickAction={this.handleOnAddHoliday}
                      id="btnAddUser"
                      text="Incluir Feriado"
                      icon="icon-add"
                      componentClass="btn btn-action" />
            </div>  
          </div>
          <div className="container">
            <div className="column" style={{width: '100%', marginBottom:`20px`}}> 
              <h3>&nbsp;{this.state.yearFiltered}</h3>
            </div>
            <ManagerHolidayGrid
                    height={450} 
                    width="100%"                         
                    onGridReady={this.onGridReady}
                    billingAdjustmentList={this.state.billingAdjustmentList}
                    handleClickButtonsGrid={this.handleClickButtonsGrid} />
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
               
          <CustomDialog                    
                    title={this.state.editHolidayTitle}
                    open={this.state.dialogEditHoliday}
                    id="editHoliday"
                >
            <h2>{this.state.editHolidayTitle}</h2>
            <div className="container">            
              <div className="column" style={{ width: '49%', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{ fontSize: '18px'}}>Data:</span>
              
                <InputDatePicker 
                      startDate={this.state.holidayDate}
                      name="holidayDate"                    
                      handleChange={(value, e) => this.handleChangeHolidayDate(value, e)}
                      disabled={!this.state.enableDatePicker}                      
                      />
              </div>
              <div className="column" style={{ width: '51%'}}></div>
              <div className="column" style={{ width: '60%', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{ fontSize: '18px'}}>Descrição:</span>
                <InputText
                          id="inputHolidayDescription"
                          value={this.state.holidayDescription}
                          placeholder={'Descrição do Feriado'}
                          maxLength={250}
                          width={500}
                          onChange={this.handleChangeDescription}                        
                      />
              </div>
              <div className="column" style={{ width: '40%'}}></div>
              </div>
              <div className="column" style={{
                          width: '38%',
                          marginTop: '10px',
                          display: 'flex',
                          justifyContent: 'space-between'                          
              }}>            
                <ActionButton
                    onClickAction={this.handleOnCancelAddAdjustmentClick}
                    id="btnCancelAddAdjustment"
                    text="Cancelar"
                    componentClass="btn btn-default" 
                    />

                <ActionButton
                    onClickAction={this.handleSaveHolidayClick}
                    id="btnSaveAddAdjustment"
                    text="Salvar"
                    componentClass="btn btn-action"
            />
            </div>
            <div className="column" style={{ width: '62%'}}></div>
          </CustomDialog>

          <DialogAlert 
              title='Excluir Feriado'
              text={textAlertDeleteHoliday}
              icon='icon-warning'
              isOpen={this.state.dialogQuestionDelete}
              actionYes={this.handleOnClickDelete}
              actionNo={this.handleNoClick}
          />
          
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default ManagerHoliday