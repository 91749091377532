// TOGGLE
const TOGGLE = 'TOGGLE'
const Toggle = {
  setToggle(toggle) {
    localStorage.setItem(TOGGLE, JSON.stringify(toggle))
  },
  hasToggle(toggle) {
    this.getToggles()

    const toggles = this.getToggles()
    if (toggles !== undefined && toggles !== null && toggles.indexOf(toggle) > -1) {
      return true
    }
    return false
  },
  getToggles() {
    return JSON.parse(localStorage.getItem(TOGGLE))
  },
  clear() {
    localStorage.removeItem(TOGGLE)
  }
}

// Toggle Manager
export const ToggleManager = {
  setToggle(toggle) {
    Toggle.setToggle(toggle)
  },
  getToggles() {
    return Toggle.getToggles()
  },
  hasToggle(toggle) {
    return Toggle.hasToggle(toggle)
  },
  logout() {
    Toggle.clear()
  }
}

export default ToggleManager