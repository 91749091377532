import React, { Component, Fragment } from 'react'
import { bindAll } from 'lodash'
import { Treebeard } from 'react-treebeard'
import decorators from 'react-treebeard/dist/components/Decorators'
import defaultTheme from '../../themes/default'
import InputText from '../form-components/InputText'
import i18n from '../../utils/i18n/i18n'
import ActionButton from '../../components/buttons/ActionButton'
import * as filters from './filter'
import { CredentialManager } from '../../commons/CredentialManager'
import CategoryController from '../../controllers/CategoryController'
import GenerateTreeList from '../../services/GenerateTreeList'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import Closable from '../../components/closable/Closable'

const removeChildren = treeList => treeList.map((node) => {
  if (node.children && node.children.length > 0) {
    removeChildren(node.children)
  } else {
    node.children = null
  }
  return node
})

const convertData = (treeList) => {
  const tree = removeChildren(treeList)

  return {
    name: 'Todas',
    toggled: true,
    children: tree
  }
} 

let categoryItem = null

const customToggle = (treeNode) => {
  const { style } = treeNode
  return (
    <div style={style.toggle}>
      <svg height="10px" width="10px">
        <path
          d="M6.8,5l-4-3.6c-0.3-0.3-0.3-0.8,0-1c0.3-0.3,0.8-0.3,1.1,0l4,3.6c0.6,
          0.6,0.6,1.5,0,2.1l-4,3.6 C3.6,10,3.1,10,2.8,9.7c-0.3-0.3-0.3-0.8,0-1L6.8,5z"
        />
      </svg>
    </div>
  )
}

class CategoriesTree extends Component {

  constructor(props) {
    super(props)

    const data = convertData(this.props.treeList);
    filters.expandSelectedNodes(data);
    this.state = {
      showPartnerSuccessMessage: false,
      filterValue: '',
      selectedOption: 'Todos',
      data,
      loader: false
    }

    bindAll(this, ['closePartnerSuccessMessage', 'onClickSicronize','onValueChange',
                   'onToggle', 'customHeader', 'onFilterMouseUp', 
                   'onClickTreeItem', 'onFilterData', 'processTreeList', 'closePartnerMessage']);
  }

  componentDidUpdate(prevProps) {
    if (this.props.treeList !== prevProps.treeList) {
      this.onFilterData();
    }
  }

  processTreeList = (categories, categoriesFirestore) => new Promise((resolve, reject) => {
     this.setState({
      treeList: GenerateTreeList.getTreeList(this.state.categories, this.state.categoriesFirestore)
     });

     resolve();
  });

  onClickTreeItem(newNode) {
    if(!newNode.hasChildren){
      this.props.onClickTreeItemCallback(newNode)
    }
  }

  customHeader(treeNode) {
    const { node, style } = treeNode

    let customType

    const newLocal = node.miraklCategory != ""
    if (newLocal && !node.syncMirakl && !node.hasChildren) { // não sincronizado
      customType = 'planCustom' 
    } 

    if ((node.miraklCategory == "" || node.miraklCategory == undefined) && !node.hasChildren) { //sem categoria de gestão
      customType = 'partnerCustom'
    }
 
    const icon = <i className={`${customType}`} />
    return (
      <div style={style.base}>
        <div id="tree-item">
          {customType && icon}
          <span id={`tree-item-title-${node.id}`} className={customType} onClick={() => this.onClickTreeItem(node)}>{ node.name }</span>
        </div>
      </div>
    )
  }

  

  /*eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["node"] }]*/
  // Função que abre a árvore quando clicado na seta
  onToggle(node, toggled) {
    const { cursor } = this.state

    if (cursor) {
      cursor.active = false
    }

    node.active = true
    if (node.children) {
      node.toggled = toggled
    }
    this.setState({ cursor: node })
  }

  onFilterMouseUp(e) {
    const filter = e.target.value.trim()
    if(filter.length>3){
      this.setState({ filterValue: filter }, () => this.onFilterData())
    } else {
      this.setState({ filterValue: filter })
      const { treeList } = this.props
      const pureData = convertData(treeList)
      filters.expandSelectedNodes(pureData)
      this.setState({
        data: pureData
      })
    }
  }

  onValueChange(event) {    
    this.setState({  selectedOption: event.target.value }, () => this.onFilterData())
  }

  

  onFilterData() {
    
    const { treeList } = this.props
    const pureData = convertData(treeList)
    const { filterValue } = this.state
    if (!filterValue) {
      let filtered = filters.filterTree(pureData, '', this.state.selectedOption)
      //filtered = filters.expandFilteredNodes(filtered, '', this.state.selectedOption)
      this.setState({ data: filtered })
      //filters.expandSelectedNodes(pureData)
     // this.setState({
      //  data: pureData
     // })
    } else {

      let filtered = filters.filterTree(pureData, filterValue, this.state.selectedOption)
      if(filterValue.length > 3){
        filtered = filters.expandFilteredNodes(filtered, filterValue, this.state.selectedOption)
      }
      this.setState({ data: filtered })

    }

  
  }

  onClickSicronize = () => {
    this.setState({ loader : true });
    CategoryController.synchronizeCategories()
    .then((respose) => {
      this.setState({ 
        showPartnerMessage: true, 
        message: 'Sincronização de categoria realizado com sucesso.',
        colorMessage: 'header-message header-success-message'
      });

      console.log('Sync de categoria realizado com sucesso.');
      window.location.reload();

  })
  .catch((error) => {
    this.setState({ 
      showPartnerMessage: true, 
      message: 'Nenhuma categoria sem sincronização encontrada.',
      colorMessage: 'header-message header-error-message'
    });

    console.log(`Erro no sync de categoria: ${error}`);
  });
  

  }

  reload(){
    CategoryController.getCategories()
      .then(data => {
        console.log(data);
        this.setState({
          categories: data
        });

        CategoryController.getCategoriesFirestore()
        .then(data => {
          console.log(data);
          this.setState({
            categoriesFirestore: data
          });

          this.setState({ loader : true });

          this.processTreeList(this.state.categories, this.state.categoriesFirestore)
            .then(() => {
              this.data = convertData(this.state.treeList)

              this.setState({
                data: this.data,
                showPartnerSuccessMessage: true,
                loader : false 
              });
              this.forceUpdate()
            })
            .catch((error) => {
              console.log(error);
              this.setState({ loader : false });
            })
        })
        .catch(err => {
          console.log(err);
          this.setState({ loader : false });
        });
      })
      .catch(err => {
        console.log(err)
      });
  }
 

  openPartnerSuccessMessage() {
    this.setState({ showPartnerSuccessMessage: true })
  }

  closePartnerSuccessMessage() {
    this.setState({ showPartnerSuccessMessage: false })
  }

  closePartnerMessage() {
    this.setState({ showPartnerMessage: false })
  }

  render() {
    const { data, loader } = this.state

    decorators.Toggle = customToggle
    decorators.Header = this.customHeader

    defaultTheme.tree.base = {
      ...defaultTheme.tree.base,
      backgroundColor: '#ebebeb',
      listStyle: 'none',
      padding: '16px'
    }

    /**
     * The Firefox requires container object on tree.node with link properties
     */
    defaultTheme.tree.node = {
      ...defaultTheme.tree.node,
      container: {
        activeLink: {
          background: '#31363F'
        },
        link: {
          cursor: 'pointer',
          display: 'block',
          padding: '0px 5px',
          position: 'relative'
        }
      }
    }
    const hasRoleSync = CredentialManager.userHasRole('ROLE_SYNC_CATEGORY')

    return (
      <Fragment>
        <section className="header-message-container">
          <Closable
            className="header-message header-success-message"
            isOpen={this.state.showPartnerSuccessMessage}
            onClose={this.closePartnerSuccessMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="commission-partner-save-success">
              <i className="icon-ok-check" />
             Categorias sincronizadas com sucesso.
            </span>
          </Closable>
        </section>
        <h3 id="title">{ i18n.t('Categories') }</h3>

        {/* Adiciona o campo de busca */}
        <div id="tree-categories-search">
          <InputText
            className="input-search"
            icon="icon-search"
            placeholder={i18n.t('Search category')}
            onChange={this.onFilterMouseUp}
           
          />
        </div>

        <ul id="legenda">
           <li className="partnerCustom">
              <span className="partnerCustom">
                <input type="radio" name="filtro" value="Sem_cat" checked={this.state.selectedOption === "Sem_cat"} onChange={this.onValueChange}/> Sem categoria de Gestão
              </span>
            </li>
           <li className="planCustom">
              <span className="planCustom">
                <input type="radio" name="filtro" value="No_sync" checked={this.state.selectedOption === "No_sync"} onChange={this.onValueChange}/> Não sincronizado com a Mirakl
              </span>
          </li>
          
          <li>
            <input type="radio" name="filtro" value="Todos" checked={this.state.selectedOption === "Todos"} onChange={this.onValueChange}/> Todos
          </li>
          </ul>
          
        {/* Adiciona o componente da árvore */}
       
        <br/>
             <div id="tree">
            <Treebeard
              style={defaultTheme}
              decorators={decorators}
              data={data}
              onToggle={this.onToggle}/>
              </div>
              
          <br />
          <div className="btn">

            <ActionButton type="button" componentClass="btn-action-green" text="Sincronizar categorias com a Mirakl"
              onClickAction={this.onClickSicronize} disable={!hasRoleSync} />

          </div>

           {/* Criar componente de mensagem */}
        <section className="header-message-container">
          <Closable
            className={this.state.colorMessage || ''}
            isOpen={this.state.showPartnerMessage || false}
            onClose={this.closePartnerMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="commission-partner-save-success">
              <i className="icon-ok-check" />
              {this.state.message}
            </span>
          </Closable>
        </section>
      
          <div className="content payment-reports">
            {loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
          </div>
      </Fragment>
    )
  }
}


export default CategoriesTree
