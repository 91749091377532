import React, { PureComponent } from 'react';

import { CustomDialog, CustomDialogHeader} from '../../components/commons/dialog/CustomDialog'
import ActionButton from '../../components/buttons/ActionButton'

class DialogAlert extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {
    const {
      isOpen, onClose, title, icon, id, text, actionYes, actionNo
    } = this.props
    
    const header = (
      <CustomDialogHeader
          title={title}
          icon={icon}
          id={`${id}-header`}
      />
    )

    return (
      <CustomDialog
        header={header}
        open={isOpen}
    >
        <div className="column" style={{ width: '100%' }}>
            {text}
        </div>
            
        <div className="column" style={{
            width: '100%',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <div style={{
              width: '35%',
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <ActionButton
                onClickAction={actionYes}
                id="btnSim"
                text="Sim"
                componentClass="btn btn-default btn-alert" />
            
            <ActionButton
                onClickAction={actionNo}
                id="btnNao"
                text="Não"
                componentClass="btn btn-action btn-alert"
                />
              </div>

        </div>                    
      </CustomDialog>
    );
  }
}

export default DialogAlert;
