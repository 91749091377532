import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import Closable from '../../components/closable/Closable'
import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'
import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import InputText from '../../components/form-components/InputText'
import ManagementController from '../../controllers/ManagementController'
import ManagerUserGrid from './ManagerUserGrid'
import DialogAlert from '../../components/DialogAlert/DialogAlert'

import './ManagerUser.css'

class ManagerUser extends Component {
    constructor(props) {
      super(props)
      
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        loader: false,
        selectedRoles:  [] ,
        mktpListRoles:  [] ,
        inputUsername: '',    
        allUsersList: [],
        gridApi: undefined,
        dialogQuestionRedefine: false,
        dialogQuestionLock: false,
        dialogQuestionDelete: false,
        userNameSelected: ''
      }
      
      bindAll(this, [
                     'closePartnerMessage',
                     'handleOnClickFilter',
                     'handleRolesSelected',
                     'handleMktpListRoles'
                    ]);
    }

    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    handleRolesSelected = (roles) => {
      this.setState({ selectedRoles: roles })
    }

    handleMktpListRoles = (roles) => {
      this.setState({ mktpListRoles: roles })
    }

    handleOnClickFilter = (event) => {
      this.setState({ loader: true });

      let inputUsername = this.state.inputUsername ? this.state.inputUsername : undefined;
      let listRoles = this.state.selectedRoles.length > 0 ? this.state.selectedRoles : undefined;
      
      if (this.formValidate()) {
        ManagementController.createUser(inputUsername, listRoles)
          .then((result) => {
            if (result.status === 'Error') {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `${result.message}`,
                colorMessage: 'header-message header-error-message'
              });
            } else {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Usuário criado com sucesso.`,
                colorMessage: 'header-message header-success-message'
              });
            }
          })
          .catch((error) => {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Erro eo tentar criar o usuário.`,
                colorMessage: 'header-message header-error-message'
              });
          })
      } 
  }

  checkName = (item) => {
    if (item.userName == '' || item.userName == undefined) {
      return false;
    } else if (this.state.inputUsername == '' || this.state.inputUsername == undefined) {
      return true;
    }

    return item.userName.includes(this.state.inputUsername);
  }

  handleChangeUsername = (event) => {
    const { value } = event.target

    let listUserFiltered = this.state.allUsersList.filter(this.checkName)

    console.log(listUserFiltered)
    this.setState({
      inputUsername: value
    }, () => {
      this.state.gridApi.setRowData(listUserFiltered);
      this.refreshGrid();
    })
  }

  handleOnClickDelete = (userName) => {
    this.setState({ loader: true });

    ManagementController.deleteUserByUsername(this.state.userNameSelected)
    .then((data) => {
      this.setState({
        loader: false,
        showPartnerMessage: true,
        message: `Usuário apagado do Portal Marketplace com sucesso.`,
        colorMessage: 'header-message header-success-message',
        dialogQuestionDelete: false,
      });
    })
    .catch((error) => {
      this.setState({
        loader: false,
        showPartnerMessage: true,
        message: `Erro ao tentar excluir o usuário da base de dados: ${error}`,
        colorMessage: 'header-message header-error-message',
        dialogQuestionDelete: false,
      });
    })
  }

  handleRedefinePassword = (event) => {
    ManagementController.redefinePassword(this.state.userNameSelected)
    .then(() => {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Senha do usuário ${this.state.userNameSelected} foi redefinida com sucesso, um e-mail será enviado com instruções.`,
        colorMessage: 'header-message header-success-message',
        dialogQuestionRedefine: false
      });
    })
    .catch((error) => {
      this.setState({
        loader: false,
        showPartnerMessage: true, 
        message: `Erro ao tentar redefinir a senha do usuário selecionado: ${error}`,
        colorMessage: 'header-message header-error-message',
        dialogQuestionRedefine: false
      });
    })
  }

  handleClickButtonsGrid = {
    edit: (userName) => {
      localStorage.setItem('USER_NAME_SELECTED', userName);
      window.location.replace('edituser');
    },
    redefine: (userName) => {
      this.setState({ 
        dialogQuestionRedefine: true,
        userNameSelected: userName
      });
    },
    lockUser: (userName) => {
      this.setState({ 
        dialogQuestionLock: true,
        userNameSelected: userName
      });
    },
    deleteUser: (userName) => {
      this.setState({ 
        dialogQuestionDelete: true,
        userNameSelected: userName
      });
    }
  }
    
  formValidate = () => { 
      if (this.state.inputUsername == '' || this.state.inputUsername == undefined ) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve preencher o campo Username.`,
          colorMessage: 'header-message header-error-message'
        });
        return false; 
      }

      if (this.state.selectedRoles.length <= 0) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve adicionar ao menos 1 permissão para o usuário.`,
          colorMessage: 'header-message header-error-message'
        });

        return false; 
      }
      return true;
    }

    onGridReady = (params) => {
        this.setState({ gridApi: params.api })
    }
    
    refreshGrid = () => {
        let params = {
            force: true,
            suppressFlash: true,
        }        
        this.state.gridApi.refreshCells(params);
    }    

    fillAllUsersGrid = () => {
      this.setState({ loader: true });
      
      ManagementController.getAllUsers()
      .then((data) => {
        this.state.gridApi.setRowData(data);
        this.refreshGrid();

        this.setState({
            allUsersList: data,
            loader: false
        });
      })
      .catch((error) => {
        this.setState({
            loader: false,
            showPartnerMessage: true,
            message: `Erro ao tentar obter todos os usuários da base de dados: ${error}`,
            colorMessage: 'header-message header-error-message'
        });
      })
    }

    handleOnAddUserClick = () => {
      console.log('Redirecionar para a tela de inclusão!')
      window.location.replace('includeuser')
    }

    handleNoClick = () => {
      this.setState({ 
        dialogQuestionRedefine: false,
        dialogQuestionLock: false,
        dialogQuestionDelete: false
      });
    }

    handleLockUserClick = () => {
      this.setState({ loader: true });

      ManagementController.updateActive(this.state.userNameSelected, false)
      .then((data) => {
        this.setState({
          loader: false,
          showPartnerMessage: true,
          message: `Usuário desativado no Portal Marketplace com sucesso.`,
          colorMessage: 'header-message header-success-message',
          dialogQuestionLock: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
          showPartnerMessage: true,
          message: `Erro ao tentar obter todos os usuários da base de dados: ${error}`,
          colorMessage: 'header-message header-error-message'
        });
      })
    }

    componentDidMount = () => {
      this.fillAllUsersGrid();
    }
    
    render() {

      const hasRoleSync = CredentialManager.userHasRole('ROLE_ADMIN')

      if (!hasRoleSync) {
        return <Redirect to="/error" />
      }

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '50%'}}> 
              <h3>Permisões de Usuários</h3>
            </div>
            <div className="column" style={{ width: '50%', textAlign: 'end' }}>
              <ActionButton
                      onClickAction={this.handleOnAddUserClick}
                      id="btnAddUser"
                      text="Incluir Usuário"
                      icon="icon-add"
                      componentClass="btn btn-action" />                        
            </div>  
            <div className="column" style={{width: '50%'}}>
              <InputText
                    id="inputUsername"
                    value={this.state.inputUsername}
                    placeholder={'Digite aqui o email do usuário ou nome.'}
                    maxLength={100}
                    onChange={this.handleChangeUsername}
                    width='50%'
                />
            </div>
            <div className="column" style={{width: '50%'}}></div>
          </div>
          <div className="container">
            <ManagerUserGrid
                    height={450} 
                    width="100%"                         
                    onGridReady={this.onGridReady}
                    billingAdjustmentList={this.state.billingAdjustmentList}
                    handleClickButtonsGrid={this.handleClickButtonsGrid} />
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>

          <DialogAlert 
              title='Redefinir senha de usuário'
              text="Tem certeza que deseja redefinir a senha desse usuário? Se sim, o usuário deverá receber um e-mail com as instruções necessárias para alterar sua senha."
              icon='icon-warning'
              isOpen={this.state.dialogQuestionRedefine}
              actionYes={this.handleRedefinePassword}
              actionNo={this.handleNoClick}
          />
                
          <DialogAlert 
              title='Desativar usuário'
              text="Deseja mesmo desativar o usuário selecionado?"
              icon='icon-warning'
              isOpen={this.state.dialogQuestionLock}
              actionYes={this.handleLockUserClick}
              actionNo={this.handleNoClick}
          />

          <DialogAlert 
              title='Excluir usuário'
              text="Tem certeza que deseja excluir o usuário?"
              icon='icon-warning'
              isOpen={this.state.dialogQuestionDelete}
              actionYes={this.handleOnClickDelete}
              actionNo={this.handleNoClick}
          />
          
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default ManagerUser