import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import Closable from '../../components/closable/Closable'
import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'
import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import InputText from '../../components/form-components/InputText'
import ManagementController from '../../controllers/ManagementController'
import SellerController from '../../controllers/SellerController'
import NotificationController from '../../controllers/NotificationController'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'
import CustomDialog from '../../components/commons/dialog/CustomDialog'
import InputTextArea from '../../components/form-components/input-text-area/InputTextArea'
import Dropzone from '../../components/dropzone/DropZone';

class IncludeNotification extends Component {
    constructor(props) {
      super(props)
      
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        loader: false,
        inputTitle: '',
        inputMessage: '',
        inputCallToAction: '',
        sellerListSelected: undefined,
        sellerList: [],
        fileUpload: undefined,
        addNotificationMassiveScreenOpened: false,
      }
      
      bindAll(this, [
                     'closePartnerMessage',
                     'handleOnClickCriarNotificacao',
                     'setValueListSellerSelected',
                     'searchSellerByTrandingName',
                     'onFileAdded'
                    ]);
                    
    }

    searchSellerByTrandingName = (trandingName) => {
      SellerController.getSellerList(trandingName)
        .then((result) => {
          this.setState({
            sellerList: result
          })
        })
        .catch((error) => {
          this.setState({
            showPartnerMessage: true, 
            message: `Erro ao buscar o Seller: ${error}`,
            colorMessage: 'header-message header-error-message'
          });
        });
    }

    setValueListSellerSelected = (value) => {
      this.setState({
        sellerListSelected: value
      }, () => {console.log(this.state.sellerListSelected)});
    }
    
    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    handleOnClickCriarNotificacao = (event) => {
      this.setState({ loader: true });

      if (this.formValidate()) {
          let inputTitle = this.state.inputTitle ? this.state.inputTitle : undefined;
          let inputMessage = this.state.inputMessage ? this.state.inputMessage : undefined;
          let inputCallToAction = this.state.inputCallToAction ? this.state.inputCallToAction : undefined;
          let list = this.state.sellerListSelected; 
          let sellerListSelected = [];
          list.map((item) => {
            sellerListSelected.push(item.ecommerceId);
          })
        NotificationController.createNotification(inputTitle, inputMessage, inputCallToAction, sellerListSelected)
          .then((result) => {
            if (result.status === 'Error') {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `${result.message}`,
                colorMessage: 'header-message header-error-message'
              });
            } else {
              let eventClear = document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
              if (eventClear != undefined) {
                eventClear.click();
              }
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Notificação criada com sucesso.`,
                colorMessage: 'header-message header-success-message',
                inputTitle: '',
                inputMessage: '',
                inputCallToAction: '',
                sellerListSelected: undefined,
                sellerList: []
              });
            }
          })
          .catch((error) => {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Erro eo tentar criar o usuário.`,
                colorMessage: 'header-message header-error-message'
              });
          })
      } 
  }
  handleOnClickCancelarNotificacao = (event) => {
     window.location.replace( 'notification');
  }


  handleChangeTitle = (event) => {
    const { value } = event.target

    this.setState({
      inputTitle: value
    })
  }

  handleChangeMessage = (event, value) => {
    this.setState({
      inputMessage: value
    })
  }
  handleChangeCallToAction = (event) => {
    const { value } = event.target

    this.setState({
      inputCallToAction: value
    })
  }
  formValidateMassive = () => { 
      if (this.state.inputTitle == '' || this.state.inputTitle == undefined ) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve preencher o campo Título.`,
          colorMessage: 'header-message header-error-message'
        });
        return false; 
      }
      if (this.state.inputMessage == '' || this.state.inputMessage == undefined ) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve preencher o campo Mensagem.`,
          colorMessage: 'header-message header-error-message'
        });
        return false; 
      }
      return true;
  }
    
  formValidate = () => { 
      if (this.state.inputTitle == '' || this.state.inputTitle == undefined ) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve preencher o campo Título.`,
          colorMessage: 'header-message header-error-message'
        });
        return false; 
      }
      if (this.state.inputMessage == '' || this.state.inputMessage == undefined ) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve preencher o campo Mensagem.`,
          colorMessage: 'header-message header-error-message'
        });
        return false; 
      }
      if (this.state.sellerListSelected == '' || this.state.sellerListSelected == undefined ) { 
        this.setState({
          loader: false,
          showPartnerMessage: true, 
          message: `Você deve preencher o campo Seller.`,
          colorMessage: 'header-message header-error-message'
        });
        return false; 
      }
      return true;
  }
  onFileAdded(file) {
    this.setState({
      fileUpload: file
    })
  }
  handleOnCancelNotificationMassiveClick = () => {
    this.setState({
      addNotificationMassiveScreenOpened: false,
      fileUpload: undefined
    })
  }
  handleOnAddNotificationMassiveClick = () => {

    if (this.formValidateMassive()) {
      this.setState({
        addNotificationMassiveScreenOpened: true
      })
    }
  }
  handleOnSaveAddNotificationMassiveClick = () => {
    this.setState({ loader: true });


    if (this.state.fileUpload == undefined) {
      this.setState({
        showPartnerMessage: true,
        message: `Não foi selecionado um arquivo para ser enviado!`,
        colorMessage: 'header-message header-error-message',
        loader: false
      });
      
      return;
    }

    NotificationController.uploadMassiveNotification(this.state.fileUpload, this.state.inputTitle, this.state.inputMessage, this.state.inputCallToAction)
      .then((data) => {
        this.setState({
          showPartnerMessage: true,
          message: `Arquivo enviado para criar notificações!`,
          colorMessage: 'header-message header-success-message',
          loader: false,
          fileUpload: undefined,
          addNotificationMassiveScreenOpened: false,
          showPartnerMessage: true, 
          inputTitle: '',
          inputMessage: '',
          inputCallToAction: '',
          sellerListSelected: undefined,
          sellerList: []
        });
      })
      .catch((error) => {
        this.setState({
          showPartnerMessage: true,
          message: `Erro enviar arquivo para criar notificações: ${error}`,
          colorMessage: 'header-message header-error-message',
          loader: false
        });
      })
  }
    
    render() {

      /*const hasRoleSync = CredentialManager.userHasRole('ROLE_ADMIN')

      if (!hasRoleSync) {
        return <Redirect to="/error" />
      }*/

      return (
        <Fragment>
          <div className="container">
            <div className='column' style={{width: '100%'}}> 
              <h2>Central de Notificações</h2>
            </div>
            <div className="column" style={{width: '100%'}}> 
              <h3>Cadastro</h3>
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Título:
            </div>                                                               
            <div className="column" style={{width: '80%', marginTop: '0px'}}>
              <InputText
                    id="inputTitle"
                    value={this.state.inputTitle}
                    placeholder={'Digite aqui o Título'}
                    maxLength={45}
                    onChange={this.handleChangeTitle}
                />
            </div>
            <div className="column" style={{width: '20%', marginTop: '0px'}}></div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Mensagem:
            </div>                                                               
            <div className="column" style={{width: '80%', marginTop: '0px'}}>
              <InputTextArea
                    id="inputMessage"
                    value={this.state.inputMessage}
                    placeholder={'Digite aqui a mensagem'}
                    onChangeValue={this.handleChangeMessage}
                    readOnly={false}
                    maxLength={220}
                />
            </div>
            <div className="column" style={{width: '20%', marginTop: '0px'}}></div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Call to Action - Link para Ação:
            </div>                                                               
            <div className="column" style={{width: '80%',  marginTop: '0px'}}>
              <InputText
                    id="inputCallToAction"
                    value={this.state.inputCallToAction}
                    placeholder={'Digite aqui o link'}
                    onChange={this.handleChangeCallToAction}
                    readonly={false}
                />
            </div>
            <div className="column" style={{width: '20%', marginTop: '0px'}}></div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
                Para:
            </div> 
            <div className="column" style={{width: '40%', marginTop: '10px'}}>
                <InputAutocomplete 
                  setValue={this.setValueListSellerSelected} 
                  searchSellerByTrandingName={this.searchSellerByTrandingName} 
                  sellerList={this.state.sellerList}
                  width={600} 
                  multiple={true}
                />
            </div>
            <div className="column" style={{ width: '60%', marginTop: '10px'}}>
                <ActionButton
                  onClickAction={this.handleOnAddNotificationMassiveClick}
                  id="btnAddNotificationtMassive"
                  text="Importar CSV"
                  componentClass="btn btn-action" />                        
            </div>
            
            <div className="column" style={{width: '15%', marginTop: '10px'}}>
                <ActionButton 
                  onClickAction={this.handleOnClickCriarNotificacao} 
                  id="btnFiltrar" 
                  text="Criar Notificação"
                  componentClass="btn btn-action" />
            </div>
            <div className="column" style={{width: '85%', marginTop: '10px'}}>
                <ActionButton 
                  onClickAction={this.handleOnClickCancelarNotificacao} 
                  id="btnCancelar" 
                  text="Cancelar"
                  componentClass="btn btn-default"/>
            </div>
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}

          <CustomDialog
              title='Criar Notificações em Massa'
              open= {this.state.addNotificationMassiveScreenOpened}
              id="lockProductMassive"
          >

              <div className='column' style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                <span style={{ 'font-size': '30px', 'color': '#156992', 'font-weight': 'bold'}}>Upload do arquivo CSV: </span>
              </div>
              <div className="column" style={{ width: '100%', display: 'flex',  alignItems:'center'}}>
                  <Dropzone onFileAdded={this.onFileAdded} file={this.state.fileUpload} accept=".csv" />
              </div>
              <div className="column" style={{ width: '100%'}}></div>

              <div className="column" style={{
                  width: '35%',
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
              }}>

                  <ActionButton 
                      onClickAction={this.handleOnCancelNotificationMassiveClick}
                      id="btnCancelNotificationMassive"
                      text="Cancelar"
                      componentClass="btn btn-action" />

                  <ActionButton
                      onClickAction={this.handleOnSaveAddNotificationMassiveClick}
                      id="btnSaveAddNotificationMassive"
                      text="Enviar"
                      componentClass="btn btn-action"                            
                      />
              </div>
              
          </CustomDialog>



        </Fragment>
      )
    }
}

export default IncludeNotification