const GenerateTreeList = {
    getTreeList: function(categories, miraklVtexCategories, children) {
        var listTree = [];
    
        if (children) {
            categories = children;
        }
    
        categories.forEach(function(item) {
            var itemFirestore = miraklVtexCategories.filter(function(category) { return category.vtexCategory == item.id});
            var itemTree;
            var listChildren;
            
            if (itemFirestore.length > 0) {
                itemTree = GenerateTreeList.buildMirakl(item, itemFirestore, true);
            } else {
                itemTree = GenerateTreeList.buildMirakl(item, itemFirestore, false);
            }
            
            if (item.hasChildren) {
                listChildren = GenerateTreeList.getTreeList(itemTree, miraklVtexCategories, item.children);
                
                itemTree.children = [];
                itemTree.children = listChildren;
            }
            
            listTree.push(itemTree);
        })
        
        return listTree;
    },
    buildMirakl: function(item, firestore, exist) {
        var tree = {};

        tree.id = item.id;
        tree.name = item.name;
        tree.hasChildren = item.hasChildren;
        tree.url = item.url;
        tree.Title = item.Title;
        tree.histories = firestore.length > 0 ? firestore[0].histories : [];
        tree.MetaTagDescription = item.MetaTagDescription;
        tree.dtSyncMirakl = firestore.length > 0 ? firestore[0].dtSyncMirakl : null;
        tree.miraklCategory = firestore.length > 0 ? firestore[0].miraklCategory : undefined;
        tree.vtexCategory = firestore.length > 0 ? firestore[0].vtexCategory : '';

        if (!exist) {
            tree.vtexCategory = item.id;
        }

        tree.syncMirakl =  firestore.length > 0 && firestore[0].syncMirakl;     
        tree.exist = exist;
        
        return tree;
    }
};

export default GenerateTreeList;