import React, { useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { format } from 'date-fns'

import { compareAsc, compareDesc } from 'date-fns'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


const ReportOffersGrid = props => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [rowData, setRowData] = useState(props.OfferList);

    const dateComparator = (date1, date2) => {
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);

        if (date1Number == null && date2Number == null) {
          return 0;
        }

        if (date1Number == null) {
          return -1;
        }

        if (date2Number == null) {
          return 1;
        }

        return date1Number - date2Number;
    }

    const monthToComparableNumber = (date) => {
        if (date || date.length !== 10) {
            return null;
        }

        var yearNumber = date.substring(6, 10);
        var monthNumber = date.substring(3, 5);
        var dayNumber = date.substring(0, 2);
        var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

        return result;
    }

    return (
        <div className="ag-theme-material" style={{ height: props.height, width: props.width }}>
            <AgGridReact
                onGridReady={props.onGridReady}
                rowData={rowData}
                pagination={true}
                enableCellChangeFlash={true}
                suppressExcelExport={true}
            >
                <AgGridColumn headerName="Data/Hora" field="createdOn" filter="agDateColumnFilter" cellRender={(params) => { return params.data.createdOn; }} comparator={dateComparator} width={180} />
                <AgGridColumn headerName="Total Mirakl" field="totalMirakl" width={120} />
                <AgGridColumn headerName="Total Middleware" field="totalMidware" width={180} />
                <AgGridColumn headerName="Total Middleware Ok" field="totalMidwareOk" width={180} />
                <AgGridColumn headerName="Total Middleware Erro" field="totalMidwareErro" width={180} />
                <AgGridColumn headerName="Total VTEX" field="totalVtex" width={120} />
                <AgGridColumn headerName="Total Seller" field="totalSeller" width={120} />

                <AgGridColumn headerName="Diff Mirakl X VTEX" field="diffMiraklVtex" filter="agNumberColumnFilter" />
                <AgGridColumn headerName="% Mirakl x VTEX" field="percentMiraklVtex" filter="agNumberColumnFilter" />

                <AgGridColumn headerName="Diff Middleware x VTEX" field="diffMidwareVtex" filter="agNumberColumnFilter" />
                <AgGridColumn headerName="% Middleware x VTEX" field="percentMidwareVtex" filter="agNumberColumnFilter" />

                <AgGridColumn headerName="Diff Mirakl x Middleware" field="diffMiraklMidware" filter="agNumberColumnFilter" />
                <AgGridColumn headerName="% Mirakl x Middleware" field="percentMiraklMidware" filter="agNumberColumnFilter" />

            </AgGridReact>
        </div>
    )
}

export default ReportOffersGrid;