import querystring from 'querystring'
import { doAuthenticate } from '../../../commons/Connection'
import { POST_AUTHENTICATION } from '../../../commons/Services'

const getParams = (auth) => {
  const { ...params } = auth
  return `${querystring.stringify(params)}`
}

const authenticate = (username, password) => {
  const auth = {
    client_id: 'web-client',
    client_secret: 'web-client-secret',
    grant_type: 'password',
    username,
    password
  }
  return new Promise((resolve, reject) => {
    doAuthenticate(POST_AUTHENTICATION, getParams(auth))
      .then(response => resolve(response))
      .catch((error) => {
        reject(error)
      })
  })
}

export default authenticate