import React, { useCallback, useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ActionButton from '../../components/buttons/ActionButton'
import green_light from '../../resources/images/green_light.png'
import yellow_light from '../../resources/images/yellow_light.png'
import red_light from '../../resources/images/red_light.png'
import { bindAll } from 'lodash'

import { format } from 'date-fns'

import { compareAsc, compareDesc } from 'date-fns'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './Notification.css'



const ProductLockedGrid = props => {

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [rowData, setRowData] = useState(props.ProductList);

    return (
        <div className="ag-theme-material" style={{ height: props.height, width: props.width }}>
            <AgGridReact 
                onGridReady={props.onGridReady}
                rowData={rowData}
                pagination={true}
                enableCellChangeFlash={true}
                suppressExcelExport={true}
                rowMultiSelectWithClick={true} 
                rowSelection="multiple"    
                headerCheckboxSelection={true}
                enableBrowserTooltips={true}
            >
                <AgGridColumn lockPosition={true} headerName="" field='selecionar' width={20} headerCheckboxSelection={true} checkboxSelection={true} />
                <AgGridColumn headerName="Título" field="title" resizable={true} tooltipField={"title"}  />
                <AgGridColumn headerName="Data Criação" field="createdAt" resizable={true} tooltipField={"createdAt"} />
                <AgGridColumn headerName="Data Leitura" field="readed_at" width={250} resizable={true} tooltipField={"readed_at"} />
                <AgGridColumn headerName="Seller" field="user_id" width={180}  resizable={true} tooltipField={"user_id"}/>
                <AgGridColumn headerName="ID" field="_id" width={180}  resizable={true} />
                <AgGridColumn headerName="GROUP_ID" field="group_id" width={180}  resizable={true} />
            </AgGridReact>
        </div>
    )
}

export default ProductLockedGrid;