import { getReport, getReportBySeller } from "../commons/Connection";

const ReportController = {
    getReport: function(from ,to, groupBy, sellerId) {
        return getReport("/v1/report", from ,to, groupBy, sellerId);
    },
    getReportBySeller: function(sellerId) {
        return getReportBySeller("/v1/report/with-seller-name", sellerId);
    }
};

export default ReportController;