import React, { Component, Fragment } from 'react'
import Closable from '../../components/closable/Closable'
import { bindAll } from 'lodash'
import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import InputText from '../../components/form-components/InputText'
import PasswordController from '../../controllers/PasswordController'
import './RequestPassword.css'

class RequestPassword extends Component {
    constructor(props) {
      super(props)
      
      this.state = {
        showPartnerMessage: false,
        message: '',
        colorMessage: '',
        loader: false,
        inputUsername: ''
      }
      
      bindAll(this, [
                     'closePartnerMessage',
                     'handleOnClickFilter',
                     'handleRolesSelected'
                    ]);
                    
    }
    
    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    handleRolesSelected = (roles) => {
      this.setState({ selectedRoles: roles })
    }

    handleOnClickFilter = (event) => {
      this.setState({ loader: true });

      let inputUsername = this.state.inputUsername ? this.state.inputUsername : undefined;
      
      if (this.formValidate()) {
        PasswordController.requestPassword(inputUsername)
          .then((result) => {
            if (result.status === 'Error') {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `${result.message}`,
                colorMessage: 'header-message header-error-message'
              });
            } else {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Solicitação de senha efetuada com sucesso.`,
                colorMessage: 'header-message header-success-message'
              });
            }
          })
          .catch((error) => {
              this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: `Erro ao tentar solicitar uma nova senha.`,
                colorMessage: 'header-message header-error-message'
              });
          })
      } 
  }

  handleChangeUsername = (event) => {
    const { value } = event.target

    this.setState({
      inputUsername: value
    })
  }

    
    
    formValidate = () => { 
        if (this.state.inputUsername == '' || this.state.inputUsername == undefined ) { 
          this.setState({
            loader: false,
            showPartnerMessage: true, 
            message: `Você deve preencher o campo E-mail.`,
            colorMessage: 'header-message header-error-message'
          });
          return false; 
        }
        return true;
    }
    
    render() {

      return (
        <Fragment>
          <div className="container">
            <div className="column" style={{width: '100%'}}> 
              <h3>Incluir Usuário</h3>
            </div>
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              Login:
            </div>                                                               
            <div className="column" style={{width: '10px'}}>
              <InputText
                    id="inputUsername"
                    value={this.state.inputUsername}
                    placeholder={'Digite aqui o seu E-mail'}
                    maxLength={100}
                    onChange={this.handleChangeUsername}
                />
            </div>
            
            <div className="column" style={{width: '100%', marginTop: '30px'}}>
              <ActionButton 
                onClickAction={this.handleOnClickFilter} 
                id="btnFiltrar" 
                text="Solicitar Senha"
                componentClass="btn btn-action" />
            </div>
          </div>
          <section className="header-message-container">
            <Closable
              className={this.state.colorMessage || ''}
              isOpen={this.state.showPartnerMessage || false}
              onClose={this.closePartnerMessage}
              showCloseButton
              autoCloseTime={5000}
            >
              <span className="commission-partner-save-success">
                <i className="icon-ok-check" />
                {this.state.message}
              </span>
            </Closable>
          </section>
          {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
        </Fragment>
      )
    }
}

export default RequestPassword