const env = {
    getUrlEnv() {
        let env = {};

        switch (process.env.REACT_APP_ENV) {
            case "local":
                env = {
                    //REACT_APP_MKTP_FRONT_BACKEND_URL: "http://mktp-portal-backend.qa.br.wcorp.carrefour.com",
                    REACT_APP_MKTP_FRONT_BACKEND_URL: "http://localhost:8087",
                    REACT_APP_MKTP_AUTH_URL: "http://mktp-auth.dev.br.wcorp.carrefour.com",
                    REACT_APP_MKTP_SELLER: "http://mktp-seller.dev.br.wcorp.carrefour.com",
                    REACT_APP_MKTP_SELLER_DIAGNOSIS: 'http://mktp-seller-diagnosis.qa.br.wcorp.carrefour.com',
                    REACT_APP_MKTP_PRODUCT: 'http://mktp-product.qa.br.wcorp.carrefour.com',
                    REACT_APP_C4_COMMISSIONING: 'https://c4-commissioning.qa.br.wcorp.carrefour.com',
                    REACT_APP_MKTP_DATA_HUB_URL: 'https://mktp-portal-front.qa.br.wcorp.carrefour.com/mktp-data-hub',
                    //GOOGLE_SOCIAL_LOGIN_CLIENTID DEV
                    GOOGLE_SOCIAL_LOGIN_CLIENTID: '1041356878392-si629oitbq2jtgt9vpf2bq3n9gjvoafd.apps.googleusercontent.com',
                    REACT_APP_CATALOGING_DASHBOARD: 'http://mktp-top-sellers.qa.br.wcorp.carrefour.com',
                    REACT_APP_MKTP_NOTIFICATION: 'https://apihub.cloud.carrefour.com.br/mgus-portalseller-notifications-central',
                    REACT_APP_MKTP_NOTIFICATION_AUTHORIZATION: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3RldG9rZW4iLCJpYXQiOjE2NTQxODcyOTl9.Aio3Gx7UU3w7hnTWrJIQFn8zVZDhjKBXtSqy_A9E86g',
                    REACT_APP_MKTP_NOTIFICATION_X_API_KEY: 'QPgWXgrDCNJc2vcy3sBTTVcDp2buGIdA'
                }
                break;

            //AMBIENTE DE DEV
            case "development":
                 env = { 
                    REACT_APP_MKTP_FRONT_BACKEND_URL: "https://mktp-portal-backend.dev.br.wcorp.carrefour.com",
                    REACT_APP_MKTP_AUTH_URL: "https://mktp-portal-front.dev.br.wcorp.carrefour.com/mktp-auth",
                    REACT_APP_MKTP_SELLER: "https://mktp-portal-front.dev.br.wcorp.carrefour.com/mktp-seller",
                    REACT_APP_MKTP_SELLER_DIAGNOSIS: 'https://mktp-portal-front.dev.br.wcorp.carrefour.com/mktp-seller-diagnosis',
                    REACT_APP_MKTP_PRODUCT: 'https://mktp-portal-front.dev.br.wcorp.carrefour.com/mktp-product',
                    REACT_APP_C4_COMMISSIONING: 'https://mktp-portal-front.dev.br.wcorp.carrefour.com/c4-commissioning',
                    REACT_APP_MKTP_DATA_HUB_URL: 'https://mktp-portal-front.dev.br.wcorp.carrefour.com/mktp-data-hub',
                    //GOOGLE_SOCIAL_LOGIN_CLIENTID DEV
                    GOOGLE_SOCIAL_LOGIN_CLIENTID: '1041356878392-si629oitbq2jtgt9vpf2bq3n9gjvoafd.apps.googleusercontent.com',
                    REACT_APP_CATALOGING_DASHBOARD: 'https://mktp-portal-front.dev.br.wcorp.carrefour.com/mktp-top-sellers',
                    REACT_APP_MKTP_NOTIFICATION: 'https://apihub.cloud.carrefour.com.br/mgus-portalseller-notifications-central',
                    REACT_APP_MKTP_NOTIFICATION_AUTHORIZATION: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3RldG9rZW4iLCJpYXQiOjE2NTQxODcyOTl9.Aio3Gx7UU3w7hnTWrJIQFn8zVZDhjKBXtSqy_A9E86g',
                    REACT_APP_MKTP_NOTIFICATION_X_API_KEY: 'QPgWXgrDCNJc2vcy3sBTTVcDp2buGIdA'
                }
                 break;
                 
            //AMBIENTE DE QA
            case "qa":
                env = {
                    REACT_APP_MKTP_FRONT_BACKEND_URL: "https://mktp-portal-backend.qa.br.wcorp.carrefour.com",
                    REACT_APP_MKTP_AUTH_URL: "https://mktp-portal-front.qa.br.wcorp.carrefour.com/mktp-auth",
                    REACT_APP_MKTP_SELLER: "https://mktp-portal-front.qa.br.wcorp.carrefour.com/mktp-seller",
                    REACT_APP_MKTP_PRODUCT: 'https://mktp-portal-front.qa.br.wcorp.carrefour.com/mktp-product',
                    REACT_APP_C4_COMMISSIONING: 'https://mktp-portal-front.qa.br.wcorp.carrefour.com/c4-commissioning',
                    REACT_APP_MKTP_SELLER_DIAGNOSIS: 'https://mktp-portal-front.qa.br.wcorp.carrefour.com/mktp-seller-diagnosis',
                    REACT_APP_MKTP_DATA_HUB_URL: 'https://mktp-portal-front.qa.br.wcorp.carrefour.com/mktp-data-hub',
                    //GOOGLE_SOCIAL_LOGIN_CLIENTID QA                    
                    GOOGLE_SOCIAL_LOGIN_CLIENTID: '305401786312-bo4610bvr7v2li6f3428im7idbltpn3o.apps.googleusercontent.com',
                    REACT_APP_CATALOGING_DASHBOARD: 'https://mktp-portal-front.qa.br.wcorp.carrefour.com/mktp-top-sellers',
                    REACT_APP_MKTP_NOTIFICATION: 'https://apihub.cloud.carrefour.com.br/mgus-portalseller-notifications-central',
                    REACT_APP_MKTP_NOTIFICATION_AUTHORIZATION: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3RldG9rZW4iLCJpYXQiOjE2NTQxODcyOTl9.Aio3Gx7UU3w7hnTWrJIQFn8zVZDhjKBXtSqy_A9E86g',
                    REACT_APP_MKTP_NOTIFICATION_X_API_KEY: 'QPgWXgrDCNJc2vcy3sBTTVcDp2buGIdA'
                }
                break;
                
            //AMBIENTE DE PROD
             case "production":
                 env = {
                    REACT_APP_MKTP_FRONT_BACKEND_URL: "https://mktp-portal-backend.cloud.carrefour.com.br",
                    REACT_APP_MKTP_AUTH_URL: "https://mktp-portal-front.cloud.carrefour.com.br/mktp-auth",
                    REACT_APP_MKTP_SELLER: "https://mktp-portal-front.cloud.carrefour.com.br/mktp-seller",
                    REACT_APP_MKTP_SELLER_DIAGNOSIS: 'https://mktp-portal-front.cloud.carrefour.com.br/mktp-seller-diagnosis',
                    REACT_APP_MKTP_PRODUCT: 'https://mktp-portal-front.cloud.carrefour.com.br/mktp-product',
                    REACT_APP_C4_COMMISSIONING: 'https://mktp-portal-front.cloud.carrefour.com.br/c4-commissioning',
                    REACT_APP_MKTP_DATA_HUB_URL: 'https://mktp-portal-front.cloud.carrefour.com.br/mktp-data-hub',
                    //GOOGLE_SOCIAL_LOGIN_CLIENTID PRD 
                    GOOGLE_SOCIAL_LOGIN_CLIENTID: '771447935056-r2oao8q78ptrd898eukonvhi6mu8vure.apps.googleusercontent.com',
                    REACT_APP_CATALOGING_DASHBOARD: 'https://mktp-portal-front.cloud.carrefour.com.br/mktp-top-sellers',
                    REACT_APP_MKTP_NOTIFICATION: 'https://apihub.cloud.carrefour.com.br/mgus-portalseller-notifications-central',
                    REACT_APP_MKTP_NOTIFICATION_AUTHORIZATION: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3RldG9rZW4iLCJpYXQiOjE2NTQxODcyOTl9.Aio3Gx7UU3w7hnTWrJIQFn8zVZDhjKBXtSqy_A9E86g',
                    REACT_APP_MKTP_NOTIFICATION_X_API_KEY: 'QPgWXgrDCNJc2vcy3sBTTVcDp2buGIdA'
                 }

                 break;
            default:
                break;
        }

        return env;
    }
}

export default env;