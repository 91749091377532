import axios from 'axios'
import env from './Environment'

import { getAuthorizationHeaders } from './Connection'

const axiosUser = axios.create({
    baseURL: env.getUrlEnv().REACT_APP_MKTP_FRONT_BACKEND_URL,
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors'
  })

  axiosUser.interceptors.request.use(
    getAuthorizationHeaders,
    error => Promise.reject(error)
)

export const createUser = (url, data) => (
    new Promise((resolve, reject) => {
        axiosUser.post(url, data)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(err => {
          reject(err)
      });
    })
  )

export const getUserByUsername = (url,username) => (
    new Promise((resolve, reject) => {
      axiosUser.get(`${url}?email=${username}`)
              .then(({ data }) => {
                resolve(data)
              })
              .catch(err => {
                  reject(err)
              });
    })
  )

  export const getAllUsers = (url) => (
    new Promise((resolve, reject) => {
      axiosUser.get(`${url}`)
              .then(({ data }) => {
                resolve(data)
              })
              .catch(err => {
                  reject(err)
              });
    })
  )
  
  export const getUserListByUsername = (url, username) => (
    new Promise((resolve, reject) => {
      axiosUser.get(`${url}?email=${username}`)
              .then(({ data }) => {
                resolve(data)
              })
              .catch(err => {
                  reject(err)
              });
    })
  )
  
  export const isNewUser = (url,username) => (
    new Promise((resolve, reject) => {
      axiosUser.get(`${url}?email=${username}`)
              .then(({ data }) => {
                resolve(data)
              })
              .catch(err => {
                  reject(err)
              });
    })
  )

  export const updateLastLogon = (url, data) => (
    new Promise((resolve, reject) => {
      axiosUser.post(url, data)
              .then(({ data }) => {
                resolve(data)
              })
              .catch(err => {
                  reject(err)
              });
    })
  )

  export const deleteUserByUsername = (url, username) => (
    new Promise((resolve, reject) => {
      axiosUser.get(`${url}?email=${username}`)
              .then(({ data }) => {
                resolve(data)
              })
              .catch(err => {
                  reject(err)
              });
    })
  )
  
  export const updateUser = (url, data) => (
    new Promise((resolve, reject) => {
      axiosUser.post(url, data)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(err => {
          reject(err)
      });
    })
  )
  