import React, { useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { format, parse, parseISO } from 'date-fns'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


const BillingAdjustmentGrid = props => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [rowData, setRowData] = useState(props.billingAdjustment);    

    const dateComparator = (date1, date2) => {
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);

        if (date1Number == null && date2Number == null) {
          return 0;
        }

        if (date1Number == null) {
          return -1;
        }

        if (date2Number == null) {
          return 1;
        }

        return date1Number - date2Number;
    }

    const monthToComparableNumber = (date) => {
        if (date || date.length !== 10) {
            return null;
        }

        var yearNumber = date.substring(6, 10);
        var monthNumber = date.substring(3, 5);
        var dayNumber = date.substring(0, 2);
        var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

        return result;
    }

    const currencyFormatter = (params) => {
        return formatNumber(params.value);
    }

    const formatNumber = (number) => {
        let numberReturn = number.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

        return numberReturn && numberReturn !== "NaN" ? numberReturn : '';
    }
 
    const formatDate = (data) => {
        let newDate = parseISO(data.value);
        let dateReturn = data.value;

        try {
            dateReturn = format(newDate, 'dd/MM/yyyy HH:mm');
        } catch (e) {
            console.log(`Erro ao converter a coluna CreateOn: ${e}`);
        }
        
        return dateReturn;
    }

    return (
        <div className="ag-theme-material" style={{ height: props.height, width: props.width }}>
            <AgGridReact
                onGridReady={props.onGridReady}
                rowData={rowData}
                pagination={true}
                enableCellChangeFlash={true}
                suppressExcelExport={true}     
                rowMultiSelectWithClick={true} 
                rowSelection="multiple"          
            >   
                <AgGridColumn lockPosition={true} headerName="Ciclo"       field="cycleInstance.cycleInstanceId" width={100} resizable={true}/>
                <AgGridColumn lockPosition={true} headerName="Período"     field="cycleInstance.endDate"  width={160} valueFormatter={formatDate} resizable={true}/>
                <AgGridColumn lockPosition={true} headerName="Ajuste"      field="billingAdjustmentReason.billingAdjustmentReasonId" width={120} resizable={true}/>
                <AgGridColumn lockPosition={true} headerName="Id"          field="billingAdjustmentId" width={120} resizable={true}/>
                <AgGridColumn lockPosition={true} headerName="Criado em"   field="createdOn" width={180} valueFormatter={formatDate} resizable={true}/>
                <AgGridColumn lockPosition={true} headerName="Loja"        field="seller.tradingName" width={200} resizable={true} />
                <AgGridColumn lockPosition={true} headerName="Valor"       field="value" width={150} resizable={true} />
                <AgGridColumn lockPosition={true} headerName="Finalidade"  field="billingAdjustmentReason.name" width={220} resizable={true} />
                <AgGridColumn lockPosition={true} headerName="Descrição"   field="description" width={300} resizable={true} />

            </AgGridReact>
        </div>
    )
}

export default BillingAdjustmentGrid;