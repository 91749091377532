import React from 'react'

import { Dropdown, MenuItem } from '../../../menu/dropdown/Dropdown'
import i18n from '../../../../utils/i18n/i18n'

import './Menu.css'
import { CredentialManager } from '../../../../commons/CredentialManager'
import { ToggleManager } from '../../../../commons/ToggleManager'

const options = [
  {
    text: 'De para de Categorias', route: '/category', role: 'ROLE_VIEW_CATEGORY_FIRESTORE', toggle: null
  },
  {
    text: 'Relatório de Ofertas', route: '/offers/report', role: 'ROLE_VIEW_OFFER_REPORT', toggle: null
  },
  {
    text: 'Log de Preço e Estoque', route: '/offers/price_stock', role: 'ROLE_VIEW_OFFER_PRICE', toggle: null
  },
  {
    text: 'Reprocessamento de Ofertas', route: '/offers/reprocess', role: 'ROLE_REPROCESS_OFFER', toggle: null
  },
  {
    text: 'Diagnóstico do Seller', route: '/offers/seller_diagnosis', role: 'ROLE_VIEW_SELLER_DIAGNOSIS', toggle: null
  },
  {
    text: 'Trava de Preço', route: '/offers/price_locked', role: 'ROLE_VIEW_LOCKED_OFFERS', toggle: null
  },
  {
    text: 'Dashboard de Catalogação', route: '/offers/top_sellers', role: 'ROLE_VIEW_TOP_SELLERS', toggle: null
  },  
  {
    text: 'Dashboard Financeiro', route: '/financialDashboard', role: 'ROLE_VIEW_FINANCIAL_DASHBOARD', toggle: null
  },    
  {
    text: 'Ajuste Manual', route: '/billingAdjustments', role: 'ROLE_VIEW_BILLING_ADJUSTMENTS', toggle: null
  },  
  {
    text: 'Gerenciamento de Usuários', route: '/manageruser', role: 'ROLE_ADMIN', toggle: null
  },
  {
    text: 'Bloqueio de Produtos', route: '/productlocked', role: 'ROLE_VIEW_PRODUCT_LOCK', toggle: null
  },
  {
    text: 'Central de Notificações', route: '/notification', role: 'ROLE_ADM_NOTIFICATION', toggle: null
  },
  {
    text: 'Cadastro de Feriados', route: '/ManagerHoliday', role: 'ROLE_VIEW_HOLIDAY', toggle: null
  }
]

const Menu = () => (
  <Dropdown title="Menu" className="menu" id="nav">
    {
      options.map((item) => {
        if (item.role == null || CredentialManager.userHasRole(item.role)) {
          if (item.toggle == null || ToggleManager.hasToggle(item.toggle)) {
            return <MenuItem text={i18n.t(item.text)} route={item.route} key={item.text} />
          }
        }
        return null
      })
    }
  </Dropdown>
)

export default Menu