import React, { Component, Fragment } from 'react'

import { Redirect } from 'react-router-dom'

import Closable from '../../components/closable/Closable'
import { CustomDialog, CustomDialogHeader} from '../../components/commons/dialog/CustomDialog'
import DialogAlert from '../../components/DialogAlert/DialogAlert'

import { bindAll } from 'lodash'
import { CredentialManager } from '../../commons/CredentialManager'
import { format, getDate, getMonth, getYear, getHours, getMinutes, compareDesc, sub, parse, differenceInDays } from 'date-fns'

import ActionButton from '../../components/buttons/ActionButton'
import loaderIcon from '../../resources/images/loaderComplete.gif'
import PriceLockedGrid from './PriceLockedGrid'
import InputText from '../../components/form-components/InputText'

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import PriceLockedController from '../../controllers/PriceLockedController'
import OfferController from '../../controllers/OfferController'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'
import InputDatePicker from '../../components/form-components/input-date-picker/inputDatePicker'
import InputTimePicker from '../../components/form-components/input-time-picker/InputTimePicker'
import InputMoney from '../../components/form-components/input-money/InputMoney'
import InputPercentage from '../../components/form-components/input-percentage/InputPercentage'
import { Select, MenuItem, Checkbox, OutlinedInput, ListItemText, Input } from '@material-ui/core'

import './PriceLocked.css'

const matchPriceLockOptionsList = [
    { value: 'AVG', text: 'AVG' },
    { value: 'MIN', text: 'MIN' },
    { value: 'MAX', text: 'MAX' }
];

const areaFilterOptionsList = [
    { value: '',    text: 'Sem filtro'},
    { value: 'CAT', text: 'Catálogo' },
    { value: 'FAR', text: 'Farming' }
];

const lockedTypeFilterOptionsList = [
    'LOCKED',
    'FIRSTPRICELOCKED',
    'MATCHPRICELOCKED',
    'MATCHPRICELOCKEDINIT'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

class PriceLocked extends Component {
    constructor(props) {
        super(props)

        let selectedOffers = new Array()

        this.state = {
            showPartnerMessage: false,
            message: '',
            colorMessage: '',
            gridApi: undefined,
            columnApi: undefined,
            loader: false,
            sellerList: [],
            offerList: [],
            seller: undefined,            
            fromDate: new Date(),
            fromTime: new Date().setHours(0, 0, 0, 0),
            toDate: new Date(),
            toTime: new Date(),
            fromDateTime: this.formatDateToISO(sub(new Date().setHours(0,0,0,0),{ days: 7}), new Date().setHours(0,0,0,0)),
            toDateTime: this.formatDateToISO(new Date(), new Date()),
            showingBlockedOffersTab: true,
            disableExport: true,
            tabBlocked: 'btn btn-action-tab',
            tabApproved: 'btn btn-default',
            editLockOpen: false,
            lockedPrice_initial_price: 0,
            lockedPrice_percent_min: 0,
            lockedPrice_percent_max: 0,
            lockedPrice_limit_update_min: 0,
            lockedPrice_limit_update_max: 0,
            lockedPrice_diff_percent: 0,
            matchPriceLockOption: 'MAX',
            areaFilterOption: "",
            rmsFilterOption: '',
            lockedTypeFilterOption: [],
            lastFromDateTime: this.formatDateToISO(sub(new Date().setHours(0,0,0,0),{ days: 7}), new Date().setHours(0,0,0,0)),
            lastToDateTime: this.formatDateToISO(new Date(), new Date()),
            lastlockedTypeFilterOption: [],
            lastsellerId: "",
            dialogQuestion: false,
            dialogQuestionMassa: false
        }

        bindAll(this, [
            'closePartnerMessage',
            'onGridReady',
            'refreshGrid',            
            'handleOnClickBlocked',
            'handleOnClickFilter',
            'handleOnClickUnlock',
            'handleOnClickUnlockBatch',
            'handleChangeEcommerceId',
            'searchSellerByTrandingName',
            'setValueSellerSelected',
            'handleOnEditLockClick',
            'handleOnSaveLockClick',
            'handleChangeSelectMatchPrice',
            'handleChangeSelectAreaFilter',
            'handleChangeSelectLockedTypeFilter',
            'handleOnClosePriceLockScreen',
            'handleChangeInitialPrice',
            'handleChangePercentMin',
            'handleChangePercentMax',
            'handleChangeDiffPercent',
            'handleChangeLimitUpdateMin',
            'handleChangeLimitUpdateMax',
            'fillGridLockedUnlockedFilter',
            'handleOnTodayClickFilter',
            'handleOnYesterdayClickFilter',
            'handleOnSevenDaysClickFilter',
            'handleChangeFromDate',
            'handleChangeFromTime',
            'handleChangeToDate',
            'handleChangeToTime',
            'handleOpenDialogQuestion',
            'handleNoClick'   
        ]);
    }

    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    onGridReady = params => {
        this.setState({ gridApi: params.api,
                        columnApi: params.columnApi 
                    })
    }

    refreshGrid = () => {
        let params = {
            force: true,
            suppressFlash: true,
        }        
        this.state.gridApi.refreshCells(params);
    }

    formatDateToISO = (date, time) => {
        let dateTime;
  
        if (date && time) {
          let day = getDate(date)
          let month = getMonth(date)
          let year = getYear(date)
          let hours = getHours(time)
          let minutes = getMinutes(time)
          dateTime = new Date(year, month, day, hours, minutes, 0)
        } else {
          return this.formatDateToISO(new Date(), new Date());
        }
  
        return format(dateTime, 'dd/MM/yyyy HH:mm:ss')
      }

    handleOnClickBlocked = () => {
        if (!this.state.showingBlockedOffersTab){
            this.setState({
                tabBlocked: 'btn btn-action-tab',
                tabApproved: 'btn btn-default',
                showingBlockedOffersTab: true
            }, () => { this.showLastFilter()})             
        }
    }

    handleOnClickApproved = () => {
        if (this.state.showingBlockedOffersTab){
            this.setState({
                tabBlocked: 'btn btn-default',
                tabApproved: 'btn btn-action-tab',
                showingBlockedOffersTab: false
            }, () => { this.showLastFilter()})             
        }
    }

    handleOnEditLockClick = () => {
        
        if (!this.state.editLockOpen){            
            this.getPriceLockedParams()
        }
    }

    handleOnClosePriceLockScreen = () => {
        this.setState({
            loader: false,
            editLockOpen: false
        })
    }

    handleChangeInitialPrice = (id, value) => {                
        this.state.lockedPrice_initial_price = value
    }

    handleChangePercentMin = (id, value) => {                
        this.state.lockedPrice_percent_min = value
    }

    handleChangePercentMax = (id, value) => {                
        this.state.lockedPrice_percent_max = value
    }    

    handleChangeDiffPercent = (id, value) => {                
        this.state.lockedPrice_diff_percent = value
    }    

    handleChangeLimitUpdateMin = (id, value) => {                
        this.state.lockedPrice_limit_update_min = value
    }            

    handleChangeLimitUpdateMax = (id, value) => {                
        this.state.lockedPrice_limit_update_max = value
    }            

    getPriceLockedParams = () => {
        
        OfferController.getLockedPriceParams()
        .then((result) => {
          if (result.status === 'Error') {
            this.setState({
              loader: false,
              showPartnerMessage: true, 
              message: `${result.message}`,
              colorMessage: 'header-message header-error-message'
            });
          } else {
            this.setState({
              loader: false,              
              lockedPrice_initial_price: result.initial_price,
              lockedPrice_percent_min:  100 * result.percent_min,
              lockedPrice_percent_max:  100 * result.percent_max,
              lockedPrice_limit_update_min: 100 * result.limit_update_min,
              lockedPrice_limit_update_max: 100 * result.limit_update_max,
              lockedPrice_diff_percent: 100 * result.diff_percent,
              matchPriceLockOption: result.type,
              editLockOpen: !this.state.editLockOpen
            });
            console.log(result)
          }
        })
        .catch((error) => {
            this.setState({
              loader: false,
              showPartnerMessage: true, 
              message: `Erro ao tentar ler parâmetros de trava de preço.`,
              colorMessage: 'header-message header-error-message'
            });
        })        
    }

    setPriceLockedParams = (params) => {
        
        OfferController.setLockedPriceParams(params)
        .then((result) => {
          if (result.status === 'Error') {
            this.setState({
              loader: false,
              showPartnerMessage: true, 
              message: `${result.message}`,
              colorMessage: 'header-message header-error-message'
            });
          } else {
            this.setState({
              loader: false,              
              showPartnerMessage: true,
              message: `Parâmetros de trava de preço salvos.`,
              colorMessage: 'header-message .header-success-message',
              editLockOpen: !this.state.editLockOpen
            });
            console.log(result)
          }
        })
        .catch((error) => {
            this.setState({
              loader: false,
              showPartnerMessage: true, 
              message: `Erro ao tentar gravar parâmetros de trava de preço.`,
              colorMessage: 'header-message header-error-message'
            });
        })        
    }

    handleOnSaveLockClick = ()     => {
        const param = (
            {
                "diff_percent": `${this.state.lockedPrice_diff_percent/100}`,
                "initial_price": `${this.state.lockedPrice_initial_price}`,
                "limit_update_min": `${this.state.lockedPrice_limit_update_min/100}`,
                "limit_update_max": `${this.state.lockedPrice_limit_update_max/100}`,
                "percent_max": `${this.state.lockedPrice_percent_max/100}`,
                "percent_min": `${this.state.lockedPrice_percent_min/100}`,
                "type": `${this.state.matchPriceLockOption}`
            }
        )
        this.setPriceLockedParams(param)
        console.log("Salvou os parâmetros de trava")
    }

    handleOnTodayClickFilter = () => {        
        this.setState({
            fromDate: new Date(),
            fromTime: new Date().setHours(0, 0, 0, 0),
            fromDateTime: this.formatDateToISO(new Date(), new Date().setHours(0,0,0,0)),
            toDate: new Date(), 
            toTime: new Date(),
            toDateTime: this.formatDateToISO(new Date(), new Date())              
        })                           
    }

    handleOnYesterdayClickFilter = () => {
        const fDate = sub(new Date(),{ days: 1})
        const fTime = sub(new Date(),{ days: 1}).setHours(0, 0, 0, 0)
        const tDate = fDate
        const tTime = sub(new Date(),{ days: 1}).setHours(23, 59, 59)

        this.setState({
            fromDate: fDate,
            fromTime: fTime,
            fromDateTime: this.formatDateToISO(fDate, fTime),
            toDate: tDate,
            toTime: tTime,
            toDateTime: this.formatDateToISO(tDate, tTime)
        })                                   
    }
    
    handleOnSevenDaysClickFilter = () => {
        const fDate = sub(new Date(),{ days: 7})
        const fTime = sub(new Date(),{ days: 1}).setHours(0, 0, 0, 0)
        const tDate = new Date()
        const tTime = new Date()

        this.setState({
            fromDate: fDate,
            fromTime: fTime,
            fromDateTime: this.formatDateToISO(fDate, fTime),
            toDate: tDate,
            toTime: tTime,
            toDateTime: this.formatDateToISO(tDate, tTime)
        })                                           
    }    

    handleChangeFromDate = (value, e) => {
        this.setState({ 
          fromDate: value,
          fromDateTime: this.formatDateToISO(value, this.state.fromTime),
          buttonChartDisable: true
        }); 
      }
  
      handleChangeFromTime = (value, e) => {
        this.setState({ 
          fromTime: value, 
          fromDateTime: this.formatDateToISO(this.state.fromDate, value),
          buttonChartDisable: true
        });
      }
  
      handleChangeToDate = (value, e) => {
        this.setState({ 
          toDate: value, 
          toDateTime: this.formatDateToISO(value, this.state.toTime),
          buttonChartDisable: true,
          diffBetweenDays: differenceInDays(parse(this.state.toDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()), parse(this.state.fromDateTime, 'dd/MM/yyyy HH:mm:ss', new Date()))
        });
      }
  
      handleChangeToTime = (value, e) => {
        this.setState({ 
          toTime: value, 
          toDateTime: this.formatDateToISO(this.state.toDate, value),
          buttonChartDisable: true      
        });
      }

    handleOnClickFilter = () => {
       
        const fDateLimit = sub(new Date(this.state.toDate),{ months: 3}).setHours(0,0,0,0)
        const fDateSearch = this.state.fromDate.setHours(0,0,0,0)
        const rms = this.state.rmsFilterOption != '' ? this.state.rmsFilterOption : undefined;
        
        if ( fDateSearch < fDateLimit){            
            this.setState({
                showPartnerMessage: true, 
                message: "O filtro máximo de pesquisa permitido é de três meses.",
                colorMessage: 'header-message header-error-message'                
            })
        }            
        else {
            const sellerId = this.state.seller != null ? this.state.seller.ecommerceId:""
            const lockedFilters = 
                this.state.lockedTypeFilterOption.length > 0 ? 
                    this.state.lockedTypeFilterOption : 
                    lockedTypeFilterOptionsList
            
            this.setState({
                loader: true,
                lastFromDateTime: this.state.fromDateTime,
                lastToDateTime: this.state.toDateTime,
                lastlockedTypeFilterOption: lockedFilters,
                lastsellerId: sellerId
            })
            
            this.fillGridLockedUnlockedFilter(
                this.state.fromDateTime,
                this.state.toDateTime,
                lockedFilters,
                sellerId,
                this.state.showingBlockedOffersTab,
                rms
            )
        }        
    }

    showLastFilter = () => {
       
        this.setState({ loader: true })
        const sellerId = this.state.seller != null ? this.state.seller.ecommerceId:""

        this.fillGridLockedUnlockedFilter(
            this.state.lastFromDateTime,
            this.state.lastToDateTime,
            this.state.lastlockedTypeFilterOption,
            this.state.lastsellerId,
            this.state.showingBlockedOffersTab
        )        
    }

    handleOnClickUnlock = (event)  => {        
        this.setState({ loader: true })
        let selectedOffers = new Array()
        this.state.gridApi.getSelectedRows().forEach(element => {
            selectedOffers.push(element.offerId)
        });          
        if (selectedOffers.length > 0) {
            this.processUnlockGridSelectedOffers(selectedOffers, CredentialManager.getAuthenticatedUser().name);
        } else
            this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: "Não há ofertas selecionadas.",
                colorMessage: 'header-message header-error-message'
            })        
    }

    handleOpenDialogQuestion = () => {
        this.setState({
            dialogQuestion: true
        })
    }

    handleOpenDialogQuestionMassa = () => {
        this.setState({
            dialogQuestionMassa: true
        })
    }

    handleNoClick = () => {
        this.setState({
            dialogQuestion: false,
            dialogQuestionMassa: false
        })
    }

    handleOnClickUnlockBatch = (event) => {
        this.setState({ loader: true })
        let selectedOffers = new Array()                
        this.state.gridApi.forEachNode(element => {            
            selectedOffers.push(element.data.offerId)                
        })
        if (selectedOffers.length > 0)
            this.processUnlockGridSelectedOffers(selectedOffers, CredentialManager.getAuthenticatedUser().name)
        else
            this.setState({
                loader: false,
                showPartnerMessage: true, 
                message: "Não há ofertas filtradas.",
                colorMessage: 'header-message header-error-message'                
            })        
    }

    formValidate = () => {
        if (!this.state.seller) { return false; }

        return true;
    }

    handleChangeEcommerceId = (event) => {
        const { value } = event.target

        this.setState({
            ecommerceId: value
        })
    }

    handleOnClickExportarCSV = () => {
        var params = {
            columnSeparator: ';'
        };
        this.state.gridApi.exportDataAsCsv(params);
    }

    handleChangeRms = (event) => {
        const { value } = event.target

        this.setState({
          rmsFilterOption: value
        })
    }

    render() {
        const hasRoleViewLocked = CredentialManager.userHasRole('ROLE_VIEW_LOCKED_OFFERS')
        const hasRoleUpdateLocked = CredentialManager.userHasRole('ROLE_UPDATE_LOCKED_OFFERS')
        const hasRoleViewPriceLock = CredentialManager.userHasRole('ROLE_VIEW_PRICE_LOCK')
        const hasRoleUpdatePriceLock = CredentialManager.userHasRole('ROLE_UPDATE_PRICE_LOCK')
        
        if (!hasRoleViewLocked) {
            return <Redirect to="/error" />
        }

        const textAlert = (
            <>
                <h2 style={{fontWeight: "bold"}}>Esta ação é irreversível!</h2><br/>
                <h2 style={{fontWeight: "bold"}}>Existem { this.state.gridApi != undefined ? this.state.gridApi.getSelectedRows().length : 0 } oferta(as) que está(ão) na trava de preços, tem certeza que deseja seguir? </h2>
            </>
        )

        const header = (
            <CustomDialogHeader
                title='Você está prestes a liberar ofertas da trava de preços!'
                icon='icon-warning'
                id={`alert-header`}
            />
        )

        return (
            <Fragment>
                <div className="container">
                    <div className="column" style={{ width: '87%' }}>
                        <h2>Trava de Preços</h2>
                    </div>
                    <div className="column" style={{ width: '13%', alignContent: 'right'}}>
                    <ActionButton
                            onClickAction={this.handleOnEditLockClick}
                            id="btnEditLock"
                            text="Editar Trava"
                            icon="icon-edit"
                            disable={!hasRoleViewPriceLock}
                            componentClass="btn btn-action" />
                    </div>
                    <div className="column" style={{width: '7%', marginTop: '30px'}}>
                        <ActionButton
                            onClickAction={this.handleOnTodayClickFilter}
                            id="btnToday"                            
                            text="Hoje"
                            componentClass="btn btn-action"
                        />
                    </div>
                    <div className="column" style={{width: '8%', marginTop: '30px'}}>
                        <ActionButton
                            onClickAction={this.handleOnYesterdayClickFilter}
                            id="btnYesterday"
                            text="Ontem"
                            componentClass="btn btn-action" />
                    </div>
                    <div className="column" style={{width: '85%', marginTop: '30px'}}>
                        <ActionButton
                            onClickAction={this.handleOnSevenDaysClickFilter}
                            id="btnLastSevenDays"
                            text="Últimos 7 dias"
                            componentClass="btn btn-action" />
                    </div>
                    <div className="column" style={{width: '30%', marginTop: '30px'}}>
                        De: 
                    </div>
                    <div className="column" style={{width: '70%', marginTop: '30px'}}>
                        Até: 
                    </div>
                    <div className="column" style={{width: '15%'}}>
                        <InputDatePicker startDate={this.state.fromDate} name="fromDate" handleChange={(value, e) => this.handleChangeFromDate(value, e)} />
                    </div>
                    <div className="column" style={{width: '15%'}}>
                        <InputTimePicker value={this.state.fromTime} name="fromTime" handleChange={(value, e) => this.handleChangeFromTime(value, e)} />
                    </div>
                    <div className="column" style={{width: '15%'}}>
                        <InputDatePicker startDate={this.state.toDate} name="toDate" handleChange={(value, e) => this.handleChangeToDate(value, e)} />
                    </div>
                    <div className="column" style={{width: '55%'}}>
                        <InputTimePicker value={this.state.toTime} name="toTime" handleChange={(value, e) => this.handleChangeToTime(value, e)} />
                    </div>
                    <div className="column" style={{ width: '38%', marginTop: '30px', marginBottom: '10px' }}>
                        Seller: 
                    </div>
                    <div className="column" style={{ width: '62%', marginTop: '30px', marginBottom: '10px' }}>
                        Tipo de Trava: 
                    </div>
                    <div className="column" style={{ width: '38%', display: 'flex',  alignItems:'center'}}>
                        <InputAutocomplete
                            disabled={this.state.disabledAutocomplete}
                            setValue={this.setValueSellerSelected}
                            searchSellerByTrandingName={this.searchSellerByTrandingName}
                            sellerList={this.state.sellerList}
                            width={350}
                        />
                    </div>
                    <div className="column" style={{ width: '62%', display: 'flex', alignItems:'center' }}>                                               
                        <Select
                            multiple
                            value={this.state.lockedTypeFilterOption}
                            onChange={this.handleChangeSelectLockedTypeFilter}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            style={{ maxWidth: '540px', width:'540px'}}
                        >
                            {lockedTypeFilterOptionsList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox checked={this.state.lockedTypeFilterOption.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>                                                 
                    </div>          
                    <div className="column" style={{ width: '22%', marginTop: '30px' }}>
                        Área: 
                    </div>
                    <div className="column" style={{ width: '78%', marginTop: '30px' }}>
                        RMS:
                    </div>
                    <div className="column" style={{ width: '22%', display: 'flex', alignItems:'center' }}>
                        <select value={this.state.areaFilterOption} onChange={this.handleChangeSelectAreaFilter} style={{width: '140px'}}>
                            {areaFilterOptionsList.map(item => {
                                return (<option key={item.value} value={item.value}>{item.text}</option>);
                            })}
                        </select>                                               
                    </div>
                    <div className="column" style={{ width: '78%', display: 'flex', alignItems: 'center' }}>
                        <InputText 
                            id="inputSku"
                            value={this.state.rmsFilterOption}
                            placeholder={'Digite aqui o RMS'}
                            maxLength={100}
                            onChange={this.handleChangeRms}  />
                    </div>          
                    <div className="column" style={{ width: '10%', marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}>
                        <ActionButton
                            onClickAction={this.handleOnClickFilter}
                            id="btnFilter"
                            text="Filtrar"
                            icon="icon-search"
                            componentClass="btn btn-action" />
                    </div>
                    <div className="column" style={{width: '90%', marginTop: '30px'}}>
                        <ActionButton 
                        onClickAction={this.handleOnClickExportarCSV} 
                        id="btnExportarCSV" 
                        text="Exportar CSV"
                        componentClass="btn btn-action" />
                    </div>
                </div>
                                
                <div className="container">
                    
                        <div className="column" style={{ width: '50%' }}>
                            <ActionButton
                                onClickAction={this.handleOnClickBlocked}
                                id="btnBlocked"
                                text="Bloqueados"
                                componentClass={this.state.tabBlocked} />
                            <ActionButton
                                onClickAction={this.handleOnClickApproved}
                                id="btnApproved"
                                text="Desbloqueados"
                                componentClass={this.state.tabApproved} />
                        </div>                   
                        <div className="column" style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <ActionButton
                                onClickAction={this.handleOpenDialogQuestion}
                                id="btnApprove"
                                text="Liberar selecionados"
                                icon="icon-checked"
                                disable={!this.state.showingBlockedOffersTab || !hasRoleUpdateLocked} 
                                componentClass="btn btn-action"                                
                            />
                            <span>&nbsp;&nbsp;</span>
                            <ActionButton
                                onClickAction={this.handleOpenDialogQuestionMassa}
                                id="btnBatchApprove"
                                text="Liberar em massa"
                                icon="icon-checked"
                                disable={!this.state.showingBlockedOffersTab || !hasRoleUpdateLocked} 
                                componentClass="btn btn-action" />
                        </div>

                    <PriceLockedGrid
                        height={450}
                        width="100%"                        
                        onGridReady={this.onGridReady}
                        showCheckBox={this.state.showingBlockedOffersTab} />
                </div>
                <section className="header-message-container">
                    <Closable
                        className={this.state.colorMessage || ''}
                        isOpen={this.state.showPartnerMessage || false}
                        onClose={this.closePartnerMessage}
                        showCloseButton
                        autoCloseTime={5000}
                    >
                        <span className="commission-partner-save-success">
                            <i className="icon-ok-check" />
                            {this.state.message}
                        </span>
                    </Closable>
                </section>
                <CustomDialog
                    title='Alteração de travas de preço'
                    open={this.state.editLockOpen}
                >
                    
                    <div className="column" style={{ width: '100%' }}>
                        <h2 style={{fontWeight: "bold"}}>Alteração nas travas</h2>
                    </div>
                    <div className="container">
                        <div className="column" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'left', height: '30px' }}>
                        <h3>Travas de valor inicial</h3>
                        </div>
                        <div className="column" style={{ width: '40%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                        <HtmlTooltip
                            title={
                            <React.Fragment>
                                <Typography color="inherit">Preço mínimo</Typography><br></br>
                                <p><em>{"Preço mínimo que será permitido como"}</em> <b><u>{'primeiro preço'}</u></b>.</p><br></br>
                                <p>{"Utilizado quando uma"} <b><u>{"nova oferta"}</u></b> {"for cadastrada."}</p><br></br>
                                <p>{"Ofertas com preço inicial abaixo do preço indicado cairão na Trava de Preço."}</p>
                            </React.Fragment>
                            }
                        >                            
                            <span>Preço abaixo de </span>
                        </HtmlTooltip>
                        </div>
                        <div className="column" style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>                                                        
                            <InputMoney                                 
                                value={this.state.lockedPrice_initial_price} 
                                onChangeValue={(id, value) => this.handleChangeInitialPrice(id, value)}
                                readOnly={!hasRoleUpdatePriceLock}
                                />                            
                        </div>                        
                        <div className="column" style={{ width: '40%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                        <HtmlTooltip
                            title={
                            <React.Fragment>
                                <Typography color="inherit">Percentual mínimo</Typography><br></br>
                                <p><em>{"Porcentagem de desconto permitida como"}</em> <b><u>{'primeiro preço'}</u></b> <em>{" de uma oferta"}</em>.</p><br></br>
                                <p>{"Utilizado quando o preço for"} <b><u>{"inferior"}</u></b> {"ao preço atual de um match de um produto semelhante."}</p><br></br>
                                <p>{"Preços iniciais com valores de desconto acima do percentual indicado cairão na Trava de Preço."}</p>
                            </React.Fragment>
                            }
                        >
                            <span>Percentual mínimo  </span>
                        </HtmlTooltip>
                        </div>
                        <div className="column" style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                            <InputPercentage 
                                value={this.state.lockedPrice_percent_min}
                                onChangeValue={(id, value) => this.handleChangePercentMin(id, value)}
                                readOnly={!hasRoleUpdatePriceLock}
                                
                                />                                
                        </div>

                        <div className="column" style={{ width: '40%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                        <HtmlTooltip
                            title={
                            <React.Fragment>
                                <Typography color="inherit">Percentual máximo</Typography><br></br>
                                <p><em>{"Porcentagem de aumento permitida como"}</em> <b><u>{'primeiro preço'}</u></b> <em>{" de uma oferta"}</em>.</p><br></br>
                                <p>{"Utilizado quando o preço for"} <b><u>{"superior"}</u></b> {"ao preço atual de um match de um produto semelhante."}</p><br></br>
                                <p>{"Preços iniciais com valores acima do percentual indicado cairão na Trava de Preço."}</p>
                            </React.Fragment>
                            }
                        >
                            <span>Percentual máximo </span>
                        </HtmlTooltip>
                        </div>
                        <div className="column" style={{ width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px' }}>
                            <InputPercentage 
                                value={this.state.lockedPrice_percent_max}
                                onChangeValue={(id, value) => this.handleChangePercentMax(id, value)}
                                readOnly={!hasRoleUpdatePriceLock}
                                />
                        </div>
                    </div>
                    <div className="container">

                        <div className="column" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'left', height: '30px' }}>
                        <h3>Travas de alteração de valor</h3>
                        </div>

                        {/* Percentual de update MIN no Offer */}
                        <div className="column" style={{ width: '40%', display: 'flex', alignItems: 'center', height: '60px' }}>
                        <HtmlTooltip
                            title={
                            <React.Fragment>
                                <Typography color="inherit">Percentual de Redução permitida</Typography><br></br>
                                <p><em>{"Redução de preço que será permitida na"}</em> <b><u>{'atualização'}</u></b> <em>{" de preço"}</em>.</p><br></br>
                                <p>{"Utilizado quando o novo preço for"} <b><u>{"inferior"}</u></b> {"ao preço atual."}</p><br></br>
                                <p>{"Atualizações de preço com redução acima do percentual indicado cairão na Trava de Preço."}</p>
                            </React.Fragment>
                            }
                        >
                            <span>% Redução permitida </span>
                         </HtmlTooltip>   
                        </div>
                        <div className="column" style={{ width: '60%', display: 'flex', alignItems: 'center', height: '60px' }}>
                            <InputPercentage
                                value={this.state.lockedPrice_limit_update_min}
                                onChangeValue={(id, value) => this.handleChangeLimitUpdateMin(id, value)}
                                readOnly={!hasRoleUpdatePriceLock}
                                />
                        </div>
                        {/* Percentual de update MAX no Offer */}
                        <div className="column" style={{ width: '40%', display: 'flex', alignItems: 'center', height: '60px' }}>
                        <HtmlTooltip
                            title={
                            <React.Fragment>
                                <Typography color="inherit">Percentual de Aumento permitido</Typography><br></br>
                                <p><em>{"Aumento de preço que será permitido na"}</em> <b><u>{'atualização'}</u></b> <em>{" de preço"}</em>.</p><br></br>
                                <p>{"Utilizado quando o novo preço for"} <b><u>{"superior"}</u></b> {"ao preço atual."}</p><br></br>
                                <p>{"Atualizações de preço com aumento acima do percentual indicado cairão na Trava de Preço."}</p>
                            </React.Fragment>
                            }
                        >
                            <span>% Aumento permitido </span>
                        </HtmlTooltip>
                        </div>
                        <div className="column" style={{ width: '60%', display: 'flex', alignItems: 'center', height: '60px' }}>
                            <InputPercentage
                                value={this.state.lockedPrice_limit_update_max}
                                onChangeValue={(id, value) => this.handleChangeLimitUpdateMax(id, value)}
                                readOnly={!hasRoleUpdatePriceLock}
                                />
                        </div>

                        {/* Percentual de Match Price Lock */}
                        <div className="column" style={{ width: '40%', display: 'flex', alignItems: 'center', height: '60px' }}>
                        <HtmlTooltip
                            title={
                            <React.Fragment>
                                <Typography color="inherit">Percentual Match</Typography><br></br>
                                <p><em>{"Porcentagem de diferença de preço permitida como"}</em> <b><u>{'atualização'}</u></b> <em>{" de uma oferta"}</em>.</p><br></br>
                                <p>{"Utilizado quando o preço for"} <b><u>{"superior ou inferior"}</u></b> {"ao preço atual de um match de um produto semelhante."}</p><br></br>
                                <p>{"Atualizações de preços com valores acima do percentual indicado cairão na Trava de Preço."}</p>
                            </React.Fragment>
                            }
                        >
                            <span>Percentual Match</span>
                        </HtmlTooltip>
                        </div>
                        <div className="column" style={{ width: '60%', display: 'flex', alignItems: 'center', height: '60px' }}>
                            <InputPercentage
                                value={this.state.lockedPrice_diff_percent}
                                onChangeValue={(id, value) => this.handleChangeDiffPercent(id, value)}
                                readOnly={!hasRoleUpdatePriceLock}
                                />
                        </div>

                        {/* Tipo de Trava */}
                        <div className="column" style={{ width: '40%', display: 'flex', alignItems: 'center', height: '60px' }}>
                        <HtmlTooltip
                            title={
                            <React.Fragment>
                                <Typography color="inherit">Tipo da trava de Match</Typography><br></br>
                                <p><em>{"Tipo da trava que a"}</em> <b><u>{'atualização'}</u></b> <em>{" de uma oferta irá utilizar como referência para bloqueio"}</em>.</p><br></br>
                                <p><b>{"MAX"}</b> {"para utilizar como base o "} <b><u>{"maior preço"}</u></b> {"entre os match do produto."}</p><br></br>
                                <p><b>{"MIN"}</b> {"para utilizar como base o "} <b><u>{"menor preço"}</u></b> {"entre os match do produto."}</p><br></br>
                                <p><b>{"AVG"}</b> {"para utilizar como base a "} <b><u>{"média de preço"}</u></b> {"do match de todos os produtos semelhantes."}</p><br></br>
                                <p>{"Atualizações de preços com valores acima do percentual indicado cairão na Trava de Preço."}</p>
                            </React.Fragment>
                            }
                        >
                            <span>Tipo da trava </span>
                        </HtmlTooltip>
                        </div>
                        <div className="column" style={{ width: '60%', display: 'flex', alignItems: 'center', height: '60px' }}>
                            <Select value={this.state.matchPriceLockOption} onChange={this.handleChangeSelectMatchPrice} readOnly={!hasRoleUpdatePriceLock}>
                                {matchPriceLockOptionsList.map(item => {
                                    return (<option key={item.value} value={item.value}>{item.text}</option>);
                                })}
                            </Select>
                        </div>

                    </div>

                    <div className="column" style={{
                        width: '35%',
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>

                        <ActionButton
                            onClickAction={this.handleOnClosePriceLockScreen}
                            id="btnCancelEditLock"
                            text="Cancelar"
                            componentClass="btn btn-default" />

                        <ActionButton
                            onClickAction={this.handleOnSaveLockClick}
                            id="btnSaveEditLock"
                            text="Salvar"
                            componentClass="btn btn-action"
                            disable={!hasRoleUpdatePriceLock}
                            />

                    </div>                    
                </CustomDialog>

                <DialogAlert 
                    title='Liberar ofertas da trava de preços'
                    text={textAlert}
                    icon='icon-warning'
                    isOpen={this.state.dialogQuestion}
                    actionYes={this.handleOnClickUnlock}
                    actionNo={this.handleNoClick}
                />

                <DialogAlert 
                    title='Liberar ofertas da trava de preços em massa'
                    text={textAlert}
                    icon='icon-warning'
                    isOpen={this.state.dialogQuestionMassa}
                    actionYes={this.handleOnClickUnlockBatch}
                    actionNo={this.handleNoClick}
                />

                {this.state.loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon} /></div></div> : ''}
            </Fragment>
        )
    }

    handleChangeSelectMatchPrice = (event) => {
        this.setState({ matchPriceLockOption: event.target.value })
    }

    handleChangeSelectAreaFilter = (event) => {
        this.setState({ 
            areaFilterOption: event.target.value,
            lockedTypeFilterOption: 
                event.target.value === 'CAT'? ['MATCHPRICELOCKEDINIT']: 
                    event.target.value === 'FAR'? ['LOCKED','FIRSTPRICELOCKED','MATCHPRICELOCKED']:
                        []
        })
    }

    handleChangeSelectLockedTypeFilter = (event) => {        
        this.setState({            
            lockedTypeFilterOption: event.target.value
        })
    }

    searchSellerByTrandingName = (trandingName) => {
        PriceLockedController.getSellerList(trandingName)
            .then((result) => {
                this.setState({
                    sellerList: result
                })
            })
            .catch((error) => {
                this.setState({
                    showPartnerMessage: true,
                    message: `Erro ao buscar o Seller: ${error}`,
                    colorMessage: 'header-message header-error-message'
                });
            });
    }

    setValueSellerSelected = (value) => {
        this.setState({
            seller: value
        }, () => { console.log(this.state.seller) });
    }
    
    fillGridLockedUnlockedFilter = (from, to, statusList, seller, showBlockeds, rms) => {
        console.log("filters: ", from, to, statusList, seller, showBlockeds, rms);
        console.log(`RMS: ${this.state.rmsFilterOption}`)

        this.setState({
            offerList: [],
            loader: true 
        });

        const columns = this.state.columnApi.getColumnState();

        if (showBlockeds) {
            PriceLockedController.getLockedsOffers(from, to, statusList, seller, rms)
                .then(
                    data => {
                        this.setState({
                            offerList: data,                        
                            loader: false
                        },
                            () => {
                                this.state.columnApi.setColumnState(columns);
                                this.state.gridApi.setRowData(data);
                                this.refreshGrid();
                            });
                    }).catch((error) => {
                        console.log(error)
                        if (error.response != undefined && error.response.status == "404")
                            this.setState({
                                loader: false,
                                showPartnerMessage: true,
                                message: `Não existem ofertas com esses filtros: ${error}`,
                                colorMessage: 'header-message header-error-message'
                            });
                        else
                            this.setState({
                                loader: false,
                                showPartnerMessage: true,
                                message: `Erro ao tentar chamar a API de ofertas bloqueadas: ${error}`,
                                colorMessage: 'header-message header-error-message'
                            });
                    });
        } else {
            PriceLockedController.getUnlockedsOffers(from, to, statusList, seller, rms)
            .then(
                data => {
                    this.setState({
                        offerList: data,                        
                        loader: false
                    },
                        () => {
                            this.state.columnApi.setColumnState(columns);
                            this.state.gridApi.setRowData(data);
                            this.refreshGrid();
                        });
                }).catch((error) => {
                    console.log(error)
                    if (error.response.status == "404")
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Não existem ofertas com esses filtros: ${error}`,
                            colorMessage: 'header-message header-error-message'
                        });
                    else
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Erro ao tentar chamar a API de ofertas desbloqueadas: ${error}`,
                            colorMessage: 'header-message header-error-message'
                        });
                });
        }
    }
    
    processUnlockGridSelectedOffers = (selectedOffers, user) => {
        
        PriceLockedController.processUnlockOffers(selectedOffers, user)
            .then(
                data => {
                    this.setState({                   
                        loader: false,
                        dialogQuestion: false,
                        dialogQuestionMassa: false
                    },
                        () => {
                            this.handleOnClickFilter();
                        });
                }).catch((error) => {
                    console.log(error)
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Erro ao tentar chamar a API de desbloqueio de ofertas: ${error}`,
                            colorMessage: 'header-message header-error-message',
                            dialogQuestionMassa: false
                        });
                });
    }

    getSellerReportUrl = (trandingName) => {
        console.log("trandingName: ", trandingName);
        PriceLockedController.getUrlReport(trandingName)
            .then(
                data => {
                    window.open(data, '_blank');
                    this.setState({ loader: false });
                }).catch((error) => {
                    console.log(error)
                    if (error.response.status == "404")
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Não existem produtos para este seller: ${error}`,
                            colorMessage: 'header-message header-error-message'
                        });
                    else
                        this.setState({
                            loader: false,
                            showPartnerMessage: true,
                            message: `Erro ao tentar chamar a API do Report: ${error}`,
                            colorMessage: 'header-message header-error-message'
                        });
                });
    }

}

export default PriceLocked