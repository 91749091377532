import 'normalize.css'

/** dependencies */
import React from 'react'
import ReactDOM from 'react-dom'

import {
  Router, Switch, Route, Redirect
} from 'react-router-dom'

import createBrowserHistory from 'history/createBrowserHistory'


import Header from './components/header/Header'
import Error from './components/error/Error'
import Category from './scenes/category/Category'
import ReportOffers from './scenes/offers/ReportOffers'
import PriceStock from './scenes/offers/LogPriceStock'
import Dashboard from './scenes/dashboard/Dashboard'
import ErrorPage from './scenes/error/ErrorPage'
import ReprocessOffers from './scenes/offers/ReprocessOffers'
import IncludeUser from './scenes/user/IncludeUser'
import ManagerUser from './scenes/user/ManagerUser'
import EditUser from './scenes/user/EditUser'
import ChangePassword from './scenes/user/ChangePassword'
import RequestPassword from './scenes/user/RequestPassword'
import SellerDiagnosis from './scenes/offers/SellerDiagnosis'
import ProductLocked from './scenes/product/ProductLocked/ProductLocked'
import PriceLocked from './scenes/offers/PriceLocked'
import TopSellers from './scenes/offers/TopSellers'
import BillingAdjustments from './scenes/BillingAdjustments/BillingAdjustments'
import FinancialDashboard from './scenes/financial/FinancialDashboard'
import IncludeNotification from './scenes/notification/IncludeNotification'
import HolidayManager from './scenes/ManagerHoliday/ManagerHoliday'
import Notification from './scenes/notification/Notification'
import env from './commons/Environment'

import CredentialManager from './commons/CredentialManager'

/** css */
import './sass/main.css'

/** scenes */
import {
  NotFound,
  Authentication
} from './scenes/index'
import ManualAdjustment from './scenes/offers/ManualAdjustment'
import GoogleSocialAuthentication from './scenes/authentication/GoogleSocialAuthentication'
import { GoogleOAuthProvider } from '@react-oauth/google';

function RequireAuth(PageToRender) {

  console.log(`ENV Environment: ${process.env.REACT_APP_ENV}`);

  return CredentialManager.userIsAuthenticated() ? (
      <main className="base-main-content base-main-pages">
        <Header />
        <PageToRender />
        <Error />
      </main>
  ) : (
    <Redirect to="/authentication" />
  )
}
function NoAuth(PageToRender) {
  
  console.log(`ENV Environment: ${process.env.REACT_APP_ENV}`);

  return (
    <GoogleOAuthProvider clientId={env.getUrlEnv().GOOGLE_SOCIAL_LOGIN_CLIENTID}>
      <main className="base-main-content">
        <PageToRender />
        <Error />
      </main>
    </GoogleOAuthProvider>
  )
}

ReactDOM.render(
    <Router history={createBrowserHistory()}>
      <Switch>

        {/* Rota alternativa para a home/dashboard */}
        {
          /*
            <Route exact path="/">
            <Redirect to="/authentication" />
            </Route>
          */
        }
        <Route exact path="/">
          <Redirect to="/googlesocialauthentication" />
        </Route>
        

        <Route exact path="/dashboard" render={() => RequireAuth(Dashboard)} />
        <Route exact path="/category" render={() => RequireAuth(Category)} />
        <Route path="/offers/report" render={() => RequireAuth(ReportOffers)} />
        <Route path="/offers/price_stock" render={() => RequireAuth(PriceStock)} />
        <Route path="/offers/reprocess" render={() => RequireAuth(ReprocessOffers)} />
        <Route path="/offers/seller_diagnosis" render={() => RequireAuth(SellerDiagnosis)} />
        <Route path="/offers/top_sellers" render={() => RequireAuth(TopSellers)} />
        <Route path="/productlocked" render={() => RequireAuth(ProductLocked)} />
        <Route path="/offers/price_locked" render={() => RequireAuth(PriceLocked)} />
        <Route path="/billingAdjustments" render={() => RequireAuth(BillingAdjustments)} />
        <Route path="/financialDashboard" render={() => RequireAuth(FinancialDashboard)} />
        <Route path="/manageruser" render={() => RequireAuth(ManagerUser)} />
        <Route path="/edituser" render={() => RequireAuth(EditUser)} />
        <Route path="/includeuser" render={() => RequireAuth(IncludeUser)} />
        <Route path="/changepassword" render={() => RequireAuth(ChangePassword)} />
        <Route path="/requestpassword" render={() => NoAuth(RequestPassword)} /> 
        <Route path="/notification" render={() => RequireAuth(Notification)} /> 
        <Route path="/includenotification" render={() => RequireAuth(IncludeNotification)} />
        <Route path="/ManagerHoliday" render={() => RequireAuth(HolidayManager)} />
        <Route path="/error" render={ErrorPage} />

        {/* Se não encontrar a opção de URL */}
        <Route exact path="/tokenauthentication" render={() => NoAuth(Authentication)} />
        <Route exact path="/authentication" render={() => NoAuth(GoogleSocialAuthentication)} />
        <Route exact path="/googlesocialauthentication" render={() => NoAuth(GoogleSocialAuthentication)} />
        <Route exact path="/404" render={NotFound} />

      </Switch>
    </Router>,
  document.getElementById('root'))
