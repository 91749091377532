import React, {useState, useEffect} from 'react';
import {
    Tab, Grid, Button
} from '@mui/material';
import {
    TabList,
    TabContext, TabPanel
 } from '@mui/lab';

const Dynamic = props => {
    const [selectedTab, setSelectedTab] = useState(props.selectedTab);

    const [tabs, setTabs] = useState([])    
    const [panels, setPanels] = useState([])    

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
        if (typeof(props.externalHandleChange) === 'function') {
            props.externalHandleChange(event);
        }
    };

    useEffect(()=>{
        setTabs(props.tabs);                
    },[props.tabs]);
    
    useEffect(()=>{        
        setPanels(props.panels);        
        setSelectedTab(props.selectedTab);        
    },[props.panels, props.selectedTab]);

    return (        
        <TabContext value={String(selectedTab)}>
            <TabList variant="scrollable" onChange={handleChange}>
                {tabs.map(tab => (
                        <Tab label={`${tab.label.tabLabel}`}
                             key={tab.value.tabValue}
                             value={String(tab.value.tabValue)}
                             sx={{width:145}}
                        />
                ))}
            </TabList>
            {panels.map(panel => (
                <TabPanel key={panel.value.tabIndex} value={String(panel.value.tabIndex)}>
                    {panel.child()}
                </TabPanel>
            ))}
        </TabContext>
    );
}

export default Dynamic