
import React, { Component, Fragment } from 'react'
import ActionButton from '../../components/buttons/ActionButton'
import ManualAdjustmentGrid from './ManualAdjustmentGrid'
import CustomDialog from '../../components/commons/dialog/CustomDialog'
import { set } from 'date-fns/esm'
import { bindAll } from 'lodash'
import ManualAdjustmentController from '../../controllers/ManualAdjustmentController'
import InputAutocomplete from '../../components/form-components/input-autocomplete/inputAutocomplete'
import Closable from '../../components/closable/Closable'
import { Select, ListItemText } from '@material-ui/core'
import InputMoney from '../../components/form-components/input-money/InputMoney'

const adjustmentTypeOptionsList = [    
    { value: 'Débito', text: 'Débito' },
    { value: 'Crédito', text: 'Crédito' }
];

class ManualAdjustment extends Component {
    constructor(props){
        super(props)

        this.state = {
            showPartnerMessage: false,
            message: '',
            colorMessage: '',            
            billingAdjustmentList: [],
            addAdjustmentScreenOpened: false,
            gridApi: undefined,
            loader: false,
            seller: undefined,
            sellerList: [],
            adjustmentTypeOption: '',
            manualAdjustmentValue: 0
        }

        bindAll(this, [
            'refreshGrid',
            'fillGridBillingAdjustments',
            'onGridReady',
            'refreshGrid',
            'setValueSellerSelected',
            'closePartnerMessage'
        ]);

    }

    handleOnAddAdjustmentClick = () => {
        this.setState({
            addAdjustmentScreenOpened: true
        })
    }

    handleOnCancelAddAdjustmentClick = () => {
        this.setState({
            addAdjustmentScreenOpened: false
        })
    }

    handleOnSaveAddAdjustmentClick = () => {
        this.setState({
            addAdjustmentScreenOpened: false
        })        
    }    

    onGridReady = params => {
        this.setState({ gridApi: params.api })
    }

    refreshGrid = () => {
        let params = {
            force: true,
            suppressFlash: true,
        }        
        this.state.gridApi.refreshCells(params);
    }    

    fillGridBillingAdjustments = () => {        
                        
        ManualAdjustmentController.getBillingAdjustments()
            .then(
                data => {                    
                    console.log(data.billingAdjustmentList);
                    this.state.gridApi.setRowData(data.billingAdjustmentList);
                    this.refreshGrid();

                    this.setState({
                        //billingAdjustmentList: data.billingAdjustmentList,
                        loader: false
                    }/*,
                        () => {
                            console.log(data.billingAdjustmentList);
                            this.state.gridApi.setRowData(data.billingAdjustmentList);
                            this.refreshGrid();
                        }*/
                    );
                }
            ).catch((error) => {
                console.log(error)
                this.setState({
                    loader: false,
                    showPartnerMessage: true,
                    message: `Erro ao tentar chamar a API de comissionamento. ${error}`,
                    colorMessage: 'header-message header-error-message'
                });
            });        
    }

    setValueSellerSelected = (value) => {
        this.setState({
            seller: value
        }, () => { console.log(this.state.seller) });
    }

    searchSellerByTrandingName = (trandingName) => {
        ManualAdjustmentController.getSellerList(trandingName)
            .then((result) => {
                this.setState({
                    sellerList: result
                })
            })
            .catch((error) => {
                this.setState({
                    showPartnerMessage: true,
                    message: `Erro ao buscar o Seller: ${error}`,
                    colorMessage: 'header-message header-error-message'
                });
            });
    }

    render(){
        return(
            <Fragment>
                <div className="container">
                    <div className="column" style={{ width: '87%' }}>
                        <h3>Ajuste Manual</h3>                        
                    </div>
                    <div className="column" style={{ width: '13%', alignContent: 'right'}}>
                        <ActionButton
                                onClickAction={this.handleOnAddAdjustmentClick}
                                id="btnAddAdjustment"
                                text="Adicionar Ajuste"
                                icon="icon-edit"
                                componentClass="btn btn-action" />                        
                    </div>
                </div>
                <div  className="container">
                    <ManualAdjustmentGrid
                        height={450} 
                        width="100%"                         
                        onGridReady={this.onGridReady}
                        billingAdjustmentList={this.state.billingAdjustmentList}                 
                    />
                </div>

                <CustomDialog
                    title='Adicionar Ajuste'
                    open= {this.state.addAdjustmentScreenOpened}
                >

                    <div className="column" style={{ width: '100%', display: 'flex',  alignItems:'center'}}>
                        <span style={{ width: '50px' }}>Seller: </span>
                        <InputAutocomplete                            
                            setValue={this.setValueSellerSelected}
                            searchSellerByTrandingName={this.searchSellerByTrandingName}
                            sellerList={this.state.sellerList}
                        />
                    </div>
                    <div className="column" style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
                        <span>Tipo de ajuste:&nbsp;</span>
                        <Select value={this.state.adjustmentTypeOption} onChange={this.handleChangeSelectAdjustmentType}>
                            {adjustmentTypeOptionsList.map(item => {
                                return (<option key={item.value} value={item.value}>{item.text}</option>);
                            })}
                        </Select>
                    </div>
                    <div className="column" style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
                        <span>Valor:&nbsp;</span>
                        <InputMoney                                 
                            value={this.state.manualAdjustmentValue} 
                            onChangeValue={(id, value) => this.handleChangeManualAdjustmentValue(id, value)}                            
                        />
                        <span>Descrição:&nbsp;</span>
                        <ListItemText/>
                    </div>
                    <div className="column" style={{ width: '20%'}}></div>

                    <div className="column" style={{
                        width: '35%',
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>

                        <ActionButton
                            onClickAction={this.handleOnCancelAddAdjustmentClick}
                            id="btnCancelAddAdjustment"
                            text="Cancelar"
                            componentClass="btn btn-default" />

                        <ActionButton
                            onClickAction={this.handleOnSaveAddAdjustmentClick}
                            id="btnSaveAddAdjustment"
                            text="Adicionar Ajuste"
                            componentClass="btn btn-action"                            
                            />
                    </div>
                    
                </CustomDialog>
                <section className="header-message-container">
                    <Closable
                        className={this.state.colorMessage || ''}
                        isOpen={this.state.showPartnerMessage || false}
                        onClose={this.closePartnerMessage}
                        showCloseButton
                        autoCloseTime={5000}
                    >
                        <span className="commission-partner-save-success">
                            <i className="icon-ok-check" />
                            {this.state.message}
                        </span>
                    </Closable>
                </section>
            </Fragment>            
        )        
    }

    componentDidMount() {
        this.fillGridBillingAdjustments()
    }

    closePartnerMessage = () => {
        this.setState({ showPartnerMessage: false })
    }

    handleChangeSelectAdjustmentType = (event) => {
        this.setState({ adjustmentTypeOption: event.target.value })
    }

    handleChangeManualAdjustmentValue = (id, value) => {                
        this.state.manualAdjustmentValue = value
    }
}

export default ManualAdjustment
